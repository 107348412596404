import styled from 'styled-components'

interface IButton {
    content?: string;
    backgroundColor?: string;
    Color?: string;
    hoverBgColor?: string;
    activeBgColor?: string;
    Height?: string;
    width?: string;
    marginLeft?: string;
}

export const Container = styled.div`
    margin-top: 1.5rem;
    position: relative;
    display: flex;
    &.disable {
        display: none;
    }
    &.able {
        display: unset;
        width: 222px;
    }
    &.header {
        margin-top: 24px;
        align-items: flex-end;
        max-height: 40px;
    }
`;

export const OpenOs = styled.button<IButton>`
    width: 8.4375rem;
    width: ${({width}) => width};
    min-width: 135px;
    height: ${({Height}) => `${Height? Height:'3rem'}`};
    align-self: center;
    font-size: 1.125rem;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 1.375rem;
    letter-spacing: -0.2496px;
    color: ${({Color}) => Color};
    background-color: ${({backgroundColor}) => backgroundColor};
    border-radius: 6px;
    border: none;
    transition: ease background-color .3s, ease box-shadow .3s;
    margin-left: ${({marginLeft}) => marginLeft};
    &::after {
        content: ${({content}) => `'${content}'`};
        font-family: 'Inter', sans-serif;
        &.icon.hidden {
            content: '' !important;
        }
    }
    &:hover {
        background-color: ${({hoverBgColor}) => hoverBgColor};
        box-shadow: 0px 4px 20px rgba(8, 46, 69, 0.2);
    }
    &:active {
        background-color: ${(({activeBgColor}) => activeBgColor)};
    }
`;

export const MoveColor = styled.div<IButton>`
    width: 0;
    top: 0;
    left: 0;
    height: 3rem;
    align-self: center;
    font-size: 1.125rem;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 1.375rem;
    position: absolute;
    letter-spacing: -0.2496px;
    border-radius: 6px;
    border: none;
    &.hidden {
        display: none;
    }
    &.active {
        animation: Done normal linear 1300ms;
    }
    @keyframes Done {
        100% {
            width: 135px;
            transition: width 1300ms, background-color 0;
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
`;

export const SendedIcon = styled.img`
    position: absolute;
    width: 24px;
    height: 24px;
    left: 56px;
    top: 12px;
    display: none;
    &.icon {
        display: unset;
    }
`;
// var(--brand-primary-shade-200)
// 48px