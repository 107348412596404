import styled from 'styled-components';

export const Container = styled.div`
    max-width: 100%;
    min-height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding-right: 1.5rem;
    border-radius: 0.375rem;
`;

export const TrackOsCardLeftContainer = styled.div`
    display: flex;
    width: 52.1%;
    justify-content: space-between;
    min-width: 810px;
    padding-left: 35px;
`;

export const TrackOsCardAlignContainer = styled.div`
    display: flex;
    min-width: 4.6875rem;
    width: 4.6875rem;
    justify-content: center;
    &.icon {
        justify-content: flex-start;
    }
`;

export const TrackOsCardUserIcon = styled.div`
    display: flex;
    background: #124A6B;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.0625rem;
    color: #FFFFFF;
    width: 2rem;
    height: 2rem;
    margin-left: -5px;
`;

export const TrackOsCardOsNumber = styled.span`
    align-self: center;
    font-weight: 500;
    font-size: 1rem;
    color: rgba(68, 64, 83, 0.87);
`;

export const TrackOsCardOsPriorityContainer = styled.div`
    width: 6.25rem;
    background: rgba(255, 0, 0, 0.1);
    display: flex;
    padding: 9px 15px 9px 15px;
    border-radius: 9999px;
    align-items: center;
    justify-content: space-between;
    &.B {
        background-color: rgba(66, 133, 244, 0.2);
    }
    &.M {
        background-color: rgba(249, 151, 30, 0.1);
    }
`;  

export const TrackOsCardPriorityIcon = styled.img`
    user-select: none;
    margin-right: 15px;
`;

export const TrackOsCardPriorityContent = styled.span`
    font-weight: 500;
    font-size: 1rem;
    color: #FF0000;
    &.B {
        color: #4285F4;
    }
    &.M {
        color: #F9971E;
    }
`;

export const TrackOsCardStatus = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    padding: 0 16px;
    font-weight: 500;
    font-size: 1rem;
    background-color: rgb(204, 204, 204, 0.3);
    &.E {
        color: #4285F4;
        background-color: rgba(66, 133, 244, 0.1);
        min-width: 150px;
    }
    &.A {
        color: #28A138;
        background-color: rgba(40, 161, 56, 0.1);
    }
    &.Con {
        color: #28A138;
        background-color: rgba(40, 161, 56, 0.1);
    }
    &.Arq {
        color: rgba(98, 60, 234, 1);
        background-color: rgba(98, 60, 234, 0.1);
    }
`;

export const TrackOsCardRightContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 47.9%;
`;

export const TrackOsCardShowMore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    min-width: 6rem;
    font-weight: 500;
    user-select: none;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #FFFFFF;
    border-radius: 9999px;
    background-color: #124A6B;
`;