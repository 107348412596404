import React, { useEffect } from "react";
import {
    ArrowContainer,
    ArrowIcon,
    Container,
    OverflowAdjust,
    SelectOption,
    SelectOptionContent,
    SelectOptionContentStrong,
    SelectOptionsContainer,
} from "./styles";
import Arrow from "../../assets/icons/Track-Service-Order/SelectArrow.svg";

interface IShowMoreOsSelect {
    height?: string;
    placeHolder: string;
    colabs?: boolean;
    state?: string;
    setState: (value: string) => void;
    values?: Array<{
        label: string;
        value: string;
    } | undefined>;
}

const initialSelected = { value: "", label: "" };

export const ShowMoreOsSelect: React.FC<IShowMoreOsSelect> = ({
    values,
    placeHolder,
    height,
    colabs,
    state,
    setState
}) => {
    const [show, setShow] = React.useState(false);
    const [prevent, setPrevent] = React.useState(false);
    const [selected, setSelected] = React.useState(initialSelected);
    useEffect(() => {state === '-1' && setSelected(initialSelected)},[state])
    return (
        <Container>
            <SelectOption
                tabIndex={1}
                onBlur={() => {
                    if (show && !prevent) {
                        setShow(false);
                    }
                }}
                onClick={() => {
                    setShow((old) => !old);
                }}
                className={`main`}
            >
                <SelectOptionContent className={`main`}>
                    {selected === initialSelected ? placeHolder : ""}
                </SelectOptionContent>
                <ArrowContainer>
                    <ArrowIcon
                        className={`${show ? "active" : ""}`}
                        src={Arrow}
                    />
                </ArrowContainer>
            </SelectOption>
            <OverflowAdjust
                onMouseLeave={() => {
                    setPrevent(false);
                }}
                className={`main${show ? "" : " hidden"}`}
                height={height}
            >
                <SelectOptionsContainer>
                    {values?.filter((item) => {return item?.value !== ''}).map((item, key) => {
                        return (
                            colabs? 
                            <SelectOption
                                onMouseEnter={() => {
                                    setPrevent(true);
                                }}
                                onClick={() => {
                                    setSelected(
                                        (old) =>
                                            (old = {
                                                value: String(item?.value),
                                                label: String(item?.label),
                                            })
                                    );
                                    setShow((old) => !old);
                                    setState(String(item?.value))
                                }}
                                key={key}
                            >
                                <SelectOptionContent>
                                    {String(item?.label.split('-')[0])} - <SelectOptionContentStrong>{item?.label.split('-')[1]}</SelectOptionContentStrong>
                                </SelectOptionContent>
                            </SelectOption> :
                            <SelectOption
                                onMouseEnter={() => {
                                    setPrevent(true);
                                }}
                                onClick={() => {
                                    setSelected({value: String(item?.value),label: String(item?.label)});
                                    setShow((old) => !old);
                                    setState(`${String(item?.value)}`);
                                }}
                                key={key}
                            >
                                <SelectOptionContent>
                                    {String(item?.label)}
                                </SelectOptionContent>
                            </SelectOption>
                        );
                    })}
                </SelectOptionsContainer>
            </OverflowAdjust>
            <SelectOptionContent
                tabIndex={1}
                onBlur={() => {
                    if (selected === initialSelected && !prevent) {
                        setShow(false);
                    }
                }}
                height={height}
                onClick={() => {
                    setShow(true);
                    setSelected(initialSelected);
                }}
                className={`out${selected !== initialSelected ? " move" : ""}`}
            >
                {colabs? <SelectOptionContent>
                            {selected.label.split('-')[0]} - <SelectOptionContentStrong>{selected.label.split('-')[1]}</SelectOptionContentStrong>
                        </SelectOptionContent>
                        :   selected.label}
            </SelectOptionContent>
        </Container>
    );
};
