import { IOsResponse } from "../interface/os.interface";
import { replaceNumber, replaceText } from "../utils/replace";

interface Validate {
    osNumber?: string;
    name?: string;
    local?: string;
    colabs?: string[];
    priority?: number;
    time?: string;
    description?: string;
    number?: string;
    text?: string;
    any?: string;
}

export const osValidate = (item: Validate, maxLength?: number, minLength?: number, progress?: IOsResponse, finished?: IOsResponse) => {
    let newNumber = item.osNumber?.trim();
    let newName = item.name;
    let newLocal = item.local?.trim();
    let any = item?.any;
    let text = replaceText(String(item.text))
    let number = replaceNumber(String(item.number))
    if (newNumber === "") {
        return "empty";
    }
    if (
        String(newNumber) !== "undefined" &&
        progress?.rows.filter((item) => {
            return item.numero === newNumber
        }).length !== 0 && maxLength !== undefined
    ) {
        return "equal";
    }
    if (
        String(newNumber) !== "undefined" &&
        finished?.rows.filter((item) => {
            return item.numero === newNumber
        }).length !== 0 && maxLength !== undefined
    ) {
        return "equal";
    }
    if (String(newNumber) !== "undefined" && String(newNumber).length > Number(maxLength)) {
        return "big";
    }
    if (newName?.trim() === "") {
        return "empty";
    }
    if (String(newName).length < 3) {
        return "small";
    }
    if (String(newName) !== "undefined" && String(newName).length > 70) {
        return "big";
    }
    if (newLocal === "") {
        return "empty";
    }
    let newColabs = item.colabs;
    if (newColabs?.length === 0) {
        return "empty";
    }
    let newPriority = item.priority;
    let newTime = item.time;
    let newDescription = item.description;

    if (newPriority === -1) {
        return "empty";
    }
    if (newTime === "") {
        return "empty";
    }
    if (String(newTime) !== "undefined" && String(newTime).length > 11) {
        return "big-time";
    }
    if (newDescription === "") {
        return "empty";
    }
    if (String(newDescription).trim() === "") {
        return "empty";
    }
    if (String(newDescription).length > 300) {
        return "big";
    }

    if (String(text).trim() === '') {
        return 'empty';
    }
    if (String(text) !== 'undefined' && maxLength && String(text).length > Number(maxLength)) {
        return 'big';
    }
    if (String(text) !== 'undefined' && minLength && String(text).length < Number(minLength)) {
        return 'small';
    }
    if (String(number).trim() === '') {
        return 'empty';
    }
    if (String(number) !== 'undefined' && maxLength && String(number).length > Number(maxLength)) {
        return 'big';
    }
    if (String(number) !== 'undefined' && minLength && String(number).length < Number(minLength)) {
        return 'small';
    }
    if (String(any).trim() === '') {
        return 'empty';
    }
    if (String(any) !== 'undefined' && maxLength && String(any).length > Number(maxLength)) {
        return 'big';
    }
    if (String(any) !== 'undefined' && minLength && String(any).length < Number(minLength)) {
        return 'small';
    }
    
};
