import styled from 'styled-components';

export const DashboardGraphicDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: center;
`;

export const DashboardCalendarContainer = styled.div`
    display: flex;
    background: rgba(66, 133, 244, 0.05);
    align-items: center;
    max-width: 180px;
    margin-top: 24px;
    border-radius: 10px;
    transition: ease background-color 0.3s;
    &:hover {
        background: rgba(66, 133, 244, 0.1);
    }
    &:active {
        background: rgba(66, 133, 244, 0.15);
    }
`;

export const DashboardGraphicDate = styled.h3`
    font-size: 1rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    word-spacing: 16px;
    cursor: pointer;
    border-radius: 10px;
    height: 50px;
    padding: 14px 16px;
    min-width: 180px;
    &.from strong {
        color: var(--blue-secondary);
        margin-right: 24px;
    }
    &.until strong {
        font-weight: 600;
        margin-right: 24px;
        color: #F44D42;
    }
`;

export const CalendarContainer = styled.div`
    position: absolute;
    background: unset;
    opacity: 1;
    transition: ease opacity 0.18s, ease margin-top 0.3s;
    border: none;
    &.hide {
        z-index: -1;
        opacity: 0;
    }
    &.selected {
        z-index: -1;
        opacity: 0;
    }
    &.from  {
        margin-top: 324px;
        left: 21px;
    }
    &.until {
        margin-top: -294px;
        left: 21px;
    }
    &.enabled.from {
        margin-top: -290px;
        opacity: 1;
        z-index: 2;
    }
    &.enabled.until {
        margin-top: -216px;
        opacity: 1;
        z-index: 2;
    }
`;
