import React from 'react';
import { ConfigCardButtonContainer, ConfigCardButtonIcon, ConfigCardCenterContent, ConfigCardContentContainer, ConfigCardFooterButtonsContainer, ConfigCardFooterContainer, ConfigCardTitle, ConfigCardTopContainer, ConfigCardTopIcon, ConfigCardTopIconContainer, ConfigCardUsersIcons, ConfigCardUsersIconsContainer, Container } from './styles';
import Trash from '../../assets/icons/users-icon/Trash.svg';
import Edit from '../../assets/icons/Colabs/Edit.svg';
// import { deleteLocal } from '../../services/locals.service';

interface IConfigCards {
    id?: string;
    target: string;
    content: string;
    icon: string;
    title: string;
    usersIcon?: string;
    onClickTrash?: (id: string, target: string) => void;
    onClickEdit?: (id: string, target: string) => void;
}

export const ConfigCards = (props: IConfigCards) => {
    
    const filterIcon = (name: string) => {
        return name.slice(0, 1).toUpperCase().toString();
    }

    const JobGroup = [
        {
            username: 'Thiago da Silva',
            registration: 12312451,
            createdAt: '1231-12-12'
        },
        {
            username: 'Thiago da Silva',
            registration: 12312451,
            createdAt: '1231-12-12'
        },
        {
            username: 'Thiago da Silva',
            registration: 12312451,
            createdAt: '1231-12-12'
        },
        {
            username: 'Thiago da Silva',
            registration: 12312451,
            createdAt: '1231-12-12'
        }
    ]

    const handleDelete = React.useCallback(async () => {
        props.onClickTrash && props.onClickTrash(`${props.id}`,`${props.target}`);
    }, [props]);

    const handleUpdate = React.useCallback(async () => {
        props.onClickEdit && props.onClickEdit(`${props.id}`,`${props.target}`);
    }, [props]);

    return (
        <Container>
            <ConfigCardTopContainer>
                <ConfigCardTopIconContainer>
                    <ConfigCardTopIcon src={props.icon}/>
                </ConfigCardTopIconContainer>
                <ConfigCardTitle>{props.title}</ConfigCardTitle>
            </ConfigCardTopContainer>
            <ConfigCardContentContainer>
                <ConfigCardCenterContent>{props.content}</ConfigCardCenterContent>
            </ConfigCardContentContainer>
            <ConfigCardFooterContainer className={`${props.usersIcon}`}>
                <ConfigCardUsersIconsContainer className={`${props.usersIcon}`}>
                    {JobGroup.map((item, key) => {
                        return(
                            <ConfigCardUsersIcons key={key}>{filterIcon(item.username)}</ConfigCardUsersIcons>
                        )
                    })}
                </ConfigCardUsersIconsContainer>
                <ConfigCardFooterButtonsContainer>
                    <ConfigCardButtonContainer onClick={handleDelete}>
                        <ConfigCardButtonIcon src={Trash}/>
                    </ConfigCardButtonContainer>
                    <ConfigCardButtonContainer onClick={handleUpdate}>
                        <ConfigCardButtonIcon src={Edit}/>
                    </ConfigCardButtonContainer>
                </ConfigCardFooterButtonsContainer>
            </ConfigCardFooterContainer>
        </Container>
    );
}
