import { Find } from "../interface/find.interface";
import { api } from "./api.service";

class NotificacaoService {

    async find(find: Find) {
        return await api().get("/notificacao", {params: find});
    }

    async confirma(notificacoes: string[]) {
        return await api().post('/notificacao', { notificacoes });
    }
}

export const notificacaoService = new NotificacaoService();