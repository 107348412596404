import React from "react";
import Select, { ActionMeta, OptionsType } from "react-select";
import { InputSelect } from "../InputSelect";
import { InputText } from "../InputText";
import { OpenOS } from "../OpenOS";
import {
    Container,
    OsModalSection,
    OsModalFooter,
    OsModalHeader,
    OsModalHeaderCriationStats,
    OsModalHeaderCriationStatsContent,
    OsModalHeaderCriationStatsDotts,
    OsModalHeaderCriationStatsDottsContainer,
    OsModalHeaderCriationStatsIcon,
    OsModalMain,
    OsModalMainSubTitle,
    OsModalMainTitle,
    ResponsiveContainer,
    OsModalNoneContainer,
    OsModalMainSelectLabel,
    OsModalMainSelectContainer,
    OsModalMainContainer,
    OsModalMainDetailsPriorityContainer,
    OsModalMainDetailsPriority,
    OsModalMainDetailsPriorityContent,
    OsModalMainDetailsPriorityTextArea,
    OsModalMainDetailsPriorityIcon,
    OsModalMainForm,
} from "./styles";
import SelectStylesJob from "../../styles/Multi-Select-Job";
import SelectStylesInCharge from "../../styles/Multi-Select-In-Charge";
import Choosed from "../../assets/icons/os-modal/Choosed.svg";
import { useLocal } from "../../contexts/LocalContext";
import { useColabs } from "../../contexts/Users";
import { useServiceOrder } from "../../contexts/ServiceOrder";
import { InputError } from "../InputError";
import { osValidate } from "../../validators/create-os.validator";

interface IOsCriationStats {
    id?: number;
    icon?: string;
    iconClass?: string;
    content?: string;
}
interface IOpenOsModal {
    blurState?: boolean;
    ClickFunct?: any;
    class?: string;
}
interface IJobs {
    value: string;
    label: string;
    description?: string;
}
interface ISelect {
    value: string;
    label: string;
}

export const OsCriationStats = (props: IOsCriationStats) => {
    return (
        <OsModalHeaderCriationStats>
            <OsModalHeaderCriationStatsIcon
                className={`${props.iconClass} ${props.icon}`}
            >
                {props.id}
            </OsModalHeaderCriationStatsIcon>{" "}
            <OsModalHeaderCriationStatsContent
                className={`${props.iconClass} ${props.icon}`}
            >
                {props.content}
            </OsModalHeaderCriationStatsContent>
            <OsModalHeaderCriationStatsDottsContainer
                className={`${props.iconClass} ${props.icon}`}
            >
                <OsModalHeaderCriationStatsDotts
                    className={`${props.iconClass} ${props.icon}`}
                ></OsModalHeaderCriationStatsDotts>
                <OsModalHeaderCriationStatsDotts
                    className={`${props.iconClass} ${props.icon}`}
                ></OsModalHeaderCriationStatsDotts>
                <OsModalHeaderCriationStatsDotts
                    className={`${props.iconClass} ${props.icon}`}
                ></OsModalHeaderCriationStatsDotts>
                <OsModalHeaderCriationStatsDotts
                    className={`${props.iconClass} ${props.icon}`}
                ></OsModalHeaderCriationStatsDotts>
                <OsModalHeaderCriationStatsDotts
                    className={`${props.iconClass} ${props.icon}`}
                ></OsModalHeaderCriationStatsDotts>
                <OsModalHeaderCriationStatsDotts
                    className={`${props.iconClass} ${props.icon}`}
                ></OsModalHeaderCriationStatsDotts>
            </OsModalHeaderCriationStatsDottsContainer>
        </OsModalHeaderCriationStats>
    );
};

export const OpenOSModal = (props: IOpenOsModal) => {

    const [jobsState, setJobsState] = React.useState<ISelect[]>([]);
    const [colabsState, setColabsState] = React.useState<ISelect[]>([]);

    const [osNumber, setOsNumber] = React.useState("");
    const [osMaster, setOsMaster] = React.useState("");
    const [osTime, setOsTime] = React.useState("");
    const [osDescription, setOsDescription] = React.useState("");
    const [selectLocalValue, setSelectLocalValue] = React.useState("");
    const [selectColabsValue, setSelectColabsValue] = React.useState<
        Array<string>
    >([]);
    const [selectTypeValue, setSelectTypeValue] = React.useState("");
    const [selectPriority, setSelectPriority] = React.useState(0);

    const { local, jobs, types } = useLocal();
    const { osColabs, getOsColabs } = useColabs();
    const { postNewOs, getOsProgress } = useServiceOrder();

    const [modalNav, setModalNav] = React.useState(0);
    const [doneNav, setDoneNav] = React.useState(-1);
    const [modal, setModal] = React.useState(false);
    const [ableNav, setAbleNav] = React.useState(false);
    const [choosedPriority, setChoosedPriority] = React.useState(-1);
    const [post, setPost] = React.useState("hidden");
    const [buttonContent, setButtonContent] = React.useState("Próxima");
    const [reload, setReload] = React.useState(false);

    const HideModal = () => {
        setModal(false);
        ResetModal();
        setButtonContent('Próxima');
    };
    const PrevNav = () => {
        if (modalNav > 0) {
            let prevState = modalNav;
            prevState--;
            setModalNav(prevState);
        }
        if (doneNav > -1) {
            let doneState = doneNav;
            doneState--;
            setDoneNav(doneState);
        }
        if (modalNav === 0) {
            ResetModal();
            return;
        }
        if (modalNav < 3) {
            setButtonContent("Próxima");
        }
    };
    const ResetModal = (clean?: boolean) => {
        props.ClickFunct();
        setModalNav(0);
        setDoneNav(-1);
        setChoosedPriority(-1);
        if(clean){
            setJobsState([]);
            setColabsState([]);
            setOsNumber('');
            setOsMaster('');
            setOsTime('');
            setOsDescription('');
            setSelectLocalValue('0');
            setSelectTypeValue('0');
            setSelectColabsValue([]);
            onChangeSelectColabs([],{removedValues: [], action: 'clear',})
            jobsFilter([],{ action: 'clear', removedValues: []})
        }
    };
    const CriationStats = [
        {
            id: 0,
            navState: "Stats",
            iconClass: "selected",
            content: "Solicitação",
        },
        {
            id: 1,
            navState: "Stats",
            iconClass: "selected",
            content: "Responsável",
        },
        {
            id: 2,
            navState: "Stats",
            iconClass: "selected",
            content: "Detalhes",
        },
    ];
    const Input = [
        {
            id: 0,
            placeHolder: "255733",
            label: "Número de ordem de serviço",
            value: osNumber,
            setValue: setOsNumber,
            maxLength: 10,
            type: 'number'
        },
        {
            id: 1,
            placeHolder: "Thiago da Silva Nascimento",
            label: "Nome do solicitante",
            value: osMaster,
            setValue: setOsMaster,
            maxLength: 70,
            type: 'text'
        },
    ];
    const SelectResponsible = [
        {
            placeHolder: "Selecionar",
            label: "Tipo de ordem",
        },
    ];
    const Priority = [
        {
            id: 0,
            content: "Alta",
            color: "#EB4335",
        },
        {
            id: 1,
            content: "Média",
            color: "#FFA26D",
        },
        {
            id: 2,
            content: "Baixa",
            color: "#4285F4",
        },
    ];
    const jobsFilter = (item: OptionsType<IJobs>, key: ActionMeta<IJobs>) => {
        if (key.action === "select-option" && item.length === 1) {
            setJobsState(old => item[0] && old.concat(item[item.length - 1]))
            setReload((old) => !old);
            return;
        }if (key.action === "select-option") {
            let jobs = jobsState;
            jobs.push(item[item.length - 1])
            setJobsState(jobs);
            setReload((old) => !old);
            return;
        }
        if (key.action === "clear") {
            setJobsState([])
            setReload((old) => !old);
        }
        if (key.action === "remove-value") {
            setJobsState(old => old = old.filter((e) => {return e.value !== key.removedValue.value}))
            setReload((old) => !old);
            return;
        }
    };
    const handleValidate = async (nav: number) => {
        try {
            if (modalNav === 0) {
                if (
                    osValidate({
                        osNumber: osNumber,
                        name: osMaster,
                        local: selectLocalValue,
                    }) === undefined
                ) {
                    if (modalNav < 2) {
                        let nextState = modalNav;
                        nextState++;
                        setModalNav(nextState);
                    }
                    if (doneNav < 2) {
                        let unDone = doneNav;
                        unDone++;
                        setDoneNav(unDone);
                    }
                    if (modalNav < 0) {
                        setButtonContent("Próxima");
                    }
                    setAbleNav(false);
                } else {
                    setAbleNav(true);
                }
            }
            if (modalNav === 1) {
                if (
                    osValidate({
                        colabs: selectColabsValue,
                    }) === undefined
                ) {
                    if (modalNav < 2) {
                        let nextState = modalNav;
                        nextState++;
                        setModalNav(nextState);
                    }
                    if (doneNav < 2) {
                        let unDone = doneNav;
                        unDone++;
                        setDoneNav(unDone);
                    }
                    if (modalNav === 1) {
                        setButtonContent("Finalizar");
                    }
                    setAbleNav(false);
                } else {
                    setAbleNav(true);
                }
            }
            if (modalNav === 2) {
                try {
                    if (
                        osValidate({
                            priority: selectPriority,
                            time: osTime,
                            description: osDescription
                        }) === undefined
                    ) {
                        const create = {
                            numero: osNumber,
                            nome_solicitante: osMaster,
                            id_tipo: selectTypeValue,
                            id_local: selectLocalValue,
                            encarregados: selectColabsValue,
                            prioridade: selectPriority,
                            tempo_estimado: osTime,
                            descricao: osDescription
                        };
                        setAbleNav(false);
                        postNewOs(create);
                        setPost("active");
                        setButtonContent("Enviando...");
                        setTimeout(() => {
                            setPost("hidden icon");
                            setButtonContent("");
                        }, 1300);
                        setTimeout(() => {
                            ResetModal(true);
                            setButtonContent("Próxima");
                        }, 2000);
                        setTimeout(() => {
                            setPost("hidden");
                        }, 2500);
                        getOsProgress({});
                    } else {
                        setAbleNav(true);
                    }
                } catch (error) {
                    alert(error);
                }
            }
        } catch {
            alert("Validate error");
        }
    };
    const setPriority = (label: string) => {
        if (label === "Baixa") {
            setSelectPriority(1);
        }
        if (label === "Média") {
            setSelectPriority(2);
        }
        if (label === "Alta") {
            setSelectPriority(3);
        }
    };
    const onChangeSelectColabs = (
        item: OptionsType<IJobs>,
        key: ActionMeta<IJobs>
    ) => {
        if (key.action === "remove-value") {
            setSelectColabsValue(old => old = old.filter((e) => {return e !== key.removedValue.value}))
            setColabsState(old => old = old.filter((e) => {return e.value !== key.removedValue.value}))
            setReload((old) => !old);
            return;
        }
        if (key.action === "clear") {
            setSelectColabsValue([]);
            setColabsState([]);
            return;
        }
        if (key.action === "select-option" && item.length === 1) {
            setSelectColabsValue(
                (old) => item[0] && old.concat(item[item.length - 1].value)
            );
            setColabsState(
                (old) => item[0] && old.concat(item[item.length - 1])
            );
            setReload((old) => !old);
            return;
        }
        if (key.action === "select-option") {
            let obj = selectColabsValue;
            let colab = colabsState;
            colab.push(item[item.length - 1]);
            obj.push(item[item.length - 1].value);
            setColabsState(colab)
            setSelectColabsValue(obj);
            setReload((old) => !old);
            return;
        }
    };
    
    React.useEffect(() => {
        getOsColabs(jobsState.map((item) => {return item.value}));
    }, [reload, getOsColabs, jobsState, modalNav]);

    return (
        <Container className={modal || props.blurState ? "" : "hidden"}>
            <ResponsiveContainer
                onClick={() => {
                    props.ClickFunct();
                    HideModal();
                }}
                className="height"
            />
            <ResponsiveContainer tabIndex={1} className="unset">
                <ResponsiveContainer
                    tabIndex={1}
                    onClick={() => {
                        props.ClickFunct();
                        HideModal();
                    }}
                    className={`width left ${props.class}`}
                />
                <OsModalNoneContainer>
                    <OsModalSection className={props.class}>
                        <OsModalHeader>
                            {CriationStats.map((item, key) => {
                                return (
                                    <OsCriationStats
                                        id={item.id + 1}
                                        key={key}
                                        iconClass={`${
                                            item.id <= modalNav - 1
                                                ? "done"
                                                : ""
                                        }`}
                                        icon={`${item.navState}${
                                            modalNav === item.id
                                                ? " " + item.iconClass
                                                : ""
                                        }`}
                                        content={item.content}
                                    />
                                );
                            })}
                        </OsModalHeader>
                        <OsModalMainForm>
                            <OsModalMainContainer>
                                <OsModalMainTitle>
                                    Nova ordem de serviço
                                </OsModalMainTitle>
                                <OsModalMainSubTitle>
                                    Solitação de ordem de serviço
                                </OsModalMainSubTitle>
                                <OsModalMain
                                    nav={modalNav}
                                    main={0}
                                    className={`${"solicitation"}`}
                                >
                                    {Input.map((item, key) => {
                                        return (
                                            <>
                                                <InputText
                                                    id={item.id === 0? 'os-number-input':'os-requester-input'}
                                                    key={key}
                                                    setValue={item.setValue}
                                                    value={item.value}
                                                    autoFocus={
                                                        item.id === 0
                                                            ? true
                                                            : false
                                                    }
                                                    type={item.type}
                                                    placeHolder={
                                                        item.placeHolder
                                                    }
                                                    maxLength={item.maxLength}
                                                    label={item.label}
                                                />
                                                <InputError
                                                    stateAlert={ableNav}
                                                    minLength={
                                                        item.id === 0 ? 1 : 3
                                                    }
                                                    maxLength={
                                                        item.id === 0 ? 10 : 70
                                                    }
                                                    value={
                                                        item.id === 0
                                                            ? {
                                                                  osNumber:
                                                                      item.value,
                                                              }
                                                            : {
                                                                  name: item.value,
                                                              }
                                                    }
                                                />
                                            </>
                                        );
                                    })}
                                    <OsModalMainSelectLabel>
                                        Local de Ordem de serviço
                                    </OsModalMainSelectLabel>
                                    <InputSelect
                                        idSelect='open-os-local-select'
                                        idOptions='open-os-local-select-option'
                                        values={local}
                                        direction={"up"}
                                        placeHolder={"Ex: Biolabs"}
                                        setState={setSelectLocalValue}
                                        state={selectLocalValue}
                                    />
                                    <InputError
                                        stateAlert={ableNav}
                                        minLength={1}
                                        maxLength={99}
                                        value={{ local: selectLocalValue }}
                                    />
                                </OsModalMain>
                                <OsModalMain
                                    nav={modalNav}
                                    main={1}
                                    className={`${"responsible"}`}
                                >
                                    <OsModalMainSelectLabel>
                                        Grupo de trabalho
                                    </OsModalMainSelectLabel>
                                    <Select
                                        id="select-filter"
                                        styles={SelectStylesInCharge}
                                        placeholder={"Selecionar"}
                                        closeMenuOnSelect={false}
                                        onChange={(option, key) => {
                                            jobsFilter(option, key);
                                        }}
                                        isMulti
                                        options={jobs.map(
                                            ({ grupo: label, id: value }) => ({
                                                label,
                                                value,
                                            })
                                        )}
                                        value={jobsState}
                                    />
                                    <OsModalMainSelectLabel>
                                        Encarregado (os)
                                    </OsModalMainSelectLabel>
                                    <Select
                                        id="select-colab"
                                        styles={SelectStylesJob}
                                        onChange={(event, key) => {
                                            onChangeSelectColabs(event, key);
                                        }}
                                        placeholder={"Selecionar"}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={osColabs?.map(
                                            ({ nome: label, id: value }) => ({
                                                label,
                                                value,
                                            })
                                        )}
                                        value={colabsState}
                                    />
                                    <InputError
                                        stateAlert={ableNav}
                                        maxLength={98989}
                                        value={{ colabs: selectColabsValue }}
                                    />
                                    {SelectResponsible.map((item, key) => {
                                        return (
                                            <OsModalMainSelectContainer
                                                key={key}
                                            >
                                                <OsModalMainSelectLabel>
                                                    {item.label}
                                                </OsModalMainSelectLabel>
                                                <InputSelect
                                                    idOptions='open-os-select-type-option'
                                                    idSelect='open-os-select-type'
                                                    values={types.map(
                                                        ({
                                                            tipo: nome,
                                                            id,
                                                        }) => ({ nome, id })
                                                    )}
                                                    direction={"down"}
                                                    placeHolder={
                                                        item.placeHolder
                                                    }
                                                    state={selectTypeValue}
                                                    setState={
                                                        setSelectTypeValue
                                                    }
                                                />
                                            </OsModalMainSelectContainer>
                                        );
                                    })}
                                </OsModalMain>
                                <OsModalMain
                                    nav={modalNav}
                                    main={2}
                                    className={`${"details"}`}
                                >
                                    <OsModalMainSelectLabel>
                                        Prioridade
                                    </OsModalMainSelectLabel>
                                    <OsModalMainDetailsPriorityContainer>
                                        {Priority.map((item, key) => {
                                            return (
                                                <OsModalMainDetailsPriority
                                                    id={`open-os-priority-option-${item.id}`}
                                                    onClick={() => {
                                                        setChoosedPriority(key);
                                                        setPriority(
                                                            item.content
                                                        );
                                                    }}
                                                    color={item.color}
                                                >
                                                    <OsModalMainDetailsPriorityIcon
                                                        className={`${
                                                            choosedPriority ===
                                                            item.id
                                                                ? "show"
                                                                : ""
                                                        }`}
                                                        src={Choosed}
                                                    />
                                                    <OsModalMainDetailsPriorityContent
                                                        color={item.color}
                                                    >
                                                        {item.content}
                                                    </OsModalMainDetailsPriorityContent>
                                                </OsModalMainDetailsPriority>
                                            );
                                        })}
                                    </OsModalMainDetailsPriorityContainer>
                                    <InputError
                                        value={{ priority: choosedPriority }}
                                        stateAlert={ableNav}
                                        maxLength={12}
                                    />
                                    <InputText
                                        value={osTime}
                                        type={'timer'}
                                        setValue={setOsTime}
                                        placeHolder={"12hrs"}
                                        label={"Tempo Estimado"}
                                        maxLength={5}
                                        id="open-os-time-input"
                                    />
                                    <InputError
                                        stateAlert={ableNav}
                                        value={{ time: osTime }}
                                        maxLength={8}
                                    />
                                    <OsModalMainSelectLabel>
                                        Descrição
                                    </OsModalMainSelectLabel>
                                    <OsModalMainDetailsPriorityTextArea
                                        value={osDescription}
                                        onChange={(e) => {
                                            setOsDescription(e.target.value);
                                        }}
                                        placeholder={"Ex: O cano quebrou"}
                                        required
                                        maxLength={300}
                                        id="open-os-textarea"
                                    ></OsModalMainDetailsPriorityTextArea>
                                    <InputError
                                        stateAlert={ableNav}
                                        value={{ description: osDescription }}
                                        maxLength={300}
                                        minLength={16}
                                    />
                                </OsModalMain>
                            </OsModalMainContainer>
                            <OsModalFooter>
                                <OpenOS
                                    clickFunct={() => {
                                        PrevNav();
                                    }}
                                    activeBgColor={"rgba(0, 0, 0, 0.1)"}
                                    hoverBgColor={"rgba(0, 0, 0, 0.05)"}
                                    Color={"rgba(0, 0, 0, 0.5);"}
                                    backgroundColor={"unset"}
                                    content={"Voltar"}
                                />
                                <OpenOS
                                    id="open-os-next"
                                    ableAnimation={true}
                                    animationClass={post ? post : ""}
                                    clickFunct={() => {
                                        handleValidate(modalNav);
                                    }}
                                    activeBgColor={
                                        "var(--brand-primary-shade-100)"
                                    }
                                    hoverBgColor={
                                        "var(--brand-primary-shade-200)"
                                    }
                                    Color={"white"}
                                    backgroundColor={
                                        "var(--brand-primary-default)"
                                    }
                                    content={`${
                                        buttonContent ? buttonContent : ""
                                    }`}
                                />
                            </OsModalFooter>
                        </OsModalMainForm>
                    </OsModalSection>
                </OsModalNoneContainer>
                <ResponsiveContainer
                    tabIndex={1}
                    onClick={() => {
                        props.ClickFunct();
                        HideModal();
                    }}
                    className="width"
                />
            </ResponsiveContainer>
            <ResponsiveContainer
                tabIndex={1}
                onClick={() => {
                    props.ClickFunct();
                    HideModal();
                }}
                className="height bottom"
            />
        </Container>
    );
};
