import React from "react";
import { Container, MoveColor, OpenOs, SendedIcon } from "./styles";
import Sended from "../../assets/icons/os-modal/Sended.svg";

interface IButton {
    content?: string;
    className?: string;
    backgroundColor?: string;
    Color?: string;
    hoverBgColor?: string;
    activeBgColor?: string;
    Height?: string;
    clickFunct?: any;
    ableAnimation?: boolean;
    animationClass?: string;
    buttonWidth?: string;
    marginLeft?: string;
    type?: string;
    state?: number;
    id?: string;
}

export const  OpenOS = (props: IButton) => {
    return (
        <Container
            id={props.id}
            onClick={props.clickFunct}
            className={`${
                props.className !== undefined ? props.className : ""
            }`}
        >
            <OpenOs
                type={"button"}
                marginLeft={props.marginLeft}
                width={props.buttonWidth}
                Height={props.Height}
                tabIndex={1}
                activeBgColor={props.activeBgColor}
                hoverBgColor={props.hoverBgColor}
                backgroundColor={props.backgroundColor}
                Color={props.Color}
                content={props.content}
                className={`${
                    props.animationClass && props.ableAnimation
                        ? props.animationClass
                        : ""
                }`}
            ></OpenOs>
            {props.ableAnimation && (
                <>
                    <MoveColor
                        className={`${
                            props.animationClass ? props.animationClass : ""
                        }`}
                    />
                    <SendedIcon
                        className={`${
                            props.animationClass ? props.animationClass : ""
                        }`}
                        src={Sended}
                    />
                </>
            )}
        </Container>
    );
};
