import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 6.875rem;
    display: flex;
    flex-direction: column;
    background-color: #FBFBFB;
`;

export const OsHistoryTitle = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    color: #444053;
    margin-top: 16px;
`;

export const OsHistorySubTitle = styled.h2`
    font-weight: 400;
    padding-top: 1rem;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const OsHistoryMain = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 48px;
    justify-content: space-between;
    height: 100%;
    padding-right: 40px;
    gap: 1rem;
    max-height: 100%;
`;

export const OsHistoryMainTop = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const OsHistoryTopLeft = styled.div`
    width: 100%;
    min-width: 700px;
`;

export const OsHistoryMainCenter = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    gap: 0.25rem;
    overflow: auto;
    &.shimmer {
        gap: 1.75rem;
    }
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 0 8px 8px 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 0 8px 8px 0;
    }
`;

export const OsHistoryDetailsTitle = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    color: #444053;
    margin-top: 16px;
`;

export const OsHistoryDetailsSubTitle = styled.h2`
    font-weight: 400;
    padding-top: 16px;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
`;