import styled from 'styled-components';

interface ISelect {
  height?: string;
  state?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.125rem 0.5rem rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  position: relative;
  display: flex;
`;

export const ArrowContainer = styled.div`
  background-color: rgba(229, 229, 229, 0.5);
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowIcon = styled.img<ISelect>`
  transition: ease transform .3s;
  margin-left: 1px;
  &.active {
    margin-bottom: 1px;
    transform: rotate(180deg);
  }
  `;

export const OverflowAdjust = styled.div<ISelect>`
  display: flex;
  max-height: ${({height}) => `${height? height + 'px' : '136px'}`};
  top: 4.5rem;
  position: absolute;
  width: 100%;
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
  z-index: 3;
  padding: 0.375rem 0 0.25rem 0;
  transition: z-index 0;
  transition: ease opacity .3s, ease height .3s;
  &.hidden {
    height: 0;
    transition: ease opacity .3s, z-index 1s, ease height .3s;
    opacity: 0;
    z-index: -1;
  }
`;

export const SelectOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 7.75rem;
  overflow: auto;
  width: 100%;
  padding: 0.125rem 0 0.25rem 0;
  &::-webkit-scrollbar {
      width: 3px;
      background: transparent;
      border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 8px;
  }
`;

export const SelectOption = styled.div`
  display: flex;
  width: 100%;
  padding: 0.25rem 1rem;
  cursor: pointer;
  transition: ease bottom .2s ;
  &:hover {
    background-color: rgba(98, 60, 234, 0.05);
  }
  &.main {
    padding: 0 1rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background-color: unset;
    }
  }
`;

export const SelectOptionContent = styled.span<ISelect>`
  font-weight: 400;
  line-height: 1.375rem;
  width: 100%;
  font-size: 1rem;
  color: #333332;
  transition: ease margin-top .3s;
  /* transition: z-index 1s; */
  &.main {
    opacity: .7;
  }
  &.out {
    position: absolute;  
    margin-top: 154px;
    margin-top: ${({height}) => `${height? `${Number(height) + 20}px}`:''}`};
    padding-left: 1rem;
    z-index: -1;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    height: 100%;
    cursor: pointer;
    &.move {
      margin-top: 0;
      z-index: 1;
      opacity: 1;
    }
  }
`;

export const SelectOptionContentStrong = styled.strong`
  font-weight: 400;
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.3);
`;