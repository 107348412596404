import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding-top: 0.5rem;
    padding-right: 1.5rem;
`;

export const TrackServiceOrderColOptions = styled.div`
    display: flex;
    min-width: 985px;
    width: 100%;
`;

export const TrackServiceOrderLeftOptions = styled.div`
    display: flex;
    width: 50%;
    justify-content: space-between;
    min-width: 810px;
    &.os {
        display: flex;
        justify-content: flex-start;
        width: 65.5%;
    }
`;

export const TrackServiceOrderOptions = styled.span`
    font-weight: 400;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
    &.Col {
        width: 50%;
        display: flex;
        justify-content: flex-start;
    }
    &.Gru {
        width: 100%;
        min-width: 140px;
        justify-content: center;
        display: flex;
    }
    &.Mat {
        justify-content: center;
        display: flex;
        width: 100%;
    }
    &.Enc {
        width: 150%;
        display: flex;
        justify-content: flex-start;
    }
    &.Núm {
        padding-left: 12px;
        justify-content: left;
        display: flex;
        width: 100%;
    }
    &.Pri {
        justify-content: center;
        display: flex;
        width: 100%;
    }
    &.Sta {
        justify-content: right;
        display: flex;
        padding-right: 16px;
        width: 100%;
    }
`;

export const TrackServiceOrderRightInfoContainer = styled.div`
    display: flex;
    width: 40%;
    min-width: 320px;
    &.os {
        width: 100%;
    }
`;

export const TrackServiceOrderRightOptions = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 60%;
    min-width: 60px;
    align-items: center;
`;

export const TrackServiceOrderNavInfo = styled.span`
    font-weight: 400;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const TrackServiceOrderNavIconContainer = styled.div`
    position: relative;
    padding: 16px;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -2px;
    &.left {
        margin-left: 0.9375rem;
        margin-top: 0;
        margin-right: 0.5rem;
    }
    &:hover {
        background: rgba(229, 229, 229, 0.5);
    }
`;

export const TrackServiceOrderNavIcon = styled.img`
    width: 14px;
    position: absolute;
    transform: rotate(90deg);
    right: 10px;
    user-select: none;
    top: 12px;
    &.right {
        top: 11px;
        right: 7px;
        transform: rotate(270deg);
    }
`;