import { LoginDTO } from "../interface/login-dto.interface";
import { ILoginResponse } from "../interface/login-response.interface";
import { api } from "./api.service";

export const doLogin = async (data: LoginDTO, profile?: boolean) => {
    const result = await api().post<ILoginResponse>("/auth/login", data);
    api().interceptors.request.use(config => {
        if (config.headers) {
            config.headers.Authorization = `Bearer ${result.data.token}`;
        }
        return config;
    })
    if (!profile) {
        window.location.replace('/dashboard')
    }
    return result.data;
};

export const revalidate = async () => {
    const result = await api().post("/auth/revalidate");
    api().interceptors.request.use(config => {
        if (config.headers) {
            config.headers.Authorization = `Bearer ${result.data.token}`;
        }
        return config;
    })
    window.location.replace('/dashboard');
    return result.data;
}