import React from 'react';
import { getPropriedades } from '../../utils/getPropriedades';
import { Container, OsChatCardContentContainer, OsChatCardIcon, OsChatCardIconContainer, OsChatCardLeftBar, OsChatCardLeftContainer, OsChatCardOsRegistration, OsChatCardOsStatus, OsChatNotificationContainer } from './styles';

interface IOsChatSelectionCards {
    registration?: string;
    status?: string;
    notifications?: number;
    onClick?: any;
    selectedClass?: string;
}

export const OsChatSelectionCards = (props: IOsChatSelectionCards) => {

    const OsProps = getPropriedades(props.status);
    
    return (
        <Container onClick={props.onClick} className={props.selectedClass}>
            <OsChatCardLeftContainer>
                <OsChatCardIconContainer>
                    <OsChatCardLeftBar className={props.selectedClass} status={OsProps.status}/>
                    <OsChatCardIcon status={`${OsProps.status}`}>OS</OsChatCardIcon>
                </OsChatCardIconContainer>
                <OsChatCardContentContainer>
                    <OsChatCardOsRegistration>{props.registration}</OsChatCardOsRegistration>
                    <OsChatCardOsStatus>{OsProps.status}</OsChatCardOsStatus>
                </OsChatCardContentContainer>            
            </OsChatCardLeftContainer>
            <OsChatNotificationContainer status={OsProps.status} notifications={props.notifications}/>
        </Container> 
    );
}
