/* eslint-disable react-hooks/exhaustive-deps */
import React, { SetStateAction } from "react";

import { Find } from "../interface/find.interface";
import { IOs, IOsResponse } from "../interface/os.interface";
import { IUser } from "../interface/user.interface";
import { IColabOs } from "../services/os.service";
import { CreateOsData, osService } from '../services/os.service';
import { useAuth } from "./AuthContext";

interface IServiceOrderContext {
	getOsProgress: (find: Find) => void;
	osProgress: IOsResponse;
	getOsFinished: (find: Find) => void;
	osFinished: IOsResponse;
	postNewOs: (data: CreateOsData) => void;
	osNumberActual: IOs;
	setOsNumberActual: React.Dispatch<SetStateAction<IOs>>;
	updateOs: (data: UpdateOsData) => void;
	addColab: (data: IColabOs) => void;
	deleteColab: (data: IColabOs) => void;
}

export interface UpdateOsData {
    id: string;
    numero?: string | undefined;
    nome_solicitante?: string | undefined;
    id_local?: string;
    encarregados?: string[];
    id_tipo?: string;
    prioridade?: number;
    tempo_estimado?: string | undefined;
    descricao?: string | undefined;
}

const inicialOs = {
	rows: [],
	total: -1,
}

const inicialStateOs = {
	id: '-1',
	numero: '',
	nome_solicitante: '',
	prioridade: '',
	tempo_estimado: '',
	descricao: '',
	status: '',
	local: { id: '', nome: '', descricao: '' },
	encarregados: [],
	tipo: '',
	created_at: new Date(),
	updated_at: null,
	finished_at: null,
	started_at: null,
	paused_at: null,
	em_aberto: {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		milliseconds: 0
	},
	pausa: {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		milliseconds: 0
	},
	andamento: {
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		milliseconds: 0
	}
}

export const ServiceOrderContext = React.createContext({} as IServiceOrderContext);

const ServiceOrderProvider: React.FC = ({ children }) => {
	

	const [osProgress, setOsProgress] = React.useState<IOsResponse>(inicialOs);
	const [osFinished, setOsFinished] = React.useState<IOsResponse>(inicialOs);
	const [osNumberActual, setOsNumberActual] = React.useState<IOs>(inicialStateOs);
	const { setUser, setToken } = useAuth();
	const getOsProgress = React.useCallback(async (find: Find) => {
		osService.getOsProgress(find)
			.then((res) => {
				setOsProgress(res.data);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					setUser({} as IUser);
					setToken("");
				}
			})
	}, []);
	const getOsFinished = React.useCallback(async (find: Find) => {
		osService.getOsFinished(find)
			.then((res) => {
				setOsFinished(res.data);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					setUser({} as IUser);
					setToken("");
				}
			})
	}, []);

	const updateOs = React.useCallback(async (data: UpdateOsData) => {
		await osService.update(data)
			.then((res) => {
				
			})
			.catch((err) => {
				if (err.response.status === 401) {
					setUser({} as IUser);
					setToken("");
				}
			})
	}, []);

	const postNewOs = React.useCallback(async (data: CreateOsData) => {
		await osService.postOs(data).then((res) => { }).catch((err) => { });
	}, [])
	const addColab = React.useCallback(async(data: IColabOs) => {
		osService.addColabOs(data);
	},[])
	const deleteColab = React.useCallback(async(data: IColabOs) => {
		osService.deleteColabOs(data)
	},[])

	return (
		<ServiceOrderContext.Provider value={{ getOsProgress, osProgress, getOsFinished, osFinished, postNewOs, osNumberActual, setOsNumberActual, updateOs, addColab, deleteColab }}>
			{children}
		</ServiceOrderContext.Provider>
	);
};

export default ServiceOrderProvider;

export const useServiceOrder = () => React.useContext(ServiceOrderContext);