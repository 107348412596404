import React from 'react';

import { Container, HistoryTimerContent, HistoryTimerContentContainer, HistoryTimerIcon, HistoryTimerIconContainer, HistoryTimerLabel } from './styles';
import GreenClock from '../../assets/icons/history/GreenClock.svg'
import GrayClock from '../../assets/icons/history/GrayClock.svg'
import BlueClock from '../../assets/icons/history/BlueClock.svg'

interface IHistoryTimer {
    label: string;
    color: string;
    content?: {
        days?: number,
        hours?: number,
        minutes: number,
        seconds: number,
        milliseconds?: number
    };
}
const HistoryTimerCard: React.FC<IHistoryTimer> = ({label, color, content}) => {
  return (
      <Container>
          <HistoryTimerLabel>{label}</HistoryTimerLabel>
          <HistoryTimerContentContainer>
              <HistoryTimerIconContainer className={color}>
                  <HistoryTimerIcon src={`${color === 'blue'? BlueClock:''}${color === 'gray'? GrayClock:''}${color === 'green'? GreenClock:''}`}/>
              </HistoryTimerIconContainer>
              <HistoryTimerContent>{content?.days? `${content.days}D ${content.hours}H e ${content.minutes}min`: content?.hours? `${content.hours}H ${content.minutes}min`: content?.minutes? `${content?.minutes}min`:'0 min'}</HistoryTimerContent>
              <div style={{minWidth: '40px'}}></div>
          </HistoryTimerContentContainer>
      </Container>
  );
  // `${content?.hours}H e ${content?.minutes}min`
}

export default HistoryTimerCard;