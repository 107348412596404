import styled from 'styled-components';

interface IOsChatCard {
    status?: string;
    notifications?: number;
}

export const Container = styled.div`
    display: flex;
    background: #FFFFFF;
    box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    min-width: 64px;
    justify-content: space-between;
    min-height: 4rem;
    cursor: pointer;
    align-items: center;
    transition: ease box-shadow 0.3s;
    &:hover {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }
    &.selected {
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }
`;

export const OsChatCardLeftContainer = styled.div`
    display: flex;
    padding-left: 0.25rem;
`;

export const OsChatCardIconContainer = styled.div`
    display: flex;
`;

export const OsChatCardLeftBar = styled.div<IOsChatCard>`
    width: 0.25rem;
    height: 1rem;
    background-color: ${({status}) => `${status === 'Pausada'? '#444053':'var(--blue-secondary)'}`};
    background-color: ${({status}) => `${status === 'Aberta'? '#28A138':''}`};
    border-radius: 9999px;
    align-self: center;
    margin-right: 0.625rem;
    opacity: 0;
    transition: ease opacity 0.3s;
    &.selected {
        opacity: 1;
    }
`;

export const OsChatCardIcon = styled.div<IOsChatCard>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${({status}) => `${status === 'Pausada'? '#444053':'var(--blue-secondary)'}`};
    background-color: ${({status}) => `${status === 'Aberta'? '#28A138':''}`};
    font-weight: 600;
    font-size: 0.875rem;
    user-select: none;
    color: #ECEFF0;
    border-radius: 50%;
    margin-right: 1rem;
`;

export const OsChatCardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OsChatCardOsRegistration = styled.span`
    font-weight: 600;
    font-size: 1rem;
    color: #444053;
`;

export const OsChatCardOsStatus = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const OsChatNotificationContainer = styled.div<IOsChatCard>`
    display: flex;
    justify-self: flex-end;
    margin-top: 0.8438rem;
    padding-bottom: 2.25rem;
    ::before {
        content: '${({notifications}) => `${notifications === 0? '': notifications}`}';
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.5625rem;
        color: #444053;
    }
    ::after {
        content: '';
        margin-left: 0.25rem;
        background-color: ${({status}) => `${status === 'Pausada'? '#444053':'var(--blue-secondary)'}`};
        background-color: ${({status}) => `${status === 'Aberta'? '#28A138':''}`};
        display: ${({notifications}) => `${notifications === 0? 'none':''}`};
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 50%;
        margin-right: 0.8125rem;
        margin-top: 3px;
    }
`;