/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IColab, IColabRow, IColabUpdateDTO } from "../interface/colab.interface";
import { CreateColabDTO } from "../interface/create-colab-dto.interface";
import { Find } from "../interface/find.interface";
import { IUser } from "../interface/user.interface";
import { colabsService } from "../services/colabs.service";
import { useAuth } from "./AuthContext";


interface IColabContext {
    colabActive: IColabRow;
    setColabActive: React.Dispatch<React.SetStateAction<IColabRow>>
    colabs: IColab;
    getColabs: (find: Find) => void;
    osColabs: IColabRow[];
    getOsColabs: (find: string[]) => void;
    updateColab: (colab: IColabUpdateDTO, sucess: () => void) => void;
    updateColabActive: (obj: object) => void;
    createColabs: (colab: CreateColabDTO, sucess: () => void) => void;
}

export interface IUsers {
    id: number;
    username?: string;
    job?: string;
    registration?: number;
    createdAt?: string;
}

const initicalState = {
    rows: [],
    total: -1,
}

const activeInicialState = {
    active: false,
    cpf: '',
    data_nascimento: null,
    email: '',
    grupos: [],
    id: '',
    matricula: '',
    nome: '',
    telefone: ''
}

export const ColabsContext = React.createContext({} as IColabContext);

const ColabsProvider: React.FC = ({ children }) => {
    const [colabs, setColabs] = React.useState<IColab>(initicalState);
    const [osColabs, setOsColabs] = React.useState<IColabRow[]>([]);
    const [colabActive, setColabActive] = React.useState<IColabRow>(activeInicialState);

    const { setUser, setToken, token } = useAuth();

    React.useEffect(() => {
        if(window.location.pathname !== '/' && token){
            getColabs({})
        }
    }, []);

    const updateColabActive = React.useCallback((obj: object) => {
        setColabActive((old) => { return { ...old, ...obj } });
    }, []);

    const getColabs = React.useCallback(async (find: Find) => {
        await colabsService.getColabs(find)
            .then((res) => {
                setColabs(res);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    const getOsColabs = React.useCallback(async (find: string[]) => {
        await colabsService.getOsColabs(find)
            .then((res) => {
                setOsColabs(res);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    const updateColab = React.useCallback(async (colab: IColabUpdateDTO, sucess: () => void) => {
        colabsService.updateColab(colab.id, colab)
            .then((res) => {
                getColabs({});
                sucess();
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            });
    }, []);

    const createColabs = React.useCallback((colab: CreateColabDTO, sucess: () => void) => {
        colabsService.createColab(colab)
            .then((res) => {
                sucess();
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    return (
        <ColabsContext.Provider value={{ colabs, getColabs, osColabs, getOsColabs, colabActive, setColabActive, updateColab, updateColabActive, createColabs }}>
            {children}
        </ColabsContext.Provider>
    );
};

export default ColabsProvider;

export const useColabs = () => React.useContext(ColabsContext);