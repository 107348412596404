import styled from 'styled-components';

export const ShowMoreColabCreatedColabModalContainer =  styled.div`
    min-width: 700px;
    min-height: 354px;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border: 1px solid #E5E5E5;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CreatedColabModalTopCloseIconContainer = styled.div`
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    align-self: flex-end;
    transition: ease background-color .3s;
    margin: 12px 12px 0 0;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const CreatedColabModalTopCloseIcon = styled.img`
    width: 14px;
`;

export const CreatedColabModalMainSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
`;

export const CreatedColabModalMainImg = styled.img`

`;

export const CreatedColabModalMainContent = styled.h3`
    margin-top: 3rem;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.375rem;
    letter-spacing: -0.25px;
    color: #444053;
`;
