import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    .input {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid #E5E5E5;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
        border-radius: 9999px;
        height: 3rem;
        min-width: 30.1875rem;
        padding: 13px 30px 13px 82px;
        font-weight: 400;
        font-size: 1.125rem;
        opacity: 0.8;
        outline: none;
        color: rgba(0, 0, 0, 0.3);
        transition: ease border 0.3s;
    }
    input {
        &:focus {
            border: 1px solid #124A6B;
            color: rgba(0, 0, 0, 0.7);
        }
        &:valid {
            border: 1px solid #124A6B;
            color: rgba(0, 0, 0, 0.7);
        }

    }
`;

export const SearchIcon = styled.img`
    position: absolute;
    left: 2rem;
    top: 0.9375rem;
    user-select: none;
`;