import { createGlobalStyle, keyframes } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    :root {
        //brand
        --brand-primary-default: #124A6B;
        --brand-primary-shade-100: #082E45;
        --brand-primary-shade-200: #22506B;
        --brand-primary-shade-disabled: #405B6B;

        --brand-secondary-default: #7BBC9C;
        --brand-secondary-700: #47E6B1;
        --brand-secondary-shade-100: #5A9679;
        --brand-secondary-shade-200: #96BCA9;
        --brand-secondary-shade-disabled: #8DBCA5;

        --brand-helper-100: #408586;
        --brand-helper-200: #A9D2A8;
        --brand-helper-300: #D0E1A1;

        // Neutrals

        --neutrals-black-default: #272726;
        --neutrals-black-shade-200: #333332;

        --neutrals-white-default: #FFFFFF;
        --neutrals-white-shade-100: #E6E6E6;
        --neutrals-white-shade-200: #F2F2F2;
        --neutrals-white-shade-300: #CCCCCC;

        // Messaging

        --messaging-warning: #F9971E;

        // Blue

        --blue-secondary: #4285F4;
        
        // Purple

        --purple-primary: #623CEA;
        --purple-assist: rgba(98, 60, 234, 0.05);
        --shimmer: shimmer .5s ease-in-out infinite;

        user-select: none;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        background: var(--background);
    }

    body, input, textarea, button {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.25px;
    }

    html {
        overflow: hidden;
        /* @media (min-height: 850px){
            font-size: 22px;
        } */
    }

    button {
        cursor: pointer;
    }

    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    .react-modal-overlay {
        background: rgba(0, 0, 0, 0.5);

        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .react-modal-content {
        width: 100%;
        max-width: 576px;
        background: var(--background);
        padding: 3rem;
        position: relative;
        border-radius: 0.25rem;
    }

    .react-modal-close {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        border: 0;
        background: transparent;
        transition: filter 0.3s;

        &:hover {
            filter: brightness(0.8);
        }
    }
`;

export const shimmer = keyframes`
    0% {
        background-position: 0% 0%;
        }
        100% {
        background-position: -135% 0%;
        }
    }
`;