import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 6.875rem;
    padding-top: 1rem;
    display: flex;
    background-color: #FBFBFB;
    flex-direction: column;
`;

export const NotificationsTitle = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    color: #444053;
`;

export const NotificationsSubTitle = styled.h2`
    font-weight: 400;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
`;

export const NotificationsMainContainer = styled.main`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;

export const NotificationsMainTopNavContainer = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2.5rem;
    margin-top: 1.5rem;
`;

export const NotificationsMainTopLeftNavContainer = styled.div`
    display: flex;
    gap: 1.5rem;
`;

export const NotificationsMainTopLeftContent = styled.span`
    font-weight: 400;
    display: flex;
    gap: 0.5rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const NotificationsMainTopLeftReload = styled.a`
    font-weight: 600;
    color: #124A6B;
    cursor: pointer;
`;

export const NotificationsMainTopRightContainer = styled.div`
    display: flex;
`;

export const NotificationsMainTopRightClean = styled.a`
    font-weight: 600;
    color: #124A6B;
    cursor: pointer;
    padding-right: 1.0938rem;
    border-right: 3px solid rgba(229, 229, 229, 0.5);
`;

export const NotificationsMainTopRightContent = styled.span`
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    align-items: center;
    padding-left: 1.0938rem;
`;

export const NotificationsMainTopRightButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: -4px;
`;

export const NotificationsMainTopRightButtons = styled.div`
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(270deg);
    margin-left: 8px;
    margin-top: -2px;
    cursor: pointer;
    &.previous {
        margin-left: 1rem;
        margin-top: 0;
        margin-right: 0.25rem;
        transform: rotate(90deg);
    }
    &:hover {
        background: rgba(229,229,229,0.5);
    }
`;

export const NotificationsMainTopRightIcon = styled.img`
    width: 13px;
    user-select: none;
`;

export const NotificationsMainCardsContainer = styled.div`
    max-height: 28.4375rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    &.shimmer {
        padding-right: 2.5rem;
        margin-top: 0.625rem;
        gap: 1rem;
    }
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
        border-radius: 9999px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 9999px;
        margin-right: 8px;
    }
`;