import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
        border-radius: 9999px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 9999px;
        margin-right: 8px;
    }
`;

export const ComponentContainer = styled.div`
    height: 100%;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    background: #FBFBFB;
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
        border-radius: 9999px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 9999px;
        margin-right: 8px;
    }
`;
