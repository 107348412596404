import { IconDiv } from './styles';

// import { Container } from './styles';

interface IconProps {
    img?: any;
    description?: string;
}

const IconContainer = (props: IconProps) => {

    
    let img = props.img.slice(14).split('.')

  return (
        <IconDiv className={img[0]}>
            <img className={img[0]} src={props.img} alt={props.description} />
        </IconDiv>
    );
}

export default IconContainer;