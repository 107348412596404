import { Find } from "../interface/find.interface";
import { IOsResponse } from "../interface/os.interface";
import { api } from "./api.service";

export interface CreateOsData {
    numero: string | undefined;
    nome_solicitante: string | undefined;
    id_local: string;
    encarregados: string[];
    id_tipo: string;
    prioridade: number;
    tempo_estimado: string | undefined;
    descricao: string | undefined;
}

export interface UpdateOsData {
    id: string;
    numero?: string | undefined;
    nome_solicitante?: string | undefined;
    id_local?: string;
    id_tipo?: string;
    prioridade?: number;
    tempo_estimado?: string | undefined;
    descricao?: string | undefined;
}

export interface IColabOs {
    id_os: string;
    id_encarregado: string;
}

class OsService {
    async getOsProgress(find: Find) {
        return await api().get<IOsResponse>(`/os/progress`, {params: find});
    }
    async getOsFinished(find: Find) {
        return await api().get<IOsResponse>(`/os/finished`, {params: find});
    }
    async getOsJobs() {
        return await api().get(`/grupos`)
    }
    async getOsTypes() {
        return await api().get(`/os/tipo`)
    }
    async postOs(data: CreateOsData) {
        return await api().post(`/os`, data);
    }
    async update(data: UpdateOsData) {
        const item = {
            numero: data.numero,
            nome_solicitante: data.nome_solicitante,
            id_local: data.id_local,
            id_tipo: data.id_tipo,
            prioridade: data.prioridade,
            tempo_estimado: data.tempo_estimado,
            descricao: data.descricao
        }
        return await api().put(`/os/${data.id}`, item);
    }
    async addColabOs(colab: IColabOs) {
        return await api().post('/encarregado', colab);
    }
    async deleteColabOs(colab: IColabOs) {
        return await api().delete(`/encarregado/${colab.id_os}/${colab.id_encarregado}`)
    }
}

export const osService = new OsService();