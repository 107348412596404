
export const getPropriedades = (status?: string, prioridade?: string) => {
    const props = {
        status: '',
        prioridade: '',
    }
    if(status === '0') {
        props.status = 'Aberta';
    }
    if(status === '1') {
        props.status = 'Em Andamento';
    }
    if(status === '2') {
        props.status = 'Pausada';
    }
    if(status === '3') {
        props.status = 'Concluida';
    }
    if(status === '4') {
        props.status = 'Arquivada';
    }

    if(prioridade === '1') {
        props.prioridade = 'Baixa';
    }
    if(prioridade === '2') {
        props.prioridade = 'Média';
    }
    if(prioridade === '3') {
        props.prioridade = 'Alta';
    }

    return props;
}