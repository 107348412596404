import styled from 'styled-components';
import { IModal } from '../../interface/modal.interface';

export const ColabsDeleteModalContainer = styled.div<IModal>`
    display: flex;
    flex-direction: ${({blurContainerDirection}) => blurContainerDirection};
    position: ${({position}) => position};
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.18);
    top: 0;
    opacity: 1;
    z-index: 2;
    transition: ease opacity .3s;
    &.hide {
        opacity: 0;
        z-index: -1;
    }
`;

export const ColabsDeleteModal = styled.div`
    min-width: 43.75rem;
    min-height: 19rem;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
`;

export const ColabsDeleteModalTopIconContainer = styled.div`
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin: 1rem 1rem 0 0;
    border-radius: 50%;
    cursor: pointer;
    transition: ease background-color .3s;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const ColabsDeleteModalTopIcon = styled.img`
    width: 0.875rem;
`;

export const ColabsDeleteModalCenter = styled.section`
    display: flex;
    flex-direction: column;
`;

export const ColabsDeleteModalCenterTitle = styled.h2`
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #333333;
    text-align: center;
`;

export const ColabsDeleteModalTitleStrong = styled.strong`
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #F9971E;
`;

export const ColabsDeleteModalCenterContent = styled.span`
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 2.5rem;
    text-align: center;
    color: #979797;
    padding: 0 60px;
    margin-top: 16px;
`;

export const ColabsDeleteModalContentStrong = styled.strong`
    color: #124A6B;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.5rem;
`;

export const ColabsDeleteModalFooterButtonsContainer = styled.div`
    display: flex;
    padding: 0 12.8125rem;
    gap: 1.5rem;
`;