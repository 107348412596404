import React from "react";
import { PrioritySelect } from "../../components/PriorityButton";
import {
    DashboardDataContainer,
    DashboardEndContainer,
    DashboardGraphicContainer,
    DashboardGraphicTitle,
    DashboardInfoContainer,
    DashboardMain,
    DashboardMainContainer,
    DashboardOsState,
    DashboardOsStateContainer,
    DashboardOsStateEllipse,
    DashboardStates,
    DashboardSubTitle,
    DashboardSubTitleContainer,
    DashboardTitle,
} from "./styles";
import Box from "../../assets/icons/dashboard-cards/Box.svg";
import Stacks from "../../assets/icons/dashboard-cards/Stacks.svg";
import Paused from "../../assets/icons/dashboard-cards/Paused.svg";
import Alert from "../../assets/icons/dashboard-cards/Alert.svg";
import Archived from "../../assets/icons/dashboard-cards/Archived.svg";
import Conclusion from "../../assets/icons/dashboard-cards/Conclusion.svg";
import Received from "../../assets/icons/dashboard-cards/Received.svg";
import Finished from "../../assets/icons/dashboard-cards/Finished.svg";
import Timer from "../../assets/icons/dashboard-cards/Timer.svg";
import { DashboardCards } from "../../components/DashboardCards";
import DashboardChart from "../../components/DashboardChart/index";
import "../../../src/styles/Calendar.css";
import { DashboardCalendar } from "../../components/Calendar";
import Layout from "../../layouts/Main";
import { useDashboard } from "../../contexts/Dashboard.context";
import { Find } from "../../interface/find.interface";
import { useFilters } from "../../contexts/FiltersContext";
import { Skeleton } from "../../components/Skeleton";

const Dashboard = () => {
    const { priority, setPriority } = useFilters();

    const { top, right, grafico, getTop, getRight, getGrafico } =
        useDashboard();

    const [blurFromCalendar, setBlurFromCalendar] = React.useState(true);
    const [blurUntilCalendar, setBlurUntilCalendar] = React.useState(true);
    const [dateValueFrom, setDateValueFrom] = React.useState(new Date());
    const [dateValueUntil, setDateValueUntil] = React.useState(new Date());

    const [filter, setFilter] = React.useState<Find>({
        page: 0,
        search: "",
        grupos: [],
        status: "",
        prioridade: "",
    });

    let newFind = {
        page: 0,
        search: "",
        grupos: [],
        status: "",
        prioridade: priority,
    };

    React.useEffect(() => {
        getTop(newFind);
        getRight(newFind);
        getGrafico({ inicio: dateValueFrom, fim: dateValueUntil });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    function CardsClass(icon: string) {
        let CardIcon = icon.slice(14);
        return CardIcon.split(".", 1).toString() + " Card-Icon";
    }

    const StateCards = [
        {
            icon: Box,
            value: top.abertas,
            content: "Em aberto",
        },
        {
            icon: Stacks,
            value: top.andamento,
            content: "Em andamento",
        },
        {
            icon: Paused,
            value: top.pausadas,
            content: "Pausadas",
        },
        {
            icon: Alert,
            value: top.atrasadas,
            content: "Em Atraso",
        },
    ];

    const InfoCards = [
        {
            icon: Received,
            value: right.recebidas,
            content: "Recebidas",
        },
        {
            icon: Finished,
            value: right.finalizadas,
            content: "Finalizadas",
        },
        {
            icon: Timer,
            value: 0,
            content: "Média de tempo",
        },
        {
            icon: Archived,
            value: right.arquivadas,
            content: "Arquivadas",
        },
        {
            icon: Conclusion,
            value: ((right.finalizadas * 100) / right.recebidas) | 0,
            content: "% de conclusão",
        },
    ];

    const Chart = {
        received: grafico.recebidas,
        finished: grafico.finalizadas,
    };

    const Priority = [
        {
            id: 0,
            icon: "red",
            label: "Alta",
            iconColor: "",
            value: "3",
        },
        {
            id: 1,
            icon: "orange",
            label: "Média",
            iconColor: "",
            value: "2",
        },
        {
            id: 2,
            icon: "blue",
            label: "Baixa",
            iconColor: "",
            value: "1",
        },
    ];
    
    return (
        <Layout>
            <DashboardMain>
                <DashboardTitle>Dashboard</DashboardTitle>
                <DashboardSubTitleContainer>
                    <DashboardSubTitle>
                        Tela de controle de dados das OS
                    </DashboardSubTitle>
                    <PrioritySelect
                        changeFunct={() => {
                            setFilter((old) => (old = newFind));
                        }}
                        setValue={setPriority}
                        placeholder="Todas as prioridades"
                        values={Priority}
                        right={"40px"}
                        top={"0"}
                    />
                </DashboardSubTitleContainer>
                <DashboardMainContainer>
                    <DashboardStates>
                        {StateCards.map((item, key) => {
                            return (
                                <DashboardCards
                                    key={key}
                                    icon={item.icon}
                                    card={CardsClass(item.icon)}
                                    children={
                                            top.abertas === -1?
                                            <Skeleton height={'22'} width={'100'}/>
                                            : String(item.value).length === 4
                                            ? String(item.value)?.replace(/(\d{1})?(\d{3})/g, '$1.$2')
                                            : String(item.value).length === 4? String(item.value)?.replace(/(\d{2})?(\d{3})/g, '$1.$2')
                                            : String(item.value)
                                    }
                                    content={item.content}
                                />
                            );
                        })}
                    </DashboardStates>
                    <DashboardEndContainer>
                        <DashboardDataContainer>
                            <DashboardGraphicContainer>
                                <DashboardGraphicTitle>
                                    Dados do Desempenho
                                </DashboardGraphicTitle>
                                <DashboardOsStateContainer>
                                    <DashboardOsStateContainer className="Received">
                                        <DashboardOsStateEllipse />
                                        <DashboardOsState className="Received">
                                            Abertas
                                        </DashboardOsState>
                                    </DashboardOsStateContainer>
                                    <DashboardOsStateContainer className="Finished">
                                        <DashboardOsStateEllipse className="Finished" />
                                        <DashboardOsState className="Finished">
                                            Finalizadas
                                        </DashboardOsState>
                                    </DashboardOsStateContainer>
                                </DashboardOsStateContainer>
                            </DashboardGraphicContainer>
                            <DashboardChart
                                children={<Skeleton height={'22'} width={'66'}/>}
                                received={Chart.received}
                                finished={Chart.finished}
                            />
                            <DashboardCalendar
                                reload={() => {
                                    setFilter(newFind);
                                }}
                                fromValue={dateValueFrom}
                                setFromValue={setDateValueFrom}
                                untilValue={dateValueUntil}
                                setUntilValue={setDateValueUntil}
                                blurFromState={blurFromCalendar}
                                blurFromStateFunct={setBlurFromCalendar}
                                blurUntilState={blurUntilCalendar}
                                blurUntilStateFunct={setBlurUntilCalendar}
                            />
                        </DashboardDataContainer>
                        <DashboardInfoContainer>
                            {InfoCards.map((item, key) => {
                                return (
                                    <DashboardCards
                                        key={key}
                                        cardclass={"Info"}
                                        card={CardsClass(item.icon)}
                                        icon={item.icon}
                                        children={right.finalizadas === -1? <Skeleton width={'100'} height={'22'}/>:item.value}
                                        content={item.content}
                                        value={right.finalizadas}
                                    />
                                );
                            })}
                        </DashboardInfoContainer>
                    </DashboardEndContainer>
                </DashboardMainContainer>
            </DashboardMain>
        </Layout>
    );
};

export default Dashboard;
