import styled from 'styled-components';

interface IChatTop {
    topContent?: string;
    
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-left: 6.875rem;
    max-height: 100%;
    display: flex;
    background-color: #FBFBFB;
    flex-direction: column;
    padding-top: 102px;
    position: relative;
`;

export const OsTitleContainer = styled.div`
    position: absolute;
    top: 16px;
`;

export const OsChatTitle = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    color: #444053;
`;

export const OsChatSubTitle = styled.h1`
    font-weight: 400;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
`;

export const OsChatMain = styled.main`
    display: flex;
    height: 100%;
    gap: 32px;
    max-height: 100%;
    padding-top: 12px;
    padding-right: 40px;
    padding-bottom: 8px;
    width: 100%;
`;

export const OsChatSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    position: relative;
    gap: 1rem;
    min-width: 19.125rem;
`;

export const OsChatSelectionTopContainer = styled.div`
    display: flex;
    align-items: center;
    width: 19.375rem;
    height: 2.5rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 4px;
`;

export const OsChatSelectionTopSubContainer = styled.div`
    display: flex;
    padding: 0 42px;
    cursor: pointer;
    min-width: 100px;
`;

export const OsChatSelectionTopContent = styled.span<IChatTop>`
    color: #000000;
    ::after {
        content: '${({topContent}) => `${topContent}`}';
        font-weight: 500;
        font-size: 1.125rem;
        color: #124A6B;
    }
`;

export const OsChatSelectionTopDownBar = styled.div`
    height: 0.25rem;
    display: flex;
    width: 9.0625rem;
    background-color: #124A6B;
    border-radius: 9999px;
    position: absolute;
    top: 36px;
    display: flex;
    transition: ease margin-left 0.3s, ease margin-right 0.3s, ease max-width .3s;
    &.selected {
        width: 162px;
        margin-left: 148px;
    }
`;

export const OsChatSelectionSearchContainer = styled.div`
    display: flex;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 0.5rem 1.75rem;
    gap: 1.5rem;
`;

export const OsChatSelectionSearchIcon = styled.img`
    width: 1.25rem;
`;

export const OsChatSelectionSearchInput = styled.input`
    outline: none;
    border: none;
    background-color: #FFFFFF;
    font-weight: 400;
    line-height: 0.875rem;
    font-size: 0.875rem;
`;

export const OsChatSelectionCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    padding-top: 8px;
    width: 100%;
    opacity: 0;
    z-index: -1;
    position: absolute;
    transition: ease opacity 0.3s;
    padding-right: 12px;
    gap: 1rem;
    &.shimmer {
        gap: 1rem;
    }
    &.selected {
        position: unset;
        opacity: 1;
        z-index: 1;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
        border-radius: 9999px;
        margin-left: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 9999px;
        margin-left: 12px;
        margin-top: 8px;
    }
`;

export const OsChatMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 32px;
    padding-right: 2px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    position: relative;
    padding: 16px 24px 100px 32px;
`;

export const OsChatMessageTopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const OsChatMessageTopLeft = styled.span`
    font-weight: 600;
    display: flex;
    gap: 0.25rem;
    font-size: 1.25rem;
    color: #444053;
`;

export const OsChatMessageTopRightContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const OsChatMessageTopRightContent = styled.span`
    font-weight: 500;
    color: #444053;
`;

export const OsChatMessageTopRightIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    gap: 16px;
`;

export const OsChatMessageTopRightIcon = styled.div`
    display: flex;
    background: #124A6B;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.0625rem;
    color: #FFFFFF;
    width: 2rem;
    user-select: none;
    height: 2rem;
    position: relative;
    &:hover {
        .edit-hover {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            bottom: -36px;
            padding: 0 16px;
            white-space: nowrap;
            height: 1.5rem;
            background-color: #061D28;
            color: #FFFFFF;
            position: absolute;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 0.875rem;
            opacity: 1;
            z-index: 1;
            transition: ease opacity 0.3s;
        }
        .edit-part {
            background-color: #061D28;
            position: absolute;
            z-index: -1;
            top: -5px;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
        }
    }
`;

export const OsChatMessageMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    border-top: 1px solid rgba(229, 229, 229, 0.5);
    margin-top: 16px;
`;

export const OsChatMessageMainTop = styled.span`
    text-align: center;
    font-weight: 500;
    color: #124A6B;
`;

export const OsChatMessageMainCenterContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow: auto;
    padding: 0 16px 16px 16px;
    &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
        border-radius: 9999px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 9999px;
    }
`;

export const OsChatMessageMainEndContainer = styled.form`
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 32px;
    width: 100%;
    &.hidden {
        display: none;
    }
`;

export const OsChatMessageMainEndInput = styled.input`
    background: rgba(229, 229, 229, 0.2);
    border-radius: 9999px;
    width: 100%;
    height: 3rem;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: 1.125rem;
    padding: 0 1rem;
    color: #333333;
`;

export const OsChatMessageMainEndSendButtonContainer = styled.button`
    width: 2.75rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.15));
    margin-left: 1rem;
    cursor: not-allowed;
    margin-right: 48px;
    opacity: 0.5;
    background: #E5E5E5;
    margin-top: 4px;
    outline: none;
    border: none;
    transition: ease opacity .15s, ease background-color .15s;
    &.able {
        opacity: 1;
        cursor: pointer;
        background: linear-gradient(227.06deg, #3AD29F 0%, rgba(112, 0, 255, 0) 333.43%);
    }
    &:hover {
        opacity: 0.92;
    }
    &:active {
        opacity: 0.87;
    }
`;

export const OsChatMessageMainEndSendIcon = styled.img`

`;

export const OsChatHoverEdit = styled.div`
    opacity: 0;
    z-index: -1;
    position: absolute;
`;

export const OsChatHoverEditPart = styled.div`
    z-index: -1;
`;