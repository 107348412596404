import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 6.875rem;
    display: flex;
    flex-direction: column;
    background-color: #FBFBFB;
`;

export const ColabsTitle = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    color: #444053;
    padding-top: 1rem;
`;

export const ColabsSubTitle = styled.h2`
    font-weight: 400;
    padding-top: 1rem;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const ColabsMain = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 32px;
    gap: 1rem;
    width: 100%;
    padding-top: 3rem;
    justify-content: space-between;
    height: 100%;
`;

export const ColabsMainTop = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const ColabsTopLeft = styled.div`
    width: 100%;
    min-width: 700px;
    .link {
        text-decoration: none;
        width: 19rem;
        display: flex;
        &:hover {
            .create-button {
                &:hover {
                    box-shadow: 1px 3px 16px rgba(0, 0, 0, 0.18);
                }
            }
        }
        &:active {
            .create-button {
                &:active {
                    box-shadow: 1px 3px 16px rgba(0, 0, 0, 0.23);
                }
            }
        }
    }
`;

export const ColabsTopLeftButton = styled.div`
    display: flex;
    padding: 9px 24px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    user-select: none;
    color: #124A6B;
    background-color: #FFFFFF;
    box-shadow: 1px 3px 16px rgba(0, 0, 0, 0.15);
    border: 1px solid #124A6B;
    border-radius: 6px;
    min-height: 2.5rem;
    width: 304px;
    cursor: pointer;
    transition: ease box-shadow 0.15s;
`;

export const ColabsMainCenter = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
    background-color: #FBFBFB;
    height: 100%;
    overflow-x: hidden;
    gap: 0.25rem;
    &.shimmer {
        gap: 1.75rem;
    }
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 0 8px 8px 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 0 8px 8px 0;
    }
`;
