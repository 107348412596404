import { api } from "./api.service";
import { Find } from '../interface/find.interface'

class ChatService {
    async getOsProgress(Find: Find) {
        return await api().get(`/chat/os/progress`, {params: Find});
    }

    async getOsFinished() {
        return await api().get(`/chat/os/finished`);
    }

    async getMessage(id_os: string) {
        return await api().get(`/chat/${id_os}`);
    }

    async sendMessage(message: string, id: string) {
        return await api().post(`/chat/${id}`, {message});
    }
}

export const chatService = new ChatService();