import { IColab, IColabRow, IColabUpdateDTO } from "../interface/colab.interface";
import { CreateColabDTO } from "../interface/create-colab-dto.interface";
import { Find } from "../interface/find.interface";
import { api } from "./api.service";


class ColabsService {
    
    async getColabs(find: Find) {
        const result = await api().get<IColab>(`/colaborador`, {params: find});
        return result.data;
    }

    async getOsColabs(Send: string[]) {
        const teste = {
            grupos: Send
        }
        const result = await api().get<IColabRow[]>(`/os/colaborador`, {params: teste});
        return result.data;
    }
    async createColab(colab: CreateColabDTO) {
        return await api().post<CreateColabDTO[]>(`/colaborador`, colab);
    }

    async deleteColab(id: string) {
        await api().delete(`/colaborador/${id}`)
    }

    async updateColab(id: string, colab: IColabUpdateDTO) {
        return await api().put<CreateColabDTO[]>(`/colaborador/${id}`, colab)
    }
}

export const colabsService = new ColabsService();