import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 14px;
    min-width: 140px;
    max-height: 250px;
`;

export const ConfigCardTopContainer = styled.div`
    display: flex;
    gap: 0.9375rem;
    align-items: center;
`;

export const ConfigCardTopIconContainer = styled.div`

`;

export const ConfigCardTopIcon = styled.img`
    user-select: none;
`;

export const ConfigCardTitle = styled.h4`
    font-weight: 500;
    font-size: 1rem;
    color: #444053;
`;

export const ConfigCardContentContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
`;

export const ConfigCardCenterContent = styled.span`
    padding-left: 8px;
    font-weight: 400;
    width: 100%;
    font-size: 0.875rem;
    word-break: break-all;
    height: 50px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: .5rem;
    text-align: center;
`;

export const ConfigCardFooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    &.able {
        justify-content: space-between;
    }
`;

export const ConfigCardFooterButtonsContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 40px;
    gap: 16px;
`;

export const ConfigCardUsersIconsContainer = styled.div`
    display: none;
    &.able {
        display: flex;
        user-select: none;
    }
`;  

export const ConfigCardUsersIcons = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    background: #124A6B;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #FFFFFF;
    margin-right: 8px;
`;

export const ConfigCardButtonContainer = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: ease background-color .3s;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const ConfigCardButtonIcon = styled.img`
    user-select: none;
`;