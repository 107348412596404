import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LogCardLeftContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
    padding-right: 100px;
`;

export const LogCardUsername = styled.strong`
    color: var(--blue-secondary);
    font-size: 0.875rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
`;

export const LogCardAction = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: #CCCCCC;
    margin-right: 0.5rem;
`;

export const LogCardValue = styled.strong`
    color: var(--blue-secondary);
    font-size: 0.875rem;
    padding-top: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
    overflow-x: hidden;
`;

export const LogCardRightContainer = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: #CCCCCC;
    padding-right: 0.8125rem;
`;