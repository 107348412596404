import React from "react";
import { Container } from "./styles";
import {osValidate} from '../../validators/create-os.validator'
import { useServiceOrder } from "../../contexts/ServiceOrder";

interface Validate {
    value : {
        osNumber?: string;
        name?: string;
        local?: string;
        colabs?: string[];
        type?: string;
        priority?: number;
        time?: string;
        description?: string;
        cpf?: string;
        text?: string;
        number?: string;
        any?: string;
    }
    position?: string;
    top?: string;
    stateAlert: boolean;
    maxLength: number;
    minLength?: number;
}

type newValidate = Validate & JSX.IntrinsicElements['span']

export const InputError: React.FC<newValidate> = ({position, top, stateAlert, minLength, maxLength ,value ,...rest}) => {
    const {osProgress, osFinished, getOsFinished, getOsProgress} = useServiceOrder()
    React.useEffect(() => {
        getOsProgress({})
        getOsFinished({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return(
        <Container top={top} position={position} maxLength={maxLength} minLength={minLength}>
            <span className={`${osValidate(value, maxLength, minLength, osProgress, osFinished) !== undefined && stateAlert? osValidate(value, maxLength, minLength,osProgress, osFinished):''}`} {...rest}></span>
        </Container>
    )
};
