/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Find, FindGrafico } from "../interface/find.interface";
import { IUser } from "../interface/user.interface";
import { dashboardService } from "../services/dashboard.service";
import { useAuth } from "./AuthContext";

interface IDashboardContext {
    top: ITop;
    right: IRight;
    grafico: IGrafico;
    getTop: (find: Find) => void;
    getRight: (find: Find) => void;
    getGrafico: (find: FindGrafico) => void;
}

interface ITop {
    abertas: number;
    andamento: number;
    pausadas: number;
    atrasadas: number;
}

interface IRight {
    recebidas: number;
    arquivadas: number;
    finalizadas: number;
}

interface IGrafico {
    recebidas: number;
    finalizadas: number;
}

const inicialTop = {
    abertas: -1,
    andamento: -1,
    pausadas: -1,
    atrasadas: -1,
}

const inicialRight = {
    recebidas: -1,
    arquivadas: -1,
    finalizadas: -1,
}

const inicialGrafico = {
    recebidas: -1,
    finalizadas: -1,
}

export const DashboardContext = React.createContext({} as IDashboardContext);

const DashboardProvider: React.FC = ({ children }) => {
    const [top, setTop] = React.useState<ITop>(inicialTop);
    const [right, setRight] = React.useState<IRight>(inicialRight);
    const [grafico, setGrafico] = React.useState<IGrafico>(inicialGrafico);

    const {setUser, setToken} = useAuth()

    const getTop = (find: Find) => {
        dashboardService.findTop(find)
        .then((res) => {
            setTop(res.data);
        })
        .catch((err) => {
            if (err.response.status === 401) {
                setUser({} as IUser);
                setToken("");
            }
        })
    }

    const getRight = (find: Find) => {
        dashboardService.findRight(find)
        .then((res) => {
            setRight(res.data);
        })
        .catch((err) => {
            if (err.response.status === 401) {
                setUser({} as IUser);
                setToken("");
            }
        })
    }

    const getGrafico = (find: FindGrafico) => {
        dashboardService.findGrafico(find)
        .then((res) => {
            setGrafico(res.data);
        })
        .catch((err) => {
            if (err.response.status === 401) {
                setUser({} as IUser);
                setToken("");
            }
        })
    }

    return (
        <DashboardContext.Provider value={{ top, right, grafico, getTop, getRight, getGrafico }}>
            {children}
        </DashboardContext.Provider>
    );
};

export default DashboardProvider;

export const useDashboard = () => React.useContext(DashboardContext);