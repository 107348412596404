import { MenuIconContainer, IconHoverBar, IconImg, IconText, IconLogo } from './styles'
import { useLocation } from 'react-router-dom'
interface IconProps {
    img?: any;
    imgExpanded?: any;
    showImg?: any;
    description?: string;
    class?: string;
    onClick?: any;
    onBlur?: () => void;
    content?: string;
    logo?: any;
    active?: string;
}

const MenuIcon = (props: IconProps) => {

    const location = useLocation();
    
    let img = props.img.slice(14).split('.');

    const checkRoute = (route: string | undefined) => {
        if(Number(route?.length) > 2){return location.pathname.includes(String(route))}
    }

    return (
        <MenuIconContainer onBlur={props.onBlur} onClick={() => {props.onClick()}} tabIndex={1} className={`${props.class} ${img[0]} ${checkRoute(props.active)? 'Active':''}`}>
            <IconHoverBar className={'Icon-Hover-Bar'}/>
            <IconImg className={`${img[0]} ${props.class}`} src={props.img} alt="menu icon"/>
            <IconImg className={`Close ${props.class} ${props.showImg}`} src={props.imgExpanded} alt="menu icon"/>
            <IconLogo src={props.logo} className={`${props.class} Logo`}/>
            <IconText className={`${props.class}`}>{props.content}</IconText>
        </MenuIconContainer>
    );
}

export default MenuIcon;