import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 6.875rem;
    height: 100%;
    background-color: #FBFBFB;
`;

export const ConfigTitleContainer = styled.div`
    position: absolute;
    top: 100px;
    padding-top: 4px;
`;

export const ConfigTitle = styled.h1`
    display: flex;
    font-weight: 700;
    font-size: 36px;
    color: #444053;
    height: 30px;
`;

export const ConfigSubtitle = styled.h2`
    display: flex;
    font-weight: 400;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 10px;
`;

export const ConfigMainContainer = styled.main`
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    justify-content: center;
    gap: 3rem;
    padding-top: 108px;
`;

export const ConfigListContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30%;
    max-height: 100%;
    gap: 1rem;
`;

export const ConfigListTitle = styled.div`
    font-weight: 500;
    font-size: 1.125rem;
    color: #444053;
`;

export const ConfigListCreateButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 28px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    cursor: pointer;
`;

export const ConfigListCreateButtonIcon = styled.img`
    transform: rotate(45deg);
    &.hide {
        z-index: -1;
    }
`;

export const ConfigListCardsContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    height: 100%;
    overflow-Y: auto;
    padding-right: 4px;
    margin-bottom: 20px;
    gap: 0.5rem;
    &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
        border-radius: 9999px;
        margin-left: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 9999px;
        margin-right: 8px;
    }
`;

// Create Local Modal

export const ConfigListLocalModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 24px;
    min-width: 43.75rem;
    min-height: 25.375rem;
`;

export const ConfigListLocalModalTopContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ConfigListLocalModalTitle = styled.h3`
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 22px;
    letter-spacing: -0.25px;
    color: #000000;
`;

export const ConfigListLocalModalCloseIconContainer = styled.form`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: ease background-color 0.3s;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const ConfigListLocalModalCloseIcon = styled.img`
    width: 14px;
`;

export const ConfigListLocalModalCenterContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const ConfigListLocalModalDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const ConfigListLocalModalDescriptionLabel = styled.label`
    font-weight: 400;
    font-size: 1.125rem;
    color: #444053;
    margin-top: 1rem;
`;

export const ConfigListLocalModalDescriptionTextArea = styled.textarea`
    min-height: 100px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    background: rgba(229, 229, 229, 0.1);
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.25px;
    color: #2f2e41;
    outline: none;
    -webkit-appearance: none;
    resize: none;
`;

export const ConfigListLocalModalButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
`;
// Create Job Modal
export const ConfigListJobModalCenterContainer = styled.div`
    display: flex;
    gap: 26px;
`;

export const ConfigListJobModalLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const ConfigListJobModalRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: none;
    margin-top: 24px;
    &.update {
        display: flex;
    }
`;

export const ConfigListJobModalFooterButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ConfigListJobModalIconsContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
`;

export const ConfigListJobModalIconsTitle = styled.h3`
    font-weight: 400;
    font-size: 1.125rem;
    color: #444053;
`;

export const ConfigListJobModalViewContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 1.25rem;
    gap: 8px;
`;

export const ConfigListJobModalViewIconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #124a6b;
`;

export const ConfigListJobModalViewIcons = styled.img`
    max-width: 14px;
`;

export const ConfigListJobModalFileLabel = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 40px;
    font-weight: 500;
    color: #124a6b;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    gap: 12px;
    margin-top: 1.5rem;
    margin-left: 2.5rem;
    cursor: pointer;
    transition: ease background-color 0.3s;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.08);
    }
`;

export const ConfigListJobModalImportButton = styled.input`
    display: none;
`;

export const ConfigListJobModalImportButtonIcon = styled.img`

`;

export const ConfigListJobModalRightInputContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;
`;

export const ConfigListJobModalSearchIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: ease background-color .3s;
    left: 10px;
    top: 54px;
    z-index: 1;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const ConfigListJobModalSearchIcon = styled.img`

`;

export const ConfigListJobModalColabsListContainer = styled.div`
    position: relative;
`;

export const ConfigListJobModalColabsTopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 24px 0 8px 0;
`;

export const ConfigListJobModalColabsListTitle = styled.h3`
    font-weight: 400;
    font-size: 1.125rem;
    color: #444053;
`;

export const ConfigListJobModalColabsListTotal = styled.span`
    font-weight: 400;
    font-size: 1.125rem;
    color: rgba(68, 64, 83, 0.7);
`;

export const ConfigListJobModalColabsCardContainer = styled.div`
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    position: absolute;
    width: 100%;
    top: 16px;
    opacity: 0;
    z-index: -1;
    transition: ease opacity .3s;
    &.show {
        opacity: 1;
        z-index: unset;
    }
    &.hide {
        z-index: unset;
        opacity: 0;
    }
`;

export const ConfigListJobModalColabCard = styled.div`
    cursor: pointer;
    padding: 12px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const AddedWorkersContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 260px;
    width: 100%;
    margin-top: 22px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 4px;
        background: transparent;
        border-radius: 9999px;
        margin-left: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 9999px;
        margin-right: 8px;
    }
`;

export const AddedWorkersCard = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
`;

export const ConfigListJobModalColabUsername = styled.span`
    font-weight: 400;
    font-size: 1.125rem;
    color: #44405399;
`;

export const ConfigListJobModalColabStrong = styled.strong`
    font-weight: 400;
    font-size: 1.125rem;
    color: #444053;
`;

export const ConfigListJobModalColabTrashIconContainer = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease background-color .3s;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export const ConfigListJobModalColabTrashIcon = styled.img`

`;