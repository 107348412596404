import React from "react";
import {
    Container,
    NotificationCardContentContainer,
    NotificationCardContentEnd,
    NotificationCardContentTop,
    NotificationCardDate,
    NotificationCardIcon,
    NotificationCardIconContainer,
    NotificationCardLeftContainer,
    NotificationCardRightContainer,
    NotificationCardRightIcon,
    NotificationCardStrongOsNumber,
} from "./styles";
import Bell from "../../assets/icons/dashboard-menu/Bell-White.svg";
import Close from "../../assets/icons/users-icon/Close.svg";
import { useNotificacao } from "../../contexts/notificacao.contex";

interface INotificationsCards {
    notificacao: {
        id: string;
        titulo: string;
        message: string;
        profissional: {
            id: string;
            nome: string;
            data_nascimento: string;
            matricula: string;
            email: string;
            telefone: string;
            cpf: string;
            active: boolean;
            id_login: string;
        };
        created_at: Date;
    };
}

export const NotificationsCards = (props: INotificationsCards) => {
    const { confirma } = useNotificacao();

    const _onClick = () => {
        confirma([props.notificacao.id]);
    };

    return (
        <Container>
            <NotificationCardLeftContainer>
                <NotificationCardIconContainer>
                    <NotificationCardIcon src={Bell} />
                </NotificationCardIconContainer>
                <NotificationCardContentContainer>
                    <NotificationCardContentTop>
                        {props.notificacao.titulo} -{" "}
                        {props.notificacao.message.split(" ")[2].trim()}
                        <NotificationCardDate>{`${props.notificacao.created_at
                            .toString()
                            .split("-")[2]
                            .slice(0, 2)
                            .trim()}/${
                            props.notificacao.created_at
                                .toString()
                                .split("-")[1]
                        }/${
                            props.notificacao.created_at
                                .toString()
                                .split("-")[0]
                        }`}</NotificationCardDate>
                    </NotificationCardContentTop>
                    <NotificationCardContentEnd>
                        {`${props.notificacao.message
                            .split(" ")
                            .slice(0, 1)} ${props.notificacao.message
                            .split(" ")
                            .slice(1, 2)} `}
                        <NotificationCardStrongOsNumber>
                            {props.notificacao.message.split(" ")[2]}
                        </NotificationCardStrongOsNumber>
                        {` ${props.notificacao.message
                            .split(" ")
                            .slice(3, 9999)
                            .toString()
                            .replaceAll(",", " ")}`}
                    </NotificationCardContentEnd>
                </NotificationCardContentContainer>
            </NotificationCardLeftContainer>
            <NotificationCardRightContainer
                onClick={() => {
                    _onClick();
                }}
            >
                <NotificationCardRightIcon src={Close} />
            </NotificationCardRightContainer>
        </Container>
    );
};
