import React, { useState } from "react";
import MenuIcon from "../MenuIcon";
import { CenterIconNavContainer, IconNav, IconNavContainer, IconNavContainerDivisor } from "./styles";
import hamburger from '../../assets/icons/dashboard-menu/Hamburger.svg'
import acomp from '../../assets/icons/dashboard-menu/Acomp.svg';
import chat from '../../assets/icons/dashboard-menu/Chat.svg';
import colabs from '../../assets/icons/dashboard-menu/Colabs.svg';
import dash from '../../assets/icons/dashboard-menu/Dash.svg';
import clock from '../../assets/icons/dashboard-menu/History.svg';
import out from '../../assets/icons/dashboard-menu/Out.svg';
import config from '../../assets/icons/dashboard-menu/Set.svg';
import BellWhite from '../../assets/icons/dashboard-menu/Bell-White.svg';
import Close from '../../assets/icons/dashboard-menu/Close.svg'
import Logo from '../../assets/imgs/LogoICC.png'
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";

interface IIconNavDivisorBar {
    class?: string;
    state?: any;
}

export const IconNavDivisorBar = (props: IIconNavDivisorBar) => {
    return (
        <IconNavContainerDivisor className={`${props.class} ${props.state?'Expanded':''}`}/>
    );
}
export const AsideMenu = () => {
    const auth = useAuth();
    const location = useLocation();
    let history = useHistory();

    const handleSignOut = React.useCallback(() => {
        auth.signOut();
    }, [auth]);

    const MenuIcons = [
        {
            img: hamburger,
            imgExpanded: Close,
            logo: Logo
        },
        {
            img: dash,
            content: 'Dashboard',
            push: '/dashboard'
        },
        {
            img: acomp,
            content: 'Acompanhar',
            push: '/ordens'
        },
        {
            img: clock,
            content: 'Histórico',
            push: '/history',
        },
        {
            img: colabs,
            content: 'Colaboradores',
            push: '/colabs',
        },
        {
            img: chat,
            content: 'Conversas',
            push: '/chat',
        },
        {
            img: BellWhite,
            content: 'Notificações',
            push: '/notifications',
        },
        {
            img: config,
            content: 'Configurações',
            push: '/config',
        },
        {
            img: out,
            content: 'Sair',
            push: '/',
            action: handleSignOut
        },
    ]

    const[showNavMenu, setShowNavMenu] = useState(false);
    
    return (
        <IconNav className={`Fixed-nav ${showNavMenu? 'Expanded':''}`}>
            {MenuIcons.slice(0, 1).map((item, key) => {
                    return(<MenuIcon
                        key={key}
                        img={item.img}
                        imgExpanded={item.imgExpanded}
                        showImg={`${showNavMenu?'Show':''}`}
                        logo={item.logo}
                        onClick={() => {setShowNavMenu(old => !old);}}
                        onBlur={() => {setShowNavMenu(false)}}
                        class={`${showNavMenu?'Expanded':''} Icon-Container Main`}
                    />)
            })}
            <CenterIconNavContainer>
                <IconNavContainer>
                    {MenuIcons.slice(1, 7).map((item, key) => {
                        return(
                            <MenuIcon
                                key={key}
                                img={item.img}
                                class={`Icon-Container ${showNavMenu?'Expanded':''}`}
                                content={item.content}
                                onClick={() => {if(item.push !== '' || item.push !== location.pathname){history.push(`${item.push}`)}}}
                                active={item.push}
                            />
                        )
                    })}
                </IconNavContainer>
                <IconNavContainer className={`Second-Icons-Container`}>
                    <IconNavDivisorBar state={showNavMenu?'Expanded':''}/>
                    {MenuIcons.slice(7, 9).map((item, key) => {
                        return (
                            <MenuIcon
                                key={key}
                                img={item.img}
                                class={`Icon-Container ${
                                    showNavMenu ? "Expanded" : ""
                                }`}
                                content={item.content}
                                active={item.push}
                                onClick={() => {
                                    if(item.action) {
                                        item.action();
                                        return;
                                    }if(item.push !== "" ||item.push !== location.pathname){history.push(`${item.push}`)}
                            
                                }}
                            />
                        );
                    })}
                </IconNavContainer>
            </CenterIconNavContainer>
        </IconNav>
    );
}