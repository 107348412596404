import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 4.5rem;
    margin-top: 0.5rem;
    padding: 1rem;
    padding-right: 3.5rem;
`;

export const NotificationCardLeftContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const NotificationCardIconContainer = styled.div`
    background: linear-gradient(45.36deg, #3AD29F 0%, #4285F4 215.63%);
    border-radius: 8px;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NotificationCardIcon = styled.img`
    user-select: none;
    width: 1.0625rem;
`;

export const NotificationCardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
`;

export const NotificationCardContentTop = styled.span`
    font-weight: 500;
    color: #444053;
`;

export const NotificationCardStrongOsNumber = styled.strong`

`;

export const NotificationCardDate = styled.span`
    font-weight: 500;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 12px;
`;

export const NotificationCardContentEnd = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 0.25rem;
`;

export const NotificationCardContentRegistration = styled.strong`
    color: #444053;
`;

export const NotificationCardRightContainer = styled.div`
    padding: 8px;
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease background-color 0.3s;
    cursor: pointer;
    margin-top: -1.5rem;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:active {
        background-color: rgba(0, 0, 0, 0.08);
    }
`;

export const NotificationCardRightIcon = styled.img`
    user-select: none;
`;