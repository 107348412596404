import React from "react";
import { ChatCard } from "../../components/ChatCard";
import { OsChatSelectionCards } from "../../components/OsChatSelectionCards";
import { useAuth } from "../../contexts/AuthContext";
import { useChat } from "../../contexts/ChatContext";
import Layouts from "../../layouts/Main";
import Send from "../../assets/icons/Chat/Send.svg";
import Search from '../../assets/icons/Chat/Search.svg'

import io from "socket.io-client";

import {
    Container,
    OsChatHoverEdit,
    OsChatHoverEditPart,
    OsChatMain,
    OsChatMessageContainer,
    OsChatMessageMainCenterContainer,
    OsChatMessageMainContainer,
    OsChatMessageMainEndContainer,
    OsChatMessageMainEndInput,
    OsChatMessageMainEndSendButtonContainer,
    OsChatMessageMainEndSendIcon,
    OsChatMessageMainTop,
    OsChatMessageTopContainer,
    OsChatMessageTopLeft,
    OsChatMessageTopRightContainer,
    OsChatMessageTopRightContent,
    OsChatMessageTopRightIcon,
    OsChatMessageTopRightIconContainer,
    OsChatSelectionCardsContainer,
    OsChatSelectionContainer,
    OsChatSelectionSearchContainer,
    OsChatSelectionSearchIcon,
    OsChatSelectionSearchInput,
    OsChatSelectionTopContainer,
    OsChatSelectionTopContent,
    OsChatSelectionTopDownBar,
    OsChatSelectionTopSubContainer,
    OsChatSubTitle,
    OsChatTitle,
    OsTitleContainer,
} from "./styles";
import { IOs } from "../../interface/os.interface";
import { ColabCardSkeleton, IconSkeleton, Skeleton } from "../../components/Skeleton";

const socket = io(`${process.env.REACT_APP_CHAT_URL}`, {
    transports: ["websocket"],
});

const CardsShimmer = [
    {},
    {},
    {},
    {},
    {},
]
const IconShimmer = [
    {},
    {},
    {},
]

socket.on("connect", () => {
    console.log("CONNECTED: ", socket.connected);
});

export const Chat = () => {
    const body = React.useRef<any>();
    const {
        osProgress,
        osFinished,
        messages,
        getProgress,
        getFinished,
        getMessages,
        sendMessage,
    } = useChat();
    const { user } = useAuth();

    const [selected, setSelected] = React.useState(Number);
    const [selectedFinished, setSelectedFinished] = React.useState(Number);
    const [selectionNav, setSelectionNav] = React.useState(0);
    const [mess, setMess] = React.useState("");
    const [actualOs, setActualOs] = React.useState<IOs[]>([]);
    const [searchValue, setSearchValue] = React.useState('');

    let initialFind = {
        page: 0,
        search: searchValue,
        grupos: [],
        status: '',
        prioridade: ''
    };

    const _onClickProgress = (key: number, id_os: string) => {
        setSelected(key);
        getMessages(id_os);
    };
    const _onClickFinished = (key: number, id_os: string) => {
        setSelectedFinished(key);
        getMessages(id_os);
    };
    const _send = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        sendMessage(mess, actualOs[0].id);
        setMess("");
    };
    const showOsProgress = (id: string) => {
        setActualOs(
            osProgress.filter((item) => {
                return item.id === id;
            })
        );
    };
    
    const showOsFinished = (id: string) => {
        setActualOs(
            osFinished.filter((item) => {
                return item.id === id;
            })
        );
    };
    React.useEffect(() => {
        getProgress({});
        getFinished();
        setActualOs([osProgress[0]]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (actualOs[0]) {
            const handleNewMesage = () => {
                getMessages(actualOs[0].id);
            };
            socket.on(`Received${actualOs[0].id}`, handleNewMesage);
        }
    }, [actualOs, getMessages]);

    React.useEffect(() => {
        if (body.current.scrollHeight > body.current.offsetHeight) {
            body.current.scrollTop =
                body.current.scrollHeight - body.current.offsetHeight;
        }
    }, [messages]);
    
    React.useEffect(() => {
        setActualOs([osProgress[0]]);
    },[osProgress])

    React.useEffect(() => {
        getProgress(initialFind)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchValue])

    return (
        <Layouts>
            <Container>
                <OsTitleContainer>
                    <OsChatTitle>Chat das OS</OsChatTitle>
                    <OsChatSubTitle>
                        Veja as conversas de cada OS
                    </OsChatSubTitle>
                </OsTitleContainer>
                <OsChatMain>
                    <OsChatSelectionContainer>
                        <OsChatSelectionTopContainer>
                            <OsChatSelectionTopSubContainer
                                onClick={() =>
                                    setSelectionNav(0)
                                }
                                className={"Open"}
                            >
                                <OsChatSelectionTopContent
                                    topContent={"Aberta"}
                                ></OsChatSelectionTopContent>
                            </OsChatSelectionTopSubContainer>
                            <OsChatSelectionTopSubContainer
                                onClick={() => {
                                    setSelectionNav(1);
                                }}
                                className={"finished"}
                            >
                                <OsChatSelectionTopContent
                                    topContent={"Finalizada"}
                                ></OsChatSelectionTopContent>
                            </OsChatSelectionTopSubContainer>
                        </OsChatSelectionTopContainer>
                        <OsChatSelectionTopDownBar
                            className={`${
                                selectionNav === 1 ? "selected" : ""
                            }`}
                        />
                        <OsChatSelectionSearchContainer>
                            <OsChatSelectionSearchIcon src={Search}/>
                            <OsChatSelectionSearchInput value={searchValue} onChange={(e) => {setSearchValue(e.target.value)}} placeholder='Pesquisar OS por código'/>
                        </OsChatSelectionSearchContainer>
                        <OsChatSelectionCardsContainer
                            className={`${
                                selectionNav === 0 ? "selected" : ""
                            }${String(actualOs[0]?.id) === 'undefined'? ' shimmer':''}`}
                        >
                            {String(actualOs[0]?.id) !== 'undefined'
                            ?osProgress.map((item, key) => {
                                return (
                                    <OsChatSelectionCards
                                        selectedClass={`${
                                            selected === key ? "selected" : ""
                                        }`}
                                        onClick={() => {
                                            _onClickProgress(key, item.id);
                                            showOsProgress(item.id);
                                        }}
                                        notifications={0}
                                        registration={item.numero}
                                        status={item.status}
                                        key={key}
                                    />
                                );
                            }):CardsShimmer.map(() => {return <ColabCardSkeleton responsive={true} height={0} width={100}/>})}
                        </OsChatSelectionCardsContainer>
                        <OsChatSelectionCardsContainer
                            className={`${
                                selectionNav === 1 ? "selected" : ""
                            }${String(actualOs[0]?.id) === 'undefined'? ' shimmer':''}`}
                        >
                            {String(actualOs[0]?.id) !== 'undefined'?osFinished.map((item, key) => {
                                return (
                                    <OsChatSelectionCards
                                        selectedClass={`${
                                            selectedFinished === key
                                                ? "selected"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            _onClickFinished(key, item.id);
                                            showOsFinished(item.id);
                                        }}
                                        notifications={0}
                                        registration={item.numero}
                                        status={item.status}
                                        key={key}
                                    />
                                );
                            }):CardsShimmer.map(() => {return <ColabCardSkeleton responsive={true} height={0} width={100}/>})}
                        </OsChatSelectionCardsContainer>
                    </OsChatSelectionContainer>
                    <OsChatMessageContainer>
                        <OsChatMessageTopContainer>
                            <OsChatMessageTopLeft>
                                OS: {String(actualOs[0]?.numero) !== 'undefined'? actualOs[0]?.numero:<Skeleton width={160} height={24}/>}
                            </OsChatMessageTopLeft>
                            <OsChatMessageTopRightContainer>
                                <OsChatMessageTopRightContent>
                                    Encarregado (os):
                                </OsChatMessageTopRightContent>
                                <OsChatMessageTopRightIconContainer>
                                    {String(actualOs[0]?.id) !== 'undefined'?actualOs[0]?.encarregados.map((item) => {
                                        return (
                                            <OsChatMessageTopRightIcon>
                                                {item.nome.slice(0, 1)}
                                                <OsChatHoverEdit className={'edit-hover'}>
                                                    {item.nome}
                                                    <OsChatHoverEditPart className={'edit-part'}/>
                                                </OsChatHoverEdit>
                                            </OsChatMessageTopRightIcon>
                                        );
                                    }):IconShimmer.map(() => {return <IconSkeleton width={0} height={0}/>})}
                                </OsChatMessageTopRightIconContainer>
                            </OsChatMessageTopRightContainer>
                        </OsChatMessageTopContainer>
                        <OsChatMessageMainContainer>
                            <OsChatMessageMainTop></OsChatMessageMainTop>
                            <OsChatMessageMainCenterContainer ref={body}>
                                {String(messages[0]) !== 'undefined' ? (
                                    messages.map((mess) => {
                                        return (
                                            <ChatCard
                                                timer={mess.created_at}
                                                sended={
                                                    user?.id ===
                                                    mess.id_profissional
                                                        ? true
                                                        : false
                                                }
                                                username={
                                                    String(mess?.profissional?.nome) !== 'undefined'? mess?.profissional?.nome : ''
                                                }
                                            >
                                                {mess.message}
                                            </ChatCard>
                                        );
                                    })
                                ) : (
                                    <></>
                                )}
                            </OsChatMessageMainCenterContainer>
                        </OsChatMessageMainContainer>
                        <OsChatMessageMainEndContainer
                            className={`${selectionNav === 1 ? "hidden" : ""}`}
                            onSubmit={(event) => {
                                event.preventDefault();
                                mess.replaceAll(" ", "") !== "" && _send(event);
                            }}
                        >
                            <OsChatMessageMainEndInput
                                value={mess}
                                onChange={(e) => {
                                    setMess(e.currentTarget.value);
                                }}
                                maxLength={500}
                                placeholder={"Digite sua mensagem aqui"}
                            />
                            <OsChatMessageMainEndSendButtonContainer disabled={mess === ''? true:false} className={`${mess !== ''? 'able':''}`}>
                                <OsChatMessageMainEndSendIcon src={Send} />
                            </OsChatMessageMainEndSendButtonContainer>
                        </OsChatMessageMainEndContainer>
                    </OsChatMessageContainer>
                </OsChatMain>
            </Container>
        </Layouts>
    );
};
