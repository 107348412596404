import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding-left: 6.875rem;
    background-color: #FBFBFB;
    height: 100%;
`;

export const ShowMoreHistoryTitle = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    color: #444053;
    padding-top: 1rem;
`;

export const ShowMoreHistorySubTitle = styled.h2`
    font-weight: 400;
    padding-top: 1rem;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const ShowMoreHistoryTopContainer = styled.div`
    margin-top: 2rem;
`;

export const ShowMoreHistoryTopTitle = styled.h2`
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
`;

export const ShowMoreHistoryColabsContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 16px;
    margin-top: 1rem;
`;

export const ShowMoreHistoryDataOsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
`;

export const ShowMoreHistoryDataOsTitle = styled.h2`
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
`;

export const ShowMoreHistoryDataOsMain = styled.main`
    display: flex;
    height: 100%;
`;

export const ShowMoreHistoryDataOsDescriptionContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 1.5rem;
    gap: 0.5rem;
`;

export const ShowMoreHistoryDataOsDescriptionTextareaLabel = styled.label`
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 0.5px;
    color: rgba(68, 64, 83, 0.8);
`;

export const ShowMoreHistoryDataOsDescriptionTextarea = styled.textarea`
    height: 100%;
    min-height: 419px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: -0.0156rem;
    color: #333332;
    padding: 1.3125rem 1rem 0 1rem;
    background-color: #FFFFFF;
    outline: none;
    resize: none;
    border: none;
    cursor: default;
    opacity: 1;
    &:disabled {
        cursor: default;
    }
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 0 8px 8px 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 0 8px 8px 0;
    }
`;

export const ShowMoreHistoryDataOsInputsContainer = styled.div`
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    margin-left: 1rem;
    margin-top: 0.5rem;
    gap: 1rem;
`;

export const ShowMoreOsEndContainer = styled.div`
    display: flex;
    margin-top: 3.125rem;
    height: 100%;
    max-height: 29.375rem;
    justify-content: space-between;
    padding: 1.5rem;
`;

export const ShowMoreOsNotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 1.625rem;
    width: 100%;
    height: 100%;
`;

export const ShowMoreOsNotesTitle = styled.h2`
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
`;

export const ShowMoreOsCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ShowMoreOsInteractionsHistoryContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

export const ShowMoreOsInteractionsHistoryTitle = styled.h2`
    font-family: Inter;
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
    padding-left: 0.375rem;
`;

export const ShowMoreOsLogContainer = styled.div`
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    margin-top: 1.5rem;
    width: 40.625rem;
    max-width: 40.625rem;
    height: 100%;
    margin-bottom: 1.5rem;
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 8px;
    }
`;

export const ShowMoreOsLogTopContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    &.shimmer {
        padding-top: 0.9375rem;
        margin-bottom: 0.4375rem;
    }
`;

export const ShowMoreOsTopLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px;
    width: 5.875rem;
    height: 2rem;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 9999px;
    cursor: pointer;
`;

export const ShowMoreOsFilterContent = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: rgba(68, 64, 83, 0.7);
    display: flex;
`;

export const ShowMoreOsFilterIcon = styled.img`
    width: 14px;
    margin-top: 2px;
    opacity: 0.7;
`;

export const ShowMoreOsTopRight = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: #1A1A17;
    display: flex;
    padding: 8px 11px;
`;

export const ShowMoreOsMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    &.gap {
        gap: 0.875rem;
        padding: 0 1rem;
    }
`;