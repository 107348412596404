import styled from 'styled-components';

interface IOpenOsModal {
    nav?: number;
    main?: number;
    blurWidth?: string;
    blurMinWidth?: string;
    blurHeight?: string;
    blurBackgroundColor?: string;
}
interface IDetailsPriority {
    color?: string;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    z-index: 99;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    transition: ease opacity 0.3s;
    &.hidden {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
`;

export const ResponsiveContainer = styled.div<IOpenOsModal>`
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.06);
    &.unset {
        width: 100%;
        height: 100%;
        display: flex;
        width: ${({blurWidth}) => blurWidth};
        height: ${({blurHeight}) => blurHeight};
        background-color: ${({blurBackgroundColor}) => blurBackgroundColor};
    }
    &.height {
        height: 26px;
        width: 100%;
        width: ${({blurWidth}) => blurWidth};
        height: ${({blurHeight}) => blurHeight};
        background-color: rgba(0, 0, 0, 0.2);
        background-color: ${({blurBackgroundColor}) => blurBackgroundColor};
        &.bottom {
        }
    }
    &.width {
        height: 100%;
        width: 24px;
        width: ${({blurWidth}) => blurWidth};
        height: ${({blurHeight}) => blurHeight};
        background-color: rgba(0, 0, 0, 0.15);
        background-color: ${({blurBackgroundColor}) => blurBackgroundColor};
        &.left {
            min-width: 94px;
            min-width: ${({blurMinWidth}) => blurMinWidth};
            background-color: ${({blurBackgroundColor}) => blurBackgroundColor};
        }
    }
`;

export const OsModalNoneContainer = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
`;

export const OsModalSection = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 24px 64px;
    background-color: #FFFFFF;
`;

export const OsModalHeader = styled.header`
    display: flex;
    justify-content: space-between;
`;

export const OsModalHeaderCriationStats = styled.div`
    display: flex;
    align-items: center;
`;

export const OsModalHeaderCriationStatsIcon = styled.span`
    color: #623CEA;
    font-family: roboto, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: -0.25px;
    padding: 0.3125rem 0.625rem;
    margin-right: 1rem;
    border-radius: 50%;
    box-shadow: 0px 4px 16px rgba(68, 64, 83, 0.15);
    transition: ease background-color 0.3s, ease color 0.3s;
    &.done.Stats {
        background: #50D7AA;
        color: #FFFFFF;
    }
    &.Stats.selected {
        background-color: #623CEA;
        color: #FFFFFF;
    }
`;

export const OsModalHeaderCriationStatsContent = styled.h3`
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: -0.25px;
    color: #623CEA;
    margin-right: 1rem;
    transition: ease color 0.3s;
    &.done.Stats {
        color: #50D7AA;
    }
`;

export const OsModalHeaderCriationStatsDottsContainer = styled.div`
    min-width: 56px;
    max-width: 56px;
    height: 3px;
    display: flex;
    transition: ease background-color 0.3s, ease max-height 0.3s, ease border-radius 0.3s;
    &.done.Stats {
        height: 3px;
        border-radius: 9999px;
        background-color: #50D7AA;
    }
`;

export const OsModalHeaderCriationStatsDotts = styled.span`
    background-color: #cecece;
    margin-right: 7px;
    width: 2px;
    height: 2px;
    padding: 1.5px;
    border-radius: 50%;
    margin-top: 4px;
    align-self: center;
    transition: ease opacity 0.3s;
    &.done.Stats {
        opacity: 0;
    }
`;

export const OsModalMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 60%;
    position: relative;
`;

export const OsModalMain = styled.main<IOpenOsModal>`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    top: 6rem;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    transition: ease opacity 0.3s, ease z-index 0.3s;
    opacity: ${({nav, main}) => `${nav === main? '':'0'}`};
    z-index: ${({nav, main}) => `${nav === main? '':'-1'}`};
`;

export const OsModalMainSelectContainer = styled.div`

`;

export const OsModalMainSelectLabel = styled.label`
    display: inline-block;
    font-weight: 400;
    color: #444053;
    font-size: 1.125rem;
    margin: 24px 0 12px 0;
`;

export const OsModalMainTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 2rem;
`;

export const OsModalMainSubTitle = styled.h3`
    font-weight: 400;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 0.5rem;
`;

export const OsModalMainForm = styled.form`
    display: flex;
    height: 100%;
    margin-top: -16px;
    flex-direction: column;
`;

export const OsModalMainDetailsPriorityContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const OsModalMainDetailsPriority = styled.div<IDetailsPriority>`
    height: 3rem;
    max-width: 223px;
    min-width: 223px;
    border-radius: 4px;
    border: 1px solid ${({color}) => color};
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const OsModalMainDetailsPriorityIcon = styled.img`
    position: absolute;
    left: 21px;
    opacity: 0;
    transition: ease opacity 0.3s;
    &.show {
        opacity: 1;
    }
`;

export const OsModalMainDetailsPriorityContent = styled.span<IDetailsPriority>`
    font-weight: 600;
    font-size: 1.125rem;
    color: ${({color}) => color};
`;

export const OsModalMainDetailsPriorityTextArea = styled.textarea`
    width: 100%;
    min-height: 100px;
    height: 100%;
    font-weight: 400;
    font-size: 18px;
    padding: 13px 0 0 25px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.8);
    outline: none;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background: rgba(229, 229, 229, 0.1);
    transition: ease border 0.3s;
    resize: none;
    opacity: 0.6;
    &:focus {
        border: 1px solid #124A6B;
    }
    &:valid {
        border: 1px solid #124A6B;
        opacity: 1;
    }
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 0 8px 8px 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 0 8px 8px 0;
    }
`;

export const OsModalFooter = styled.footer`
    display: flex;
    height: 40%;
    align-items: flex-end;
    justify-content: space-between;
`;
