import styled from 'styled-components';
import { shimmer } from '../../styles/global';

export interface ISkeleton {
  height: string | number;
  width: string | number;
  responsive?: boolean;
  marginTop?: number;
}

export const Container = styled.div<ISkeleton>`

  height: ${({height}) => `${Number(height)/16}rem`};
  width: ${({width, responsive}) => `${width && responsive? `${width}%`: width ? `${(Number(width)/16)}rem`:''}`};
  min-width: ${({width}) => `${Number(width)/16}rem`};
  margin-top: ${({marginTop}) => `${marginTop? `${Number(marginTop)/16}rem`:''}`};
  background: #C4C4C4;
  border-radius: 100px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} .5s ease-in-out infinite;
`;

export const ContainerInput = styled.div<ISkeleton>`
  height: ${({height}) => `${Number(height)/16}rem`};
  width: ${({width, responsive}) => `${width && responsive? `${width}%`: width ? `${Number(width)/16}rem`:''}`};
  min-width: ${({width}) => `${Number(width)/16}rem`};
  margin-top: 16px;
  margin-top: ${({marginTop}) => `${marginTop? `${Number(marginTop)/16}rem`:''}`};
  gap: 12px;
  display: flex;
  flex-direction: column;
  .label {
    width: 108px;
    height: 1.25rem;
    margin-bottom: 0.25rem;
    border-radius: 100px;
    background: #C4C4C4;
    background-image: linear-gradient(
      -90deg,
      #e7edf1 0%,
      #f8f8f8 50%,
      #e7edf1 100%
    );
    background-size: 400% 400%;
    animation: ${shimmer} .5s ease-in-out infinite;
  }
  .input {
    width: 100%;
    height: 48px;
    border-radius: 100px;
    background: #C4C4C4;
    background-image: linear-gradient(
      -90deg,
      #e7edf1 0%,
      #f8f8f8 50%,
      #e7edf1 100%
    );
    background-size: 400% 400%;
    animation: ${shimmer} .5s ease-in-out infinite;
  }
`;

export const IconContainer = styled.div<ISkeleton>`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #C4C4C4;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: ${shimmer} .5s ease-in-out infinite;
`;

export const ContainerColabCard = styled.div<ISkeleton>`
  width: ${({width, responsive}) => `${ width === 0? `${22}%`: width && responsive? `${width}%`:`${(Number(width)/16)}rem`}`};
  display: flex;
  height: ${({height, responsive}) => `${height === 0? `${4}rem`: height && responsive? `${height}%`:`${Number(height)/16}rem`}`};
  background-color: #FFFFFF;
  box-shadow: 0px 0.125rem 0.5rem rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  padding: 0.625rem 0.75rem 0.875rem 1rem;
  .icon {
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    background: #C4C4C4;
    border-radius: 100px;
    margin-right: 1rem;
    background-image: linear-gradient(
      -90deg,
      #e7edf1 0%,
      #f8f8f8 50%,
      #e7edf1 100%
    );
    background-size: 400% 400%;
    animation: ${shimmer} .5s ease-in-out infinite;
  }
  .main-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    .colab {
      width: 5.9375rem;
      height: 1.375rem;
      border-radius: 100px;
      background: #C4C4C4;
      border-radius: 100px;
      background-image: linear-gradient(
        -90deg,
        #e7edf1 0%,
        #f8f8f8 50%,
        #e7edf1 100%
      );
      background-size: 400% 400%;
      animation: ${shimmer} .5s ease-in-out infinite;
    }
    .group {
      width: 7.75rem;
      height: 1.375rem;
      border-radius: 100px;
      background: #C4C4C4;
      border-radius: 100px;
      background-image: linear-gradient(
        -90deg,
        #e7edf1 0%,
        #f8f8f8 50%,
        #e7edf1 100%
      );
      background-size: 400% 400%;
      animation: ${shimmer} .5s ease-in-out infinite;
    }
  }
`;

export const NotesContainer = styled.div<ISkeleton>`
  display: flex;
  width: 100%;
  max-width: 32rem;
  margin-top: 1.5rem;
  min-height: 14px;
  height: 162px;
  padding: 0.5rem;
  gap: 16px;
  background-color: #FFFFFF;
  .icon {
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background: #C4C4C4;
    border-radius: 100px;
    background-image: linear-gradient(
      -90deg,
      #e7edf1 0%,
      #f8f8f8 50%,
      #e7edf1 100%
    );
    background-size: 400% 400%;
    animation: ${shimmer} .5s ease-in-out infinite;
  }
  .main-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    gap: 1rem;
    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .title {
        width: 84px;
        height: 1.375rem;
        border-radius: 50%;
        background: #C4C4C4;
        border-radius: 100px;
        background-image: linear-gradient(
          -90deg,
          #e7edf1 0%,
          #f8f8f8 50%,
          #e7edf1 100%
        );
        background-size: 400% 400%;
        animation: ${shimmer} .5s ease-in-out infinite;
      }
      .date {
        width: 7.3125rem;
        height: 1.375rem;
        border-radius: 50%;
        background: #C4C4C4;
        border-radius: 100px;
        background-image: linear-gradient(
          -90deg,
          #e7edf1 0%,
          #f8f8f8 50%,
          #e7edf1 100%
        );
        background-size: 400% 400%;
        animation: ${shimmer} .5s ease-in-out infinite;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      gap: 10px;
      .content-rows {
        width: 100%;
        height: 1rem;
        border-radius: 50%;
        background: #C4C4C4;
        border-radius: 100px;
        background-image: linear-gradient(
          -90deg,
          #e7edf1 0%,
          #f8f8f8 50%,
          #e7edf1 100%
        );
        background-size: 400% 400%;
        animation: ${shimmer} .5s ease-in-out infinite;
      }
    }
  }

`;