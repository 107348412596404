/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IUser } from "../interface/user.interface";
import { anotacaoService } from "../services/anotacao.service";
import { useAuth } from "./AuthContext";

interface IAnotacaoContext {
    anotacoes: IAnotacao[];
    getAnotacoes: (id_os: string) => void;
}

interface IAnotacao {
    id: string;
    id_os: string;
    nome: string;
    anotacao: string;
    created_at: string;
}

export const AnotacaoContext = React.createContext({} as IAnotacaoContext);

const AnotacaoProvider: React.FC = ({ children }) => {
    const [anotacoes, setAnotacoes] = React.useState<IAnotacao[]>([]);

    const { setUser, setToken } = useAuth();

    const getAnotacoes = (id_os: string) => {
        anotacaoService.find(id_os)
            .then((res) => {
                setAnotacoes(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
					setUser({} as IUser);
					setToken("");
				}
            })
    }

    return (
        <AnotacaoContext.Provider value={{ anotacoes, getAnotacoes }}>
            {children}
        </AnotacaoContext.Provider>
    );
};

export default AnotacaoProvider;

export const useAnotacao = () => React.useContext(AnotacaoContext);