import styled from 'styled-components';
import {IModal} from '../../interface/modal.interface'

export const Container = styled.div<IModal>`
    display: flex;
    flex-direction: ${({blurContainerDirection}) => blurContainerDirection};
    position: ${({position}) => position};
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.18);
    top: 0;
    opacity: 1;
    z-index: 2;
    transition: ease opacity .3s;
    &.hide {
        opacity: 0;
        transition: z-index 0.4s, ease opacity .3s;
        z-index: -1;
    }
    &.sucess {
        z-index: 10;
    }
`;
