import styled from 'styled-components';

export const ChartContainer = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
    position: relative;
    flex-direction: column;
    .Doughnut {
        height: 12.5rem !important;
        width: 12.5rem !important;
    }
`;

export const ChartInfo = styled.h3`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 52px;
    left: 50px;
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    line-height: 6.25rem;
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 9999px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.14);
`;

export const ChartTitle = styled.h3`
    font-weight: 400;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: -40px;
    left: 44px;
    text-align: center;
`;