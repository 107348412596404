import styled from 'styled-components';

interface IInput {
    label?: string
}

export const Container = styled.div`
    width: 100%;
`;

export const Label = styled.label<IInput>`
    display: flex;
    font-weight: 400;
    color: #444053;
    font-size: 1.125rem;
    margin: ${({label}) => `${label? '1.5rem 0 0.75rem 0':''}`};
`;

export const Input = styled.input`
    width: 100%;
    font-size: 1.125rem;
    font-weight: 400;
    padding: 0.8125rem 1rem;
    opacity: 0.6;
    background: rgba(229, 229, 229, 0.1);
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    transition: ease-out border 0.3s, ease-out border-radius 0.3s;
    &:focus {
        border: 1px solid #124A6B;
        border-radius: 4px;
    }
    &:valid {
        border: 1px solid #124A6B;
    }
`;