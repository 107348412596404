import styled from 'styled-components';

interface ISlide {
    page:  number;
}

export const Container = styled.main`
    display: flex;
    width: 100%;
    height: 100vh;
`;

export const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    padding: 56px 85px 80px 56px;
    background-color: #FFFFFF;
`;

export const Logo = styled.img`
    max-width: 8.125rem;
`;

export const Title = styled.h1`
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.5px;
    color: var(--brand-primary-default);
    margin-top: 3.875rem;
`;

export const SubTitle = styled.h2`
    font-weight: 500;
    font-size: 1rem;
    color: rgba(68, 64, 83, 0.7);
    margin-top: 0.5rem;
    margin-bottom: 0.625rem;
`;

export const Form = styled.form`

`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2.25rem;
    position: relative;
    gap: 1rem;
    &.first {
        margin-top: 1.25rem;
    }
    &.radio {
        flex-direction: row;
        &:hover {
            .radio {
                background: rgba(229, 229, 229, 0.7);
            }
        }
    }
`;

export const InputLabel = styled.label`
    font-weight: 600;
    color: var(--brand-primary-default);
`;

export const Input = styled.input`
    font-weight: 400;
    font-size: 1.125rem;
    color: #444053;
    min-width: 35.4375rem;
    min-height: 3.25rem;
    border: none;
    outline: none;
    padding: 0 0.75rem;
    background: rgba(229, 229, 229, 0.2);
    border-radius: 8px 8px 0px 0px;
    &:focus {
        border-bottom: 3px solid var(--brand-primary-default);
    }
    &:valid {
        border-bottom: 3px solid var(--brand-primary-default);
    }
`;

export const ShowPasswordContainer = styled.div`
    position: absolute;
    top: 2.625rem;
    width: 2.5rem;
    height: 2.5rem;
    right: 0.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export const ShowPassword = styled.img`
    width: 1.5rem;
`;

export const MainButton = styled.button`
    font-weight: 500;
    font-size: 1.5rem;
    color: #FFFFFF;
    min-width: 35.4375rem;
    min-height: 3.25rem;
    background-color: var(--brand-primary-default);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-top: 2.5rem;
    cursor: pointer;
    user-select: none;
    border: none;
    outline: none;
    &:hover {
        background-color: rgba(18, 74, 107, 0.97);
    }
    &:active {
        background-color: rgba(18, 74, 107, 0.95);
    }
`;

export const Footer = styled.footer`
    display: flex;
    align-self: center;
    margin-top: 2rem;
`;

export const FooterContent = styled.em`
    display: flex;
`;

export const FooterImg = styled.img`
    margin-left: 0.5rem;
`;

export const RightContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: linear-gradient(180deg, #7BBC9C 0%, #124A6B 148.54%);
`;

export const RightContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-content: center;
    position: absolute;
    opacity: 0;
    padding: 0 1rem;
    margin-top: -127px;
    padding-top: 120px;
    margin-left: -300px;
    transition: ease margin-left .3s;
    z-index: -1;
    &.right {
        margin-left: 300px;
    }
    &.selected {
        transition: ease opacity .3s, ease margin-left .3s;
        z-index: unset;
        margin-left: 0;
        opacity: 1;
    }
`;

export const RightTitle = styled.h1`
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
`;

export const RightImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RightImg = styled.img`

`;

export const RightSubTitle = styled.h2`
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    padding-bottom: 50px;
`;

export const RightMainContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
`;

export const RightNavIconContainer = styled.div`
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    cursor: pointer;
    user-select: none;
    transition: ease opacity .3s;
    &:hover {
        background-color: rgba(255, 255, 255, 0.175);
    }
    &:active {
        background-color: rgba(255, 255, 255, 0.15);
    }
    &.left {
        transform: rotate(0deg);
    }
    &.disabled {
        opacity: 0;
        z-index: -1;
    }
`;

export const RightNavIcon = styled.img`
    margin-right: 4px;
`;

export const RightFooterNavContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1.25rem;
    padding-bottom: 2rem;
    position: relative;
`;

export const RightFooterNav = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
`;

export const RightFooterSelectPoint = styled.div<ISlide>`
    width: 0.5rem;
    height: 0.5rem;
    background-color: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    transition: ease margin-left .3s;
    margin-left: ${({page}) => `${page === 1? '72px': page === 2? '144px': page === 3? '216px': page === 4? '288px':''}`};
`;