import React from 'react';
import { Container, LogCardAction, LogCardLeftContainer, LogCardRightContainer, LogCardUsername, LogCardValue } from './styles';

interface ILogCards {
    username: string;
    action?: string;
    value?: string;
    createdAt?: string;
}

export const LogCards = (props: ILogCards) => {
    return (
        <Container>
            <LogCardLeftContainer>
                <LogCardUsername>{props?.username?.split(' ').slice(0, 2).map((item) => {return `${item.slice(0, 1)}${item.slice(1, 99).toLocaleLowerCase()} `})}</LogCardUsername>
                <LogCardAction>{props?.action}</LogCardAction>
                <LogCardValue>{props?.value?.split(' ').slice(0, 2).map((item) => {return `${item.slice(0, 1) + item.toLocaleLowerCase().slice(1, 99)} `})}</LogCardValue>
            </LogCardLeftContainer>
            <LogCardRightContainer>{`${props.createdAt?.split('-')[2]?.slice(0, 2)?.trim()}/${props.createdAt?.split('-')[1]}/${props.createdAt?.split('-')[0]}`}</LogCardRightContainer>
        </Container>
    );
}
