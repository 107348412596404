import { CreateJobsDTO } from "../interface/create-job-dto-interface";
import { IJobs } from "../interface/job.interface";
import { IJobsSelect } from "../interface/jobs-select.interface";
import { api } from "./api.service";

export const getJobs = async () => {
    const result = await api().get<IJobs[]>(`/grupos`);
    return result.data;
};

export const getJobsSelect = async () => {
    const result = await api().get<IJobsSelect[]>(`/grupos`);
    return result.data;
};

export const createJob = async (job: CreateJobsDTO) => {
    const result = await api().post<IJobs>("/grupos", job);
    return result.data;
};

export const updateJob = async (id: string, job: CreateJobsDTO) => {
    await api().put<IJobs>(`/grupos/${id}`,job);
};

export const deleteJob = async (id: string) => {
    await api().delete(`/grupos/${id}`);
};
