import styled from 'styled-components';

interface IProfile {
    errorMessage: string;
}

export const Container = styled.div`
    width: 100%;
    padding-left: 6.875rem;
    height: 100%;
    align-self: center;
    justify-self: center;
    padding-top: 1rem;
    background-color: #FBFBFB;
`;

export const ConfigTitle = styled.h1`
    font-weight: 700;
    font-size: 2.25rem;
    color: #444053;
`;

export const ConfigSubTitle = styled.h2`
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 1rem;
`;

export const ConfigMainContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ConfigMainTopContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
`;

export const ConfigMainTopTitle = styled.h2`
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;

`;

export const ConfigMainTopInputsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    max-width: 100%;
    flex-wrap: wrap;
    margin-left: 0px;
    gap: 1rem;
`;

export const ConfigInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 22%;
`;

export const ConfigInputsErrorMessage = styled.span<IProfile>`
    display: flex;
    color: ${({errorMessage}) => `${errorMessage}`};
`;

export const ConfigMainChangePasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2.5rem;
`;

export const ConfigMainRowInputsContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const ConfigMainChangePasswordTitle = styled.h2`
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
`;

export const ConfigMainChangePasswordInputsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    gap: 1rem;
`;