import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding-left: 6.875rem;
    height: 100%;
    background-color: #FBFBFB;
`;

export const ShowMoreOsTitle = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    color: #444053;
    margin-top: 1rem;
`;

export const ShowMoreOsSubTitle = styled.h2`
    font-weight: 400;
    padding-top: 1rem;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const ShowMoreOsTopContainer = styled.div`
    margin-top: 2rem;
`;

export const ShowMoreOsTopTitle = styled.h2`
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
`;

export const ShowMoreOsColabsContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
`;

export const ShowMoreOsAddColab = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22%;
    height: 4rem;
    min-width: 18.75rem;
    border: 0.0625rem solid #CCCCCC;
    box-sizing: border-box;
    filter: drop-shadow(0px 0.125rem 0.5rem rgba(0, 0, 0, 0.05));
    border-radius: 0.375rem;
    font-weight: 500;
    color: #124A6B;
    cursor: pointer;
`;

export const ShowMoreOsDataOsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
`;

export const ShowMoreOsDataOsTitle = styled.h2`
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
`;

export const ShowMoreOsDataOsMain = styled.main`
    display: flex;
`;

export const ShowMoreOsDataOsDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    position: relative;
    margin-right: 0.5rem;
    gap: 0.5rem;
`;

export const ShowMoreOsDataOsDescriptionTextareaLabel = styled.label`
    font-weight: 500;
    line-height: 1.125rem;
    letter-spacing: 0.5px;
    color: rgba(68, 64, 83, 0.8);
`;

export const ShowMoreOsDataOsDescriptionTextarea = styled.textarea`
    height: 100%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: -0.0156rem;
    color: #333332;
    padding: 1.3125rem 1rem 0 1rem;
    outline: none;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    resize: none;
    background-color: #FFFFFF;
    border: none;
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 0 8px 8px 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 0 8px 8px 0;
    }
    &:disabled {
        cursor: default;
        color: #33333299;
        opacity: 1;
    }
`;

export const TrackOsEditIconContainer = styled.div`
    background: rgba(229, 229, 229, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    position: absolute;
    right: 1rem;
    bottom: 40px;
    cursor: pointer;
    transition: ease background-color 0.15s;
    &.editable {
        display: flex;
    }
    &:hover {
        background: rgba(229, 229, 229, 0.7);
    }
    &:active {
        background: rgba(229, 229, 229, 0.85);
    }
`;

export const TrackOsEditIcon = styled.img`

`; 

export const ShowMoreOsIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.125rem;
    height: 2.125rem;
    cursor: pointer;
    border-radius: 50%;
    transition: ease background-color 0.5s, ease opacity 0.15s;
    user-select: none;
    right: 0;
    bottom: 20px;
    position: absolute;
    opacity: 1;
    z-index: 1;
    &.description {
        right: 16px;
    }
    &.clean-initial {
        opacity: 0;
        z-index: -1;
    }
    &.clean.clean {
        opacity: 1;
        z-index: 1;
    }
    &.config {
        top: 2.25rem;
    }
    &:hover {
        transition: ease opacity 0.3s;
        background: rgba(229, 229, 229, 0.5);
        .edit-hover {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            bottom: -36px;
            width: 4rem;
            height: 1.5rem;
            background-color: #061D28;
            color: #FFFFFF;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 0.875rem;
            opacity: 1;
            z-index: -1;
            left: unset;
            transition: ease opacity 0.3s;
            &.disable {
                opacity: 0;
                z-index: -1;
            }
        }
        .edit-part {
            background-color: #061D28;
            position: absolute;
            z-index: -1;
            top: -5px;
            width: 16px;
            left: unset;
            height: 16px;
            transform: rotate(45deg);
            &.disable {
                opacity: 0;
                z-index: -1;
            }
        }
    }
    &:active {
        background: rgba(229, 229, 229, 0.8);
    }
    &.clean.active {
        opacity: 0;
        z-index: -1;
        width: 0;
        position: unset;
        height: 0;
        cursor: pointer;
    }
    &.edit.clean {
        opacity: 0;
        z-index: -1;
    }
    &.hidden {
        z-index: -1;
        opacity: 0;
    }
    &.green {
        transition: ease background-color 0.3s;
        background-color: rgba(71, 226, 142, 1);
    }
`;

export const ShowMoreOsEditIcon = styled.img`
    position: absolute;
    &.clean {
        opacity: 0;
        z-index: -1;
    }
    &.green {
        opacity: 1;
        z-index: unset;
    }
    &.unset.green {
        opacity: 0;
        z-index: -1;
    }
`;

export const ShowMoreOsHoverEdit = styled.div`
    opacity: 0;
    z-index: -1;
    height: 0;
    left: 5000px;
    width: 0;
    position: absolute;
`;

export const ShowMoreOsHoverEditPart = styled.div`
    z-index: -1;
    height: 0;
    left: 5000px;
    width: 0;
    &.disable {
        opacity: 0;
        z-index: -1;
    }
`;

export const ShowMoreOsDataOsInputsContainer = styled.div`
    display: flex;
    width: 75%;
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 0.7rem;
    gap: 1rem;
    padding-bottom: 7.75rem;
`;

export const ShowMoreOsEndContainer = styled.div`
    display: flex;
    margin-top: 3.125rem;
    height: 100%;
    max-height: 470px;
    justify-content: space-between;
    padding: 24px;
`;

export const ShowMoreOsInputsRowContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5rem;
    padding-right: 2.5rem;
`;

export const ShowMoreOsSelectContainer = styled.div`
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    width: 33%;
    height: 5.625rem;
    gap: 0.5rem;
`;

export const ShowMoreOsSelectLabel = styled.label`
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    letter-spacing: 0.0313rem;
    color: rgba(68, 64, 83, 0.8);
`;

export const ShowMoreOsNotesContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-right: 1.625rem;
    height: 100%;
`;

export const ShowMoreOsNotesTitle = styled.h2`
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
`;

export const ShowMoreOsCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ShowMoreOsInteractionsHistoryContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

export const ShowMoreOsInteractionsHistoryTitle = styled.h2`
    font-family: Inter;
    font-weight: 500;
    font-size: 1.375rem;
    color: #444053;
    padding-left: 0.375rem;
`;

export const ShowMoreOsLogContainer = styled.div`
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 6px;
    margin-top: 1.5rem;
    width: 40.625rem;
    height: 100%;
    overflow: auto;
    position: relative;
    margin-bottom: 1.75rem;
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 8px;
    }
`;

export const ShowMoreOsLogTopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    position: sticky;
    top: 0;
    background-color: #FFFFFF;
    &.shimmer {
        padding-top: 0.9375rem;
        margin-bottom: 0.4375rem;
    }
`;

export const ShowMoreOsTopLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 16px;
    width: 5.875rem;
    height: 2rem;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 9999px;
    cursor: pointer;
`;

export const ShowMoreOsFilterContent = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: rgba(68, 64, 83, 0.7);
    display: flex;
`;

export const ShowMoreOsFilterIcon = styled.img`
    width: 14px;
    margin-top: 2px;
    opacity: 0.7;
`;

export const ShowMoreOsTopRight = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: #1A1A17;
    display: flex;
    padding: 8px 11px;
`;

export const ShowMoreOsMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    &.gap {
        gap: 14px;
        padding: 0 16px;
    }
`;

export const ShowMoreOsModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-width: 43.75rem;
    min-height: 19rem;
    background-color: #FFFFFF;
    border: 0.0625rem solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.375rem;
    padding: 1.5rem;
    gap: 2.5rem;
`;

export const ShowMoreOsModalTitle = styled.span`
    font-weight: 500;
    font-size: 1.125rem;
    color: #000000;
`;

export const ShowMoreOsModalSelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;

export const ShowMoreOsModalSelectLabel = styled.div`

`;

export const ShowMoreOsModalEndContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;