import styled from 'styled-components'

export const IconDiv = styled.div`
    border-radius: 9999px;
    padding: 8px;
    height: 28px;
    width: 28px;
    position: relative;
    margin: auto 10px;
    transition: background-color .1s;
    cursor: pointer;
    user-select: none;
    &:hover {
        background-color: var(--neutrals-white-shade-100);
    }
    &.Arrow {
        background-color: unset;
    }
    &.Bell {
        width: 34px;
        height: 34px;
    }
    img {
        position: absolute;
        top: 12px;
        right: 7px;
    }
    .Bell {
        position: absolute;
        top: 7px;
        right: 8px;
    }
    
`;