import React from 'react';
import { ColabsCardColabContainer, ColabsCardColabIcon, ColabsCardColabInfoConaiter, ColabsCardColabJob, ColabsCardColabRegistration, ColabsCardColabUsername, ColabsCardHoverDelete, ColabsCardHoverDeletePart, ColabsCardHoverEdit, ColabsCardHoverEditPart, ColabsCardLeftContainer, ColabsCardRightContainer, ColabsCardRightDeleteIcon, ColabsCardRightEditIcon, ColabsCardRightIconContainer, ColabsCardShowMore, Container } from './styles';
import Delete from '../../assets/icons/Colabs/Delete.svg';
import Edit from '../../assets/icons/Colabs/Edit.svg';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { IJobs } from '../../interface/job.interface';

interface IColabsCards {
    id: string;
    username: string;
    job: IJobs[] | string[];
    registration: string;
    buttonPath: string;
    showMoreOnClick?: any;
    updateOnClick?: any;
    deleteOnClick?: any;
}

export const ColabCards = (props: IColabsCards) => {
    const location = useLocation();

    return (
        <Container>
            <ColabsCardLeftContainer>
                <ColabsCardColabContainer>
                    <ColabsCardColabIcon>{props.username?.slice(0, 1).toUpperCase().toString()}</ColabsCardColabIcon>
                    <ColabsCardColabUsername>{props.username}</ColabsCardColabUsername>
                </ColabsCardColabContainer>
                <ColabsCardColabInfoConaiter>
                    <ColabsCardColabJob>{`${props.job?.length > 1? props.job[0] + '...': props.job?.[0]}`}</ColabsCardColabJob>
                    <ColabsCardColabRegistration>{props.registration}</ColabsCardColabRegistration>
                </ColabsCardColabInfoConaiter>
            </ColabsCardLeftContainer>
            <ColabsCardRightContainer>
                <Link onClick={props.showMoreOnClick} className='link' to={`${location.pathname}${props.buttonPath}`}>
                    <ColabsCardShowMore>Ver mais</ColabsCardShowMore>
                </Link>
                <ColabsCardRightIconContainer onClick={props.updateOnClick}>
                    <ColabsCardRightEditIcon src={Edit}/>
                    <ColabsCardHoverEdit className={'edit-hover'}>
                        Editar
                        <ColabsCardHoverEditPart className={'edit-part'}/>
                    </ColabsCardHoverEdit>
                </ColabsCardRightIconContainer>
                <ColabsCardRightIconContainer onClick={props.deleteOnClick}>
                    <ColabsCardRightDeleteIcon src={Delete}/>
                    <ColabsCardHoverDelete className={'delete-hover'}>
                        Excluir
                        <ColabsCardHoverDeletePart className={'delete-part'}/>
                    </ColabsCardHoverDelete>
                </ColabsCardRightIconContainer>        
            </ColabsCardRightContainer>
        </Container>
    );
}