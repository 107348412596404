/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Find } from "../interface/find.interface";
import { IUser } from "../interface/user.interface";
import { notificacaoService } from "../services/notificacao.service";
import { useAuth } from "./AuthContext";

interface INotificacaoContext {
    notificacoes: INotificacaoes;
    getNotificacoes: (find: Find) => void;
    confirma: (notificacoes: string[]) => void;
}

interface INotificacao {
    id: string;
    titulo: string;
    message: string;
    profissional: {
        id: string,
        nome: string,
        data_nascimento: string,
        matricula: string,
        email: string,
        telefone: string,
        cpf: string,
        active: boolean,
        id_login: string
    };
    active: boolean;
    created_at: Date;
}

export interface INotificacaoes {
    rows: INotificacao[];
    total: number;
}

const inicialState = {
    rows: [],
    total: -1,
}

export const NotificacaoContext = React.createContext({} as INotificacaoContext);

const NotificacaoProvider: React.FC = ({ children }) => {
    const [notificacoes, setNotificacoes] = React.useState<INotificacaoes>(inicialState);

    const { setUser, setToken } = useAuth();

    const getNotificacoes = (find: Find) => {
        notificacaoService.find(find)
            .then((res) => {
                setNotificacoes(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }

    const confirma = (notificacoes: string[]) => {
        notificacaoService.confirma(notificacoes)
            .then((res) => {
                getNotificacoes({});
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }

    return (
        <NotificacaoContext.Provider value={{ notificacoes, getNotificacoes, confirma }}>
            {children}
        </NotificacaoContext.Provider>
    );
};

export default NotificacaoProvider;

export const useNotificacao = () => React.useContext(NotificacaoContext);