import { CreateLocalDTO } from "../interface/create-local-dto.interface";
import { ILocal } from "../interface/local.interface";
import { api } from "./api.service";

class LocalService {

    async getLocals() {
        const result = await api().get<ILocal[]>("/locais");
        return result.data;
    };

    async getLocalsOs() {
        return await api().get("/locais");
    };

    async createLocal(local: CreateLocalDTO) {
        const result = await api().post<ILocal>("/locais", local);
        return result.data;
    };

    async updateLocal(id: string, local: CreateLocalDTO) {
        const result = await api().put<ILocal>(`/locais/${id}`, local);
        return result.data;
    };

    async deleteLocal(id: string) {
        await api().delete(`/locais/${id}`);
    }

};

export const localService = new LocalService();