import React from 'react';
import Close from '../../assets/icons/users-icon/Close.svg'
import { Container, Content, DownBar, Icon, IconContainer, RelativeContainer, Title, TopContainer } from './styles';

let INTERVAL = setInterval(() => {},0);

export interface IToast {
  title?: string;
  content?: string;
  state?: number;
  type?: boolean;
  setState?: React.Dispatch<React.SetStateAction<{input: boolean, toast: number, content: string}>>;
}

const Toast: React.FC<IToast> = ({...props}) => {
  const [timer, setTimer] = React.useState<NodeJS.Timeout>(INTERVAL)
  const [state, setState] = React.useState(-1);
  const [count, setCount] = React.useState(1);
  const [close, setClose] = React.useState(false);

  if(props.state === 0 && !close){
    setTimeout(() => {setState(0)},100);
  }
  const fecthTimer = React.useCallback(() => {
    console.log('piahfoad')
    clearInterval(timer);
    clearInterval(INTERVAL);
    setTimeout(() => {props.setState?.(old => old = {...old,toast : 1})})
    setTimeout(() => {props.setState?.(old => old = {...old,toast : -1})}, 300)
    setCount(-1);
    setClose(false)
    setTimeout(() => {setState(-1)},300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  React.useEffect(() => {setCount(1)},[props.state])

  React.useEffect(() => {
    if(count >= 3) {
      clearTimeout(INTERVAL);
      clearTimeout(timer);
      fecthTimer();
      return;
    }
    console.log(count);
    if(props.state === 0 && count === 1) {
      setTimer(setInterval(() => {setCount(old => old + 1)},1500))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.state, count])

  return (
    <Container className={`${props.state === 0? 'show':''}${props.state === 1 && state === 0? ' hide':''
    }`}>
      <RelativeContainer>
        <TopContainer>
          <Title>{props.title}</Title>
          <IconContainer onClick={() => {
          }}>
            <Icon src={Close}/>
          </IconContainer>
        </TopContainer>
        <Content>{props.content}</Content>
        <DownBar
          error={props.type}
          className={`${state === 0? 'show':''}`}
         />
      </RelativeContainer>
    </Container>
  );
}

export default Toast;