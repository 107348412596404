import { ResponsiveContainer } from '../../components/Open-OS-Modal/styles';
import { Container } from './styles';

interface IModalComponent {
    title?: string;
    blurOnClick: () => void;
    blurState?: boolean;
    target?: string;
    children?: React.ReactNode;
}

export const Modal = (props: IModalComponent) => {
    return (
        <Container className={`${!props.blurState? 'hide':''}`} position={'absolute'} blurContainerDirection={'column'}>
            <ResponsiveContainer onClick={props.blurOnClick} className={'height'} blurHeight={'100%'} blurWidth={'100%'}/>
            <Container>
                <ResponsiveContainer onClick={props.blurOnClick} blurBackgroundColor={'rgba(0, 0, 0, 0.03)'} blurWidth={'100%'} blurHeight={'100%'} className={`width left`}/>
                    {props.children}
                <ResponsiveContainer onClick={props.blurOnClick} blurBackgroundColor={'rgba(0, 0, 0, 0.03)'} blurWidth={'100%'} blurHeight={'100%'} className={'width'}/>
            </Container>
            <ResponsiveContainer onClick={props.blurOnClick} blurHeight={'100%'} blurWidth={'100%'} className='height bottom'/>   
        </Container>
    );
}