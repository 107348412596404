import styled from 'styled-components';

export const IconNav = styled.nav`
    min-width: 4.375rem;
    max-width: 4.375rem;
    height: 100vh;
    min-height: 33.4375rem;
    max-height: 100vh;
    background: var(--brand-primary-default);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;
    z-index: 5;
    overflow: hidden;
    transition: ease max-width 0.3s;
    &.Expanded {
        width: 100%;
        max-width: 100%;
        max-width: 18.75rem;
        justify-content: left;
        transition: ease max-width 0.3s;
    }
    `;

export const CenterIconNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    width: 100%;
    justify-content: space-between;
    margin-top: 0.75rem;
`;

export const IconNavContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.Second-Icons-Container {
        justify-content: flex-end;
        height: 100%;
    }
`;

export const IconNavContainerDivisor = styled.div`
    width: 50%;
    height: 0.1875rem;
    background-color: #ffffff;
    opacity: 0.5;
    border-radius: 9999px;
    margin: 0 auto 0 18px;
    transition: ease max-width 0.3s, ease margin 0.3s;
    &.user-option {
        background-color: #E5E5E5;
        height: 1.75rem;
        width: 3px;
        margin: auto 0;
    }
    &.Expanded {
        width: 86%;
        transition: ease max-width 0.3s;
    }
`;