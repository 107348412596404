import React from 'react';
import { DashboardStateCard, DashboardStateCardContainer, DashboardStateCardIcon, DashboardStateCardValue, DashboardStateCardContent } from './styles';

interface IDashboardCards {
    cardclass?: any;
    card?: any;
    icon: string;
    content?: string;
    children: React.ReactNode;
    value?: number;
}

export const DashboardCards = (props:IDashboardCards) => {

  return (
        <DashboardStateCard className={props.cardclass}>
            <DashboardStateCardIcon className={props.card} src={props.icon}/>      
            <DashboardStateCardContainer>
                <DashboardStateCardValue className={`${props.value === -1? 'shimmer':''}`}>
                    {props.children}
                </DashboardStateCardValue>
                <DashboardStateCardContent className={`${'card-content'}`}>{props.content}</DashboardStateCardContent>
            </DashboardStateCardContainer>
        </DashboardStateCard>
    );
}