/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ILocal } from "../interface/local.interface";
import { IUser } from "../interface/user.interface";
import { localService } from "../services/locals.service";
import { osService } from "../services/os.service";
import { useAuth } from "./AuthContext";

interface ILocalContext {
    local: ILocal[];
    jobs: Jobs[];
    types: IType[];
    getLocal: () => void;
    getJobs: () => void;
    getTypes: () => void;
}

interface Jobs {
    id: string;
    grupo: string;
    descricao?: string;
}

interface IType {
    id: string;
    tipo: string;
}

export const LocalContext = React.createContext({} as ILocalContext);

const LocalProvider: React.FC = ({ children }) => {
    const [local, setLocal] = React.useState<ILocal[]>([]);
    const [jobs, setJobs] = React.useState<Jobs[]>([]);
    const [types, seTypes] = React.useState<IType[]>([]);
    
    const { setUser, setToken, token } = useAuth();

    React.useEffect(() => {
        if(window.location.pathname !== '/' && token){
            getLocal();
            getJobs();
            getTypes();}
    }, [])

    const getLocal = React.useCallback(async () => {
        localService.getLocalsOs()
            .then((res) => {
                setLocal(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    const getJobs = React.useCallback(async () => {
        osService.getOsJobs()
            .then((res) => {
                setJobs(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    const getTypes = React.useCallback(async () => {
        osService.getOsTypes()
            .then((res) => {
                seTypes(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    return (
        <LocalContext.Provider value={{ local, jobs, types, getJobs, getLocal, getTypes }}>
            {children}
        </LocalContext.Provider>
    );
};

export default LocalProvider;

export const useLocal = () => React.useContext(LocalContext);