import styled from 'styled-components'

interface IInputSelect {
    direction?: string;
    selected?: boolean;
}
export const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;
    transition: ease margin-bottom 0.3s;
`;

export const SelectContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding-left: 8px;
    max-height: 240px;
    border: 1px solid #E5E5E5;
    transition: border-radius 0.3s 0.1s;
    &.hidden {
        max-height: 50px;
        border-radius: 4px;
    }
    &.selected {
        transition: border-color .3s .2s;
        border: 1px solid #124A6B99;
        border-radius: 4px;
    }
`;

export const SelectOptionContainer = styled.div<IInputSelect>`
    display: flex;
    max-height: ${({direction}) => `${direction === 'down'? '164px':'170px'}`};
    min-height: 100%;
    width: 100%;
    opacity: 1;
    flex-direction: ${({direction}) => `${direction === 'down'? 'column':'column-reverse'}`};
    transition: ease all 0.3s, background-color 0s 0s, border 0s; 
    overflow: auto;
    background-color: #FFFFFF;
    position: absolute;
    top: ${({direction}) => `${direction === 'down'? '44px':'0px'}`};;
    border: 1px solid #E5E5E5;
    margin-top: ${({direction}) => `${direction === 'down'? '0':'-168px'}`};
    border-top: ${({direction}) => `${direction === 'down'? 'none':''}`};;
    border-bottom: ${({direction}) => `${direction === 'down'? '':'none'}`};;
    border-top-left-radius: ${({direction}) => `${direction === 'down'? 'none':'4px'}`};
    border-top-right-radius: ${({direction}) => `${direction === 'down'? 'none':'4px'}`};
    border-bottom-left-radius: ${({direction}) => `${direction === 'down'? '4px':'none'}`};
    border-bottom-right-radius: ${({direction}) => `${direction === 'down'? '4px':'none'}`};
    left: 0;
    z-index: 1;
    &.selected {
        border: none;
    }
    &.hidden {
        transition: ease all 0.3s, background-color 0s 0.08s;
        overflow: hidden;
        top: ${({direction}) => `${direction === 'down'? '0':''}`};;
        z-index: ${({selected}) => `${selected? '0':'-1'}`};
        max-height: 45px;
        background-color: transparent;
        min-height: 45px;
        margin-top: 0;     
    }
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 0 8px 8px 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 0 8px 8px 0;
    }
`;

export const SelectOption = styled.div<IInputSelect>`
    padding: 12px 24px 10px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    gap: 16px;
    &.main {
        justify-content: space-between;
    }
    &.expanded {
        border-bottom: none;
    }
    &.none {
        display: none;
    }
    &.selected {
        position: absolute;
        opacity: 1;
        border: 1px solid #124A6B99;
        z-index: 1;
        top: ${({direction}) => `${direction === 'down'? '':'0'}`};
        border-radius: 4px;
        bottom: ${({direction}) => `${direction === 'down'? '0':''}`};
        width: 100%;
    }
    &.normal {
        display: flex;
    }
`;

export const SelectOptionContent = styled.span`
    font-size: 1.125rem;
    font-weight: 400;
    background: rgba(229, 229, 229, 0.1);
    opacity: 1;
    &.main-content {
        opacity: 0.4;
    }
    &.hidden{
        opacity: 0;
    }
`;

export const SelectOptionArrow = styled.img`
        transition: ease transform 0.3s;
    &.flip {
        transform: rotate(180deg);
    }
`;
