import { ColabCardContentContainer, ColabCardIcon, ColabCardIconsContainer, ColabCardInfoContainer, ColabCardJob, ColabCardLeaderContainer, ColabCardLeaderIcon, ColabCardTrashIcon, ColabCardTrashIconContainer, ColabCardUsername, Container } from './styles';
import Leader from '../../assets/icons/Colabs/Leader.svg'
import Trash from '../../assets/icons/dashboard-menu/NotificationTrash.svg'

interface IShowMoreColabCard {
  username?: string;
  leader?: boolean;
  job?: string;
  onClickTrash?: () => void;
}

export const ShowMoreColabCard = (props: IShowMoreColabCard) => {

  return (
    <Container>
      <ColabCardIcon>{`${props.username?.slice(0, 2).toUpperCase().toString()}`}</ColabCardIcon>
      <ColabCardInfoContainer>
        <ColabCardContentContainer>
          <ColabCardUsername>{props.username}</ColabCardUsername>
          <ColabCardJob>{props.job}</ColabCardJob>
        </ColabCardContentContainer>
        <ColabCardIconsContainer leader={props.leader? true:false}>
          <ColabCardLeaderContainer className={props.leader? 'leader':''}>
            <ColabCardLeaderIcon src={Leader} />
          </ColabCardLeaderContainer>
          { props.onClickTrash &&
            <ColabCardTrashIconContainer>
              <ColabCardTrashIcon onClick={props.onClickTrash} src={Trash}/>
            </ColabCardTrashIconContainer>
          }
        </ColabCardIconsContainer>
      </ColabCardInfoContainer>
    </Container>
  );
}