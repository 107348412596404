import React, {FormEvent, useState} from "react";
import {
    Container,
    Footer,
    FooterContent,
    FooterImg,
    Form,
    Input,
    InputContainer,
    InputLabel,
    LeftContainer,
    Logo,
    MainButton,
    RightContainer,
    RightContentContainer,
    RightFooterNav,
    RightFooterNavContainer,
    RightFooterSelectPoint,
    RightImg,
    RightImgContainer,
    RightMainContainer,
    RightNavIcon,
    RightNavIconContainer,
    RightSubTitle,
    RightTitle,
    ShowPassword,
    ShowPasswordContainer,
    SubTitle,
    Title
} from "./styles";
import LogoIcc from "../../assets/LogoIcc.png";
import ShowPass from "../../assets/ShowPassword.svg";
import ShowPassVariant from '../../assets/ShowPassVariant.svg';
import Okaydev from "../../assets/okaydev.svg";
import Os from "../../assets/OS.png";
import AcompanharFunc from "../../assets/AcompanharFunc.png";
import AcompanharOs from "../../assets/AcompanharOS.png";
import Graphic from "../../assets/Graphic.png";
import Channel from "../../assets/Channel.png";
import WhiteArrow from '../../assets/WhiteArrow.svg';
import { useAuth } from "../../contexts/AuthContext";
import { InputError } from "../../components/InputError";
import { osValidate } from "../../validators/create-os.validator";
import Toast from "../../components/Toast";

export const SignIn = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [slide, setSlide] = useState(0);
    const [animationSide, setAnimationSide] = React.useState(true);
    const [username, setUsername] = React.useState({value: "", state: true});
    const [password, setPassword] = React.useState({value: "", state: true});
    const [showError, setShowError] = React.useState({input: false, toast: -1, content: ''});
    const auth = useAuth();

    const HandleShowPass = () => {
        setShowPassword((old) => !old);
    };

    const handleSlide = (page: number) => setTimeout(() => {
        if(page >= 0 && page < 5){
            setSlide(page);    
        }
    }, 130)

    const handleLogin = React.useCallback(async(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(osValidate({text: username.value}) === undefined  && osValidate({text: password.value}) === undefined){
            try {
                await auth.signIn({username: username.value ,password: password.value});
                setShowError(old => old = {...old, input :false, toast: -1});
            } catch (error) {
                setShowError(old => old = {...old, input :true, toast: 0, content: 'error'});
            }
            return;
        }
        setShowError(old => old = {...old, input: true, toast: 0});
    }, [auth, password.value, username]);

    return (
        <Container>
            <LeftContainer>
                <Logo src={LogoIcc} />
                <Title>Olá, Bem vindo(a)</Title>
                <SubTitle>
                    Digite seu código de colaborador para continuar.
                </SubTitle>
                <Form onSubmit={(event) => {handleLogin(event)}}>
                    <InputContainer className='first'>
                        <InputLabel>Código de Colaborador:</InputLabel>
                        <Input
                            id="signin-username"
                            required={username.state? true:false}
                            onFocus={() => {setUsername(old => old = {...old, state: false})}}
                            onBlur={(e) => {if(e.target.value === ''){setUsername(old => old = {...old, state: true})}}}
                            type="text"
                            value={username.value}
                            onChange={(event) => {
                                setUsername(old => old = {...old, value: event.target.value});
                            }}
                        />
                        <InputError position={'absolute'} top={'94'} minLength={3} maxLength={70} stateAlert={showError.input} value={{any: username.value}}/>
                    </InputContainer>
                    <InputContainer>
                        <InputLabel>Senha:</InputLabel>
                        <Input
                            id="signin-password"
                            title={""}
                            onFocus={() => {setPassword(old => old = {...old, state: false})}}
                            onBlur={(e) => {if(e.target.value === ''){setPassword(old => old = {...old, state: true})}}}
                            required={password.state? true : false}
                            type={`${showPassword ? "text" : "password"}`}
                            value={password.value}
                            onChange={(event) => {
                                setPassword(old => old = {...old, value: event.target.value});
                                ;
                            }}
                        />
                        <InputError position={'absolute'} top={'94'} minLength={3} maxLength={70} stateAlert={showError.input} value={{any: password.value}}/>
                        <ShowPasswordContainer onClick={() => {HandleShowPass();}}>
                            <ShowPassword src={showPassword? ShowPassVariant:ShowPass} />
                        </ShowPasswordContainer>
                    </InputContainer>
                    <MainButton id="signin-button">
                        Login
                    </MainButton>
                </Form>
                <Footer>
                    <FooterContent>
                        powered by:
                        <FooterImg src={Okaydev} />
                    </FooterContent>
                </Footer>
            </LeftContainer>
            <RightContainer>
                <RightContentContainer className={`${slide === 0? 'selected':''}${animationSide? ' left':' right'}`}>
                    <RightTitle>Distribuição de Ordem de Serviço</RightTitle>
                    <RightImgContainer>
                        <RightImg src={Os}/>
                    </RightImgContainer>
                    <RightSubTitle>Com o ICC App você pode distribuir as ordens de  serviço de forma mais organizada e direto para o colborador.</RightSubTitle>    
                </RightContentContainer>
                <RightContentContainer className={`${slide === 1? 'selected':''}${animationSide? ' left':' right'}`}>
                    <RightTitle>Visualização de Atividades</RightTitle>
                    <RightImgContainer>
                        <RightImg src={AcompanharOs}/>
                    </RightImgContainer>
                    <RightSubTitle>Visualização geral e individual de todas as atividades disponíveis, indo das inativas as concluídas.</RightSubTitle>    
                </RightContentContainer>
                <RightContentContainer className={`${slide === 2? 'selected':''}${animationSide? ' left':' right'}`}>
                    <RightTitle>Acompanhamento de Colaboradores</RightTitle>
                    <RightImgContainer>
                        <RightImg src={AcompanharFunc}/>
                    </RightImgContainer>
                    <RightSubTitle>Acompanhamento de cada um de seus colaboradores e suas ações referente as atividades.</RightSubTitle>    
                </RightContentContainer>
                <RightContentContainer className={`${slide === 3? 'selected':''}${animationSide? ' left':' right'}`}>
                    <RightTitle>Canal de Comunicação direta</RightTitle>
                    <RightImgContainer>
                        <RightImg src={Channel}/>
                    </RightImgContainer>
                    <RightSubTitle>Comunicação direta com colaborador responsável pela atividade que se deseja falar sobre.</RightSubTitle>    
                </RightContentContainer>
                <RightContentContainer className={`${slide === 4? 'selected':''}${animationSide? ' left':' right'}`}>
                    <RightTitle>Gráficos de Desempenho</RightTitle>
                    <RightImgContainer>
                        <RightImg src={Graphic}/>
                    </RightImgContainer>
                    <RightSubTitle>Dashboard com todos os dados importantes para o monitoramento de desempenho da equipe ou individual.</RightSubTitle>    
                </RightContentContainer>
                <RightMainContainer>
                    <RightNavIconContainer onClick={() => {handleSlide(slide - 1);setAnimationSide(true);}} className={`left${slide === 0? ' disabled':''}`}>
                        <RightNavIcon src={WhiteArrow}/>
                    </RightNavIconContainer>
                    <RightNavIconContainer className={`${slide === 4? 'disabled':''}`} onClick={() => {handleSlide(slide + 1);setAnimationSide(false);}}>
                        <RightNavIcon src={WhiteArrow}/>
                    </RightNavIconContainer>        
                </RightMainContainer>
                <RightFooterNavContainer>
                    <RightFooterNav onClick={() => {handleSlide(0);if(slide > 0){setAnimationSide(false);} else {setAnimationSide(true);}}}>
                        <RightFooterSelectPoint page={slide}/>
                    </RightFooterNav>
                    <RightFooterNav onClick={() => {handleSlide(1);if(slide > 1){setAnimationSide(false);} else {setAnimationSide(true);}}}/>
                    <RightFooterNav onClick={() => {handleSlide(2);if(slide > 2){setAnimationSide(false);} else {setAnimationSide(true);}}}/>
                    <RightFooterNav onClick={() => {handleSlide(3);if(slide > 3){setAnimationSide(false);} else {setAnimationSide(true);}}}/>
                    <RightFooterNav onClick={() => {handleSlide(4);if(slide > 4){setAnimationSide(false);} else {setAnimationSide(true);}}}/>
                </RightFooterNavContainer>
            </RightContainer>
            <Toast type={showError.content === 'error'? true:false} title={showError.content === 'error'? 'Erro de autenticação':''} content={showError.content === 'error'? 'Usuário ou senha inválidos':''} setState={setShowError} state={showError.toast}/>
        </Container>
    );
};
