import styled from 'styled-components';

export const Header = styled.header`
    display: flex;
    max-width: 100%;
    width: 100%;
    min-width: 550px;
    height: 5.5rem;
    justify-content: space-between;
    padding-right: 22px;
    padding-left: 6.875rem;
    background: #FBFBFB;
`;

export const HeaderUserOption = styled.div`
    display: flex;
    margin: 1.5rem 0;
`;

export const HeaderUserOptionContainer = styled.div`
    display: flex;
    cursor: pointer;
    position: relative;
`;

export const HeaderUserOptionModal = styled.div`
    min-width: 5.875rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 2;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    right: 16px;
    top: 40px;
    background-color: #FFFFFF;
    border-radius: 4px;
    opacity: 0;
    z-index: -1;
    transition: ease opacity 0.3s;
    &.show {
        opacity: 1;
        z-index: 2;
    }
`;

export const HeaderUserOptionModalOptions = styled.div`
    padding: 8px 0 8px 8px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &:last-child {
        margin-bottom: 0;
    }
`;

export const HeaderUserOptionModalOptionsContent = styled.div`
    font-weight: 400;
    font-size: 1rem;
`;

export const HeaderUserOptionContent = styled.div`
    font-weight: 400;
    font-size: 1rem;
`;

export const HeaderUserOptionUsername = styled.h3`
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: -0.25px;
    color: #444053;
    min-width: 100px;
    text-align: center;
`;

export const HeaderUserOptionUserOffice = styled.h4`
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 400;
    letter-spacing: -0.25px;
    color: rgba(0, 0, 0, 0.5);
    text-align: right;
`;

export const HeaderUserOptionUserIcon = styled.div`
    display: flex;
    background-color: #124A6B;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.0625rem;
    color: #FFFFFF;
    width: 2rem;
    height: 2rem;
    align-self: center;
    margin-left: 1rem;
`;

export const HeaderUserOptionOptionIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    align-self: center;
    padding: 12px 9px 12px 9px;
    border-radius: 9999px;
    transition: ease all .1s;
    &:hover {
        padding: 11px 9px 13px 9px;
        background-color: var(--neutrals-white-shade-200);
    }
`;

export const HeaderUserOptionNotificationContainer = styled.div`
    position: relative;
    display: flex;
    margin: 0 12px;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
        background-color: #E5E5E5;
    }
`;

export const HeaderUserOptionNotificationIcon = styled.img`
    width: 1.2rem;
    height: 1.2rem;
    height: unset;
    user-select: none;
`;

export const HeaderUserOptionNotificationStateIcon = styled.div`
    width: 0.4688rem;
    height: 0.4688rem;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 6px;
    &.unread {
        background-color: #124A6B;
    }
`;

export const NotificationModal = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 27.9375rem;
    min-height: 14.9375rem;
    max-height: 450px;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 16px;
    position: absolute;
    z-index: 2;
    right: 32px;
    top: 84px;
    transition: ease opacity 0.3s;
    &.hidden {
        opacity: 0;
        z-index: -1;
    }
`;

export const NotificationModalTopContainer = styled.div`
    display: flex;
    margin-right: 8px;
    justify-content: space-between;
`;

export const NotificationModalTitle = styled.h2`
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: rgba(0, 0, 0, 0.7);
`;

export const NotificationModalMarkAsReadContainer = styled.div`
    display: flex;
    width: 10.8125rem;
    height: 1.875rem;
    border: 0.5px solid #124A6B;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: #124A6B;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.hover {
        border: 0.5px solid #124A6B80;
        color: #124A6B80;
    }
`;

export const NotificationModalItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 190px;
    overflow-y: auto;
    padding: 8px 0;
    opacity: ease all .3s;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 3px;
        background: transparent;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #cecece;
        border-radius: 8px;
    }
`;

export const NotificationModalItems = styled.div`
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border: 0.5px solid #E5E5E5;
    border-radius: 4px;
    padding: 8px 0 0 8px;
    margin-bottom: 8px;
    margin-right: 8px;
    cursor: pointer;
    /* transition: ease opacity .8s, ease margin-right .3s, ease margin-bottom .3s; */
    /* transition: ease all .8s .3s; */
    transition: ease margin-top .3s;
    &:hover {
        background-color: rgba(0, 0, 0, 0.01);
    }
`;

export const NotificationModalItemStartContainer = styled.div`
    display: flex;
`;

export const NotificationModalItemIcon = styled.div`
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    background: #124A6B;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #FFFFFF;
    margin-right: 8px;
    user-select: none;
`;

export const NotificationModalItemContentContainer = styled.div`

`;

export const NotificationModalItemTitle = styled.h3`
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: rgba(0, 0, 0, 0.7);
`;

export const NotificationModalItemContent = styled.span`
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const NotificationModalItemContentId = styled.strong`

`;

export const NotificationModalItemEndContainer = styled.div`
    display: flex;
    align-items: flex-start;
    padding-right: 12px;
`;

export const NotificationModalItemTime = styled.span`
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 12px;
    margin-top: 2px;
`;

export const NotificationModalItemStatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 4px 0;
    justify-content: space-between;
`;

export const NotificationModalItemStatusIcon = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    &.unread {
        background-color: #124A6B;
    }
`;

export const NotificationModalTrashIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-left: 0;
`;