export const replaceNumber = (value: string) => {
  if(String(value) !== 'undefined') {
    let newValue = value.replace(']','');
    return newValue.replace(/[A-Za-zá-źÁ-ŹǸẼỸ ẽỹṽṼẐ±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©ẀÀàḿṕḱǘǵḉÁḾṔḰÚǴḈ&ǹǛ°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g,'');
  }
  return value;
}

export const replaceText = (value: string) => {
  let newValue = value.replace(']','');
  return newValue.replace(/[0-9±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©&°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g,'');
}