import React from 'react';

import { Container, ContainerColabCard, ContainerInput, IconContainer, ISkeleton, NotesContainer } from './styles';

export const Skeleton: React.FC<ISkeleton> = ({height, width, responsive, marginTop}) => {
  return (
    <Container marginTop={marginTop} responsive={responsive} height={height} width={width}>
    </Container>
  );
};

export const InputSkeleton: React.FC<ISkeleton> = ({height, width, responsive, marginTop}) => {
  return (
    <ContainerInput marginTop={marginTop} responsive={responsive} height={height} width={width}>
      <div className='label'></div>
      <div className='input'></div>
    </ContainerInput>
  );
};

export const IconSkeleton: React.FC<ISkeleton> = ({height, width, responsive, marginTop}) => {
  return (
    <IconContainer height={height} responsive={responsive} width={width} marginTop={marginTop}/>
  )
}

export const ColabCardSkeleton: React.FC<ISkeleton> = ({height, width, responsive, marginTop}) => {
  return(
    <ContainerColabCard marginTop={marginTop} responsive={responsive} height={height} width={width}>
      <div className='icon'></div>
      <div className='main-content'>
        <div className='colab'></div>
        <div className='group'></div>
      </div>
    </ContainerColabCard>
  )
}

export const NotesSkeleton: React.FC<ISkeleton> = ({height, width, marginTop, responsive}) => {
  return (
    <NotesContainer height={height} width={width} responsive={responsive} marginTop={marginTop}>
      <div className="icon"></div>
      <div className="main-content">
        <div className="top">
          <div className="title"></div>
          <div className="date"></div>
        </div>
        <div className="content">
          <div className="content-rows"></div>
          <div className="content-rows"></div>
          <div className="content-rows"></div>
          <div className="content-rows"></div>
        </div>
      </div>
    </NotesContainer>
  );
}