import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FBFBFB;
`;

export const Title = styled.h1`
    font-weight: bold;
    font-size: 2.25rem;
    padding-left: 6.875rem;
    line-height: 22px;
    color: #444053;
    padding-top: 1rem;
`;

export const SubTitle = styled.h2`
    font-weight: 400;
    font-size: 1.125rem;
    padding-left: 6.875rem;
    color: rgba(0, 0, 0, 0.5);
    padding-top: 1rem;
`;

export const ColabDetailsContainer = styled.div`
    padding: 42px 142px 42px 142px;
    align-items: center;
    justify-content: center;
`;

export const FormContainer = styled.div`
    display: flex;
    background-color: #FFFFFF;
    width: 100%;
    padding: 32px 64px 16px 64px;
    flex-direction: row;
`;

export const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const User = styled.div`
    background-color: #4285F4;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 36px;
`;

export const Iniciais = styled.span`
    margin-top: 8px;
`;

export const Form = styled.form`
    padding: 8px 0px 0px 48px;
    width: 100%;
`;

export const RowForm = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;

export const InputContainer = styled.div`
    flex-direction: column;
    display: flex;
`;

export const LabelInput = styled.label`
    color: #124A6B;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 12px;
`;

export const Input = styled.input`
    width: 100%;
    height: 52px;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: -0.25px;
    border: none;
    outline: none;
    padding: 15px 24px;
    background: rgba(229, 229, 229, 0.2);
    border-radius: 4px;
    &:disabled {
        cursor: default;
        opacity: 1;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const Button = styled.button`
    border-radius: 6px;
    background-color: #124A6B;
    padding: 13px 16px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    border: 0;
`;