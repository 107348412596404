import React, { createContext, ReactNode, useState } from "react";
import { IUser } from "../interface/user.interface";
import { historicoService } from "../services/historico.service";
import { useAuth } from "./AuthContext";

interface ILog {
    name: string;
    message?: string;
    value?: string;
    created_at?: string;
}

interface ILogContext {
    log: ILog[];
    getLog: (id: string) => void
}

interface IChildren {
    children?: ReactNode;
}

export const LogContext = createContext({} as ILogContext);

export const LogProvider = (props: IChildren) => {
    const [log, setLog] = useState<ILog[]>([]);

    const { setUser, setToken } = useAuth();

    const getLog = (id: string) => {
        historicoService.getHistorico(id)
            .then((res) => {
                setLog(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    };

    return (
        <LogContext.Provider value={{ log, getLog }}>
            {props.children}
        </LogContext.Provider>)
}