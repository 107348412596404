import React from "react";
import { InputError } from "../../components/InputError";
import TrackOsInput from "../../components/TrackOsInput";
import { useAuth } from "../../contexts/AuthContext";
import Layouts from "../../layouts/Main";
import { replaceNumber, replaceText } from "../../utils/replace";
import { osValidate } from "../../validators/create-os.validator";
import {
    ConfigMainChangePasswordContainer,
    ConfigMainChangePasswordInputsContainer,
    ConfigMainChangePasswordTitle,
    ConfigMainTopContainer,
    ConfigMainTopInputsContainer,
    ConfigMainTopTitle,
    ConfigSubTitle,
    ConfigTitle,
    Container,
    ConfigMainContainer,
    ConfigInputsContainer,
} from "./styles";

const initialUserData = {
    cpf: '',
    email: '',
    matricula: '',
    nome: '',
    data_nascimento: '',
    telefone: '',
    password: '',
    new_password: ''
}

export const Profile = () => {
    const { user, _updateProfile } = useAuth();

    const [formData, setFormData] = React.useState(initialUserData);
    const [editable, setEditable] = React.useState(0);
    const [disabled, setDisabled] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [greenBackground, setGreenBackground] = React.useState("");
    const [showError, setShowError] = React.useState(false);

	const setPutForm = React.useCallback((obj: object) => {
		setFormData((old) => { return {...old, ...obj} });
	}, []);
    
    const Inputs = [
        {
            id: 1,
            labelContent: "Nome",
            inputContent: user?.nome,
            inputType: "text",
            value: formData.nome,
            setValue: (e: React.ChangeEvent<HTMLInputElement>) => {setPutForm(({nome: formatInput('Nome' ,e.target.value)}))},
            maxLength: 80,
            minLength: 3,
            error: {text: formData.nome}
        },
        {
            id: 2,
            labelContent: "E-mail",
            inputContent: user?.email,
            inputType: "text",
            value: formData.email,
            setValue: (e: React.ChangeEvent<HTMLInputElement>) => {setPutForm({email: e.target.value})},
            maxLength: 120,
            error: {description: formData.email}
        },
        {
            id: 3,
            labelContent: "Telefone",
            inputContent: user?.telefone,
            inputType: "text",
            value: formData.telefone,
            setValue: (e: React.ChangeEvent<HTMLInputElement>) => {setPutForm({telefone: formatInput('Telefone', e.target.value)})},
            minLength: 11,
            maxLength: 14,
            error: {number: formData.telefone}
        },
        {
            id: 4,
            labelContent: "Função",
            inputContent: 'Administrador',
            inputType: "text",
            value: 'Administrador',
            setValue: (e: React.ChangeEvent<HTMLInputElement>) => {setPutForm({data_nascimento: formatInput('Data de nascimento' ,e.target.value)})},
            maxLength: 9999,
            error: {name: 'Administrador'}
        },
        {
            id: 5,
            labelContent: "CPF",
            inputContent: user?.cpf,
            inputType: "text",
            value: formData.cpf,
            setValue: (e: React.ChangeEvent<HTMLInputElement>) => {setPutForm({cpf: formatInput('CPF', e.target.value)})},
            minLength: 11,
            maxLength: 14,
            error: {number: formData.cpf}
        },
        {
            id: 6,
            labelContent: "Matrícula",
            inputContent: user?.matricula,
            inputType: "text",
            value: formData.matricula,
            setValue: (e: React.ChangeEvent<HTMLInputElement>) => {setPutForm({matricula: formatInput('Matrícula' ,e.target.value)})},
            maxLength: 10,
            error: {number: formData.matricula}
        },
        {
            id: 7,
            labelContent: "Senha atual",
            inputContent: "651456418",
            inputType: "password",
            value: formData.password,
            setValue: (e: React.ChangeEvent<HTMLInputElement>) => {setPutForm({password: e.target.value})},
            defaultValue: {password: ''},
            maxLength: 11,
            error: {}
        },
        {
            id: 8,
            labelContent: "Nova senha",
            inputContent: "651456418",
            inputType: "password",
            value: formData.new_password,
            setValue: (e: React.ChangeEvent<HTMLInputElement>) => {setPutForm({new_password: e.target.value})},
            defaultValue: {new_password: ''},
            maxLength: 11,
            error: {text: formData.new_password}
        },
    ];

    const formatInput = (type: string, value: string) => {
        if(type === 'CPF'){
            let newValue = replaceNumber(value)
            if(newValue.length === 11) {
                return newValue.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/g, "$1.$2.$3-$4")
            } 
            if(newValue !== value) {
                return newValue;
            }
            if(value.length < 11) {
                return newValue;
			}
        }
        if(type === 'Telefone') {
            let newValue = replaceNumber(value)
            if(newValue.length === 11) {
                return newValue.replace(/(\d{2})(\d{5})(\d{4})/g,'($1)$2-$3');
            }
            if(newValue !== value) {
                return newValue;
            }
            if(value.length < 11) {
                return newValue;
			}
        }
        if(type === 'Nome') {
            return replaceText(value)
        }
        if(type === 'Matrícula') {
            return replaceNumber(value);
        }
    }

    const ActiveChanges = (key: number) => {
        if (greenBackground !== "") {
            return;
        }
        setEditable(key);
        setDisabled((old) => !old);
        setEdit((old) => !old);
    };

    const Animation = () => {
        if (greenBackground !== "") {
            return;
        }
        if (!greenBackground) {
            setGreenBackground((old) => (old = "green"));
            setTimeout(() => {
                setGreenBackground((old) => (old = ""));
            }, 1000);
            return;
        }
        setGreenBackground((old) => (old = ""));
    };

    const DelayChanges = (key: number) => {
        if (greenBackground !== "") {
            return;
        }
        setEditable((old) => (old = key));
        setDisabled((old) => !old);
        setTimeout(() => {
            setEdit((old) => !old);
        }, 1000);
    };
    
    React.useEffect(() => {
        setPutForm({
            nome: user?.nome,
            email: user?.email,
            telefone: user?.telefone,
            data_nascimento: user?.data_nascimento,
            cpf: user?.cpf,
            matricula: user?.matricula
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layouts>
            <Container>
                <ConfigTitle>Perfil</ConfigTitle>
                <ConfigSubTitle>Perfil e configurações de conta</ConfigSubTitle>
                <ConfigMainContainer className={"main"}>
                    <ConfigMainTopContainer>
                        <ConfigMainTopTitle>Dados Gerais</ConfigMainTopTitle>
                        <ConfigMainTopInputsContainer>
                            {Inputs.slice(0, 6).map((item, key) => {
                                return (
                                    <ConfigInputsContainer>
                                        <TrackOsInput
                                            hideIcon={item.labelContent === 'Função'? ' hidden': " config"}
                                            key={key}
                                            iconClass={
                                                editable === item.id - 1 && edit
                                                    ? " clean"
                                                    : ""
                                            }
                                            maxLength={item.maxLength}
                                            value={item.value?.length === 11? formatInput(item.labelContent, item.value):item.value}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>{item.setValue(e)}}
                                            onClick={() => {
                                                ActiveChanges(key);
                                            }}
                                            onClickConfirm={() => {
                                                if(osValidate(item.error, item.maxLength, item.minLength) === undefined){
                                                    Animation();
                                                    DelayChanges(key);
                                                    setShowError(false);
                                                    _updateProfile(formData);
                                                    return;
                                                }
                                                setShowError(true);
                                            }}
                                            animation={
                                                greenBackground !== ""
                                                    ? greenBackground
                                                    : ""
                                            }
                                            state={editable}
                                            editable={
                                                editable === item.id - 1 && disabled
                                                    ? true
                                                    : false
                                            }
                                            inputClass={'profile'}
                                            inputContent={item.inputContent}
                                            inputType={item.inputType}
                                            labelContent={item.labelContent}
                                        />
                                        <InputError maxLength={item.maxLength} value={item.error} stateAlert={showError} minLength={item.minLength}/>
                                    </ConfigInputsContainer>
                                );
                            })}
                        </ConfigMainTopInputsContainer>
                    </ConfigMainTopContainer>
                    <ConfigMainChangePasswordContainer>
                        <ConfigMainChangePasswordTitle>
                            Mudança de senha
                        </ConfigMainChangePasswordTitle>
                        <ConfigMainChangePasswordInputsContainer>
                            {Inputs.slice(6, 8).map((item, key) => {
                                return (
                                    <TrackOsInput
                                        hideIcon={" config"}
                                        key={key}
                                        iconClass={
                                            editable === item.id - 1 && edit
                                                ? " clean"
                                                : ""
                                        }
                                        value={item.value}
                                        onChange={item.setValue}
                                        animation={
                                            greenBackground !== ""
                                                ? greenBackground
                                                : ""
                                        }
                                        onClick={() => {
                                            ActiveChanges(key + 6);
                                        }}
                                        onClickConfirm={() => {
                                            if(osValidate(item.error, item.maxLength, item.minLength) === undefined && item.labelContent === 'Senha atual'){   
                                                DelayChanges(key + 6);
                                                Animation();
                                                setShowError(false);
                                                return;
                                            }
                                            if(osValidate(item.error, item.maxLength, item.minLength) === undefined && item.labelContent === 'Nova senha'){   
                                                DelayChanges(key + 6);
                                                Animation();
                                                setShowError(false);
                                                _updateProfile(formData);
                                                return;
                                            }
                                            setShowError(true);
                                        }}
                                        state={editable}
                                        editable={
                                            editable === item.id - 1 && disabled
                                                ? true
                                                : false
                                        }
                                        inputContent={
                                            item.inputType
                                        }
                                        inputClass={'password'}
                                        inputType={item.inputType}
                                        labelContent={item.labelContent}
                                    />
                                );
                            })}
                        </ConfigMainChangePasswordInputsContainer>
                    </ConfigMainChangePasswordContainer>
                </ConfigMainContainer>
            </Container>
        </Layouts>
    );
};
