import './Multi-Select-In-Charge.css'

const SelectStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        outline: 'none',
    })
    
}

export default SelectStyles;