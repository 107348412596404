import React, { ForwardRefRenderFunction } from "react";
import {
    Container,
    TrackOsInputUnset,
    TrackOsInputLabel,
    ShowMoreOsIconContainer,
    ShowMoreOsEditIcon,
    ShowMoreOsHoverEdit,
    ShowMoreOsHoverEditPart,
} from "./styles";
import Edit from "../../assets/icons/Colabs/Edit.svg";
import Clean from "../../assets/icons/Track-Service-Order/Clean.svg";
import CleanWhite from "../../assets/icons/Track-Service-Order/CleanWhite.svg";

interface ITrackOsInput {
    labelContent?: string;
    inputContent?: string;
    editable?: boolean;
    inputType?: string;
    onClick?: any;
    onClickConfirm?: any;
    state?: number;
    iconClass?: string;
    hideIcon?: string;
    animation?: string;
    placeHolder?: string;
    inputClass?: string;
    onChange?: any;
    onFocus?: any;
    onBlur?: any;
    onMouseEnter?: any;
    onMouseLeave?: any;
    defaultValue?: string | null;
    value?: string;
    maxLength?: number;
}

const TrackOsInput: ForwardRefRenderFunction<HTMLInputElement, ITrackOsInput> =
    (props, ref) => {

        let defaultValue = props.inputContent;
        if(props.labelContent === 'Data de abertura'){
            const date = new Date(String(defaultValue));
            defaultValue = `${date.getDay().toString().length < 2? '0' + date.getDay():date.getDay()}/${date.getMonth().toString().length < 2? '0' + date.getMonth():date.getMonth()}/${date.getFullYear()}`
        }
        return (
            <Container
                onSubmit={(e) => {e.preventDefault();props.onClickConfirm()}}
                tabIndex={1}
                onMouseLeave={props.onMouseLeave}
                onMouseEnter={props.onMouseEnter}
                className={`${props.inputClass? props.inputClass:''}`}
            >
                <TrackOsInputLabel
                    className={`${props.inputClass? props.inputClass:''}`}
                    htmlFor="TrackInput"
                >
                    {props.labelContent}
                </TrackOsInputLabel>
                <TrackOsInputUnset
                    value={props.value}
                    ref={ref}
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                    className={`${props.inputClass? props.inputClass:''}`}
                    placeholder={props.placeHolder}
                    disabled={!props.editable}
                    id="TrackInput"
                    maxLength={Number(props.maxLength)}
                    onChange={props.onChange}
                    defaultValue={String(props.defaultValue)}
                    type={`${props.inputType ? props.inputType : "text"}`}
                />
                <ShowMoreOsIconContainer
                    type={'button'}
                    className={`edit${props.iconClass}${
                        props.hideIcon ? props.hideIcon : ""
                    }`}
                    onClick={props.onClick}
                >
                    <ShowMoreOsEditIcon src={Edit} />
                    <ShowMoreOsHoverEdit className={"edit-hover"}>
                        Editar
                        <ShowMoreOsHoverEditPart className={"edit-part"} />
                    </ShowMoreOsHoverEdit>
                </ShowMoreOsIconContainer>
                <ShowMoreOsIconContainer
                    className={`clean${props.iconClass ? "" : " active"} ${
                        props.animation? props.animation:''
                    }${props.hideIcon ? props.hideIcon : ""}`}
                    onClick={props.onClickConfirm}
                >
                    <ShowMoreOsEditIcon
                        className={`${props.animation? props.animation:''}`}
                        src={Clean}
                    />
                    <ShowMoreOsEditIcon
                        className={`${props.animation? props.animation:''} ${props.iconClass? props.iconClass:''}`}
                        src={CleanWhite}
                    />
                    <ShowMoreOsHoverEdit
                        className={`edit-hover${
                            props.animation !== "" ? " disable" : ""
                        }`}
                    >
                        Confirmar
                        <ShowMoreOsHoverEditPart
                            className={`edit-part${
                                props.animation !== "" ? " disable" : ""
                            }`}
                        />
                    </ShowMoreOsHoverEdit>
                </ShowMoreOsIconContainer>
            </Container>
        );
    };

const TrackOsInputComponent = React.forwardRef(TrackOsInput);

export default TrackOsInputComponent;
