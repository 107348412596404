import styled from 'styled-components';

interface IChatCard {
    sended: boolean;
}

export const Container = styled.div<IChatCard>`
    display: flex;
    justify-content: ${({sended}) => `${sended? 'right':''}`};
    min-height: 96px;
    margin-top: 1.5rem;
    width: 100%;
`;

export const ChatUserIcon = styled.div`
    display: flex;
    background: #124A6B;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.0625rem;
    color: #FFFFFF;
    width: 2rem;
    height: 2rem;
    margin-left: -5px;
`;

export const ChatContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: 1rem;
`;

export const ChatUsername = styled.span`
    font-weight: 500;
    color: #444053;
`;

export const ChatContentCard = styled.div`
    display: flex;
    align-items: center;
    min-height: 40px;
    min-width: 213px;
    background: rgba(18, 74, 107, 0.02);
    border-radius: 4px;
    padding: 8px 16px;
`;

export const ChatContent = styled.span`
    font-weight: 400;
    color: #444053;
`;

export const ChatTimerMessage = styled.div`
    display: flex;
    justify-content: right;
    font-size: 14px;
    color: rgba(68, 64, 83, 0.5);
`;