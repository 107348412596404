import styled from 'styled-components';

interface ToastProps {
  state?: boolean;
  error?: boolean;
}

export const Container = styled.div<ToastProps>`
  position: absolute;
  width: 21.875rem;
  height: 6.25rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 0.0625rem rgba(0, 0, 0, 0.4), 0px 0.75rem 0.75rem -0.375rem rgba(0, 0, 0, 0.16);
  border-radius: 0.25rem;
  display: flex;
  right: 1rem;
  top: 1rem;
  z-index: -1;
  &.hide {
    z-index: unset;
    animation: ToastOut 300ms ease backwards;
    display: flex;
  }
  &.show {
    z-index: unset;
    display: flex;
    animation: ToastIn 300ms ease backwards;
  }
  @keyframes ToastIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes ToastOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(100% + 32px));
    }
}
`;

export const RelativeContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 0.5rem;
  padding: 1.125rem;
  height: 100%;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: #444053;
`;


export const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease background-color .15s;
  &:hover {
    background-color: #E5E5E560;
  }
`;

export const Icon = styled.img`

`;

export const Content = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #7B7B7B;
`;

export const DownBar = styled.div<ToastProps>`
  width: 0;
  height: 6px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: ${({error}) => `${error === true? 'red':'rgba(71, 230, 177, 1)'}`};
  transition: linear width 0s;
  &.show {
    transition: linear width 3s;
    width: 100%;
  }
  
`;