import styled from 'styled-components';

interface IPriority {
    right?: string;
    top?: string;
    left?: string;
    bottom?: string;
    iconColor?: string;
    moveColor?: string;
}

export const Container = styled.div<IPriority>`
    position: absolute;
    right: ${({right}) => `${right}`};
    top: ${({top}) => `${top}`};
    left: ${({left}) => `${left}`};
    bottom: ${({bottom}) => `${bottom}`};
    user-select: none;
    border: none;
    z-index: 1;
    background-color: #FFFFFF;
`;

export const PriorityContainer = styled.div`
    display: flex;
    min-width: 13.625rem;
    max-width: 13.625rem;
    flex-direction: column;
    border-radius: 10px;
    max-height: 2.5rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    transition: ease all 0.3s, border-radius 0.3s 0.1s;
    border-radius: 9999px;
    &.active {
        transition: ease all 0.3s 0s, border-radius .0s;
        max-height: 15rem;
        border-radius: 10px;
    }

`;

export const PriorityOptionContainer = styled.div`
    color: rgba(68, 64, 83, 0.7);
    max-height: 0;
    width: 100%;
    opacity: 1;
    transition: all 0.3s; 
    overflow: hidden;
    background-color: #FFFFFF;
    &.active {
        max-height: 15rem;
        opacity: 1;
        overflow-y: none;
    }
`;

export const PriorityPlaceHolder = styled.span`
    min-width: 158px;
    font-weight: 400;
    font-size: 1rem;
    &.main {
        padding-left: 30px;
        display: none;
    }
`;

export const PriorityOption = styled.div`
    padding: 12px 24px 12px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    gap: 16px;
    border-radius: 9999px;
    &.selected {
        padding: 12px 24px 12px 12px;
        gap: 0;
        border-radius: 8px;
        color: rgba(68, 64, 83, 0.7);
        cursor: pointer;
        min-height: 2.75rem;
        z-index: 2;
    }
`;

export const PriorityArrow = styled.img`
    width: 0.75rem;
    height: 0.5rem;
    user-select: none;
    position: absolute;
    right: 19px;
    transition: ease all 0.3s;
    &.flip {
        transition: ease all 0.3s;
        transform: rotate(180deg) ;
        top: 18px;
    }
`;

export const PriorityIcon = styled.div<IPriority>`
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 9999px;
    opacity: 0;
    transition: ease all 0.3s;
    &.blue {
        background: ${({iconColor}) => `${iconColor? iconColor:'#4285F4'}`};
        opacity: 1;
        transition: ease all 0.3s;
        margin: 6px 0;
    }
    &.orange {
        background: ${({iconColor}) => `${iconColor? iconColor:'#F9971E'}`};
        opacity: 1;
        transition: ease all 0.3s;
        margin: 8px 0 6px 0;
    }
    &.red {
        background: ${({iconColor}) => `${iconColor? iconColor:'#FF0000'}`};
        opacity: 1;
        transition: ease all 0.3s;
        position: absolute;
        top: 62px;
    }
    &.red.hidden {
        opacity: 0;
    }
    &.red-redmain.hidden {
        top: 16px;
        left: 16px;
        opacity: 1;
        background: ${({iconColor}) => `${iconColor? iconColor:'#FF0000'}`};
    }
    &.red-redmain {
        top: 16px;
        left: 16px;
        opacity: 1;
        background: ${({iconColor}) => `${iconColor? '#4285F4':'#FF0000'}`};
    }
    &.red-bluemain.hidden {
        top: 16px;
        left: 16px;       
        opacity: 1;
        background: ${({iconColor}) => `${iconColor? '#28A138':'#4285F4'}`};

    }
    &.red-bluemain {
        top: 16px;
        left: 16px;        
        background: ${({iconColor}) => `${iconColor? '#28A138':'#4285F4'}`};

    }
    &.red-orangemain.hidden {
        top: 16px;
        opacity: 1;
        left: 16px;
        background: ${({iconColor, moveColor}) => `${iconColor? moveColor:'#F9971E'}`};
    }
    &.red-orangemain {
        top: 16px;
        opacity: 1;
        left: 16px;
        background: ${({iconColor, moveColor}) => `${iconColor? moveColor:'#F9971E'}`};
    }
    &.red-redmain.reset {
        opacity: 0;
    }
`;

export const PriorityLabel = styled.label<IPriority>`
    cursor: pointer;
    transition: ease all 0.3s;    
    z-index: 0;
    line-height: 1.375rem;
    &.red {
        padding-left: 26px;
    }
    &.red.start {
        position: absolute;
    }
    &.orange.start {
        position: absolute;
    }
    &.blue.start {          
        position: absolute;
    }
    &.red.normal {
        padding-left: 26px;
    }
    &.normal {
        padding-left: 0px;
    }
    &.redmain {
        background-color: ${({iconColor}) => `${iconColor? iconColor:'red'}`};
    }
    &.Selected {
        position: absolute;
        &.red {
            margin-top: -70px;
        }
        &.blue {
            left: 42px;
            margin-top: -282px;
        }
        &.orange {
            left: 42px;
            margin-top: -188px;

        }
    }
`;