import React from 'react';
import Layouts from '../../layouts/Main';
import Select from 'react-select';
import SelectStylesInCharge from '../../styles/Multi-Select-Colab';
import { useHistory } from "react-router-dom";

import { ColabDetailsContainer, Container, Form, FormContainer, Iniciais, LabelInput, RowForm, SubTitle, Title, User, UserContainer, Input, InputContainer, ButtonContainer, Button } from './styles';
import { useColabs } from '../../contexts/Users';
import { useLocal } from '../../contexts/LocalContext';

const ColabDetails: React.FC = () => {
	const { colabActive, updateColab, updateColabActive } = useColabs();
	const { jobs } = useLocal();
	const history = useHistory();

	const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		updateColab(
			{
				...colabActive,
				grupos: colabActive.grupos.map((grupo) => { return { grupo: grupo.id } })
			},
			() => { history.push('/colabs') }
		)
	};

	return (
		<Layouts>
			<Container>
				<Title>Colaborador</Title>
				<SubTitle>Detalhes do perfil do colaborador</SubTitle>
				<ColabDetailsContainer>
					<FormContainer>
						<UserContainer>
							<User>
								{
									colabActive.nome
										.slice(0, 1)
										.toUpperCase()
										.toString()
								}
							</User>
							<Iniciais>Iniciais</Iniciais>
						</UserContainer>
						<Form>
							{
								colabActive.update ?
									(
										<>
											<RowForm>
												<InputContainer style={{ width: '69%' }}>
													<LabelInput>Nome completo</LabelInput>
													<Input value={colabActive.nome} onChange={(e) => updateColabActive({ nome: e.currentTarget.value })} />
												</InputContainer>
												<InputContainer style={{ width: '29%' }}>
													<LabelInput>Data de nascimento</LabelInput>
													<Input type="date" value={colabActive.data_nascimento} onChange={(e) => updateColabActive({ data_nascimento: e.currentTarget.value })} />
												</InputContainer>
											</RowForm>
											<RowForm>
												<InputContainer style={{ width: '32%' }}>
													<LabelInput>CPF</LabelInput>
													<Input value={colabActive.cpf} onChange={(e) => updateColabActive({ cpf: e.currentTarget.value })} />
												</InputContainer>
												<InputContainer style={{ width: '32%' }}>
													<LabelInput>Telefone</LabelInput>
													<Input value={colabActive.telefone} onChange={(e) => updateColabActive({ telefone: e.currentTarget.value })} />
												</InputContainer>
												<InputContainer style={{ width: '32%' }}>
													<LabelInput>E-mail</LabelInput>
													<Input value={colabActive.email} onChange={(e) => updateColabActive({ email: e.currentTarget.value })} />
												</InputContainer>
											</RowForm>
											<RowForm>
												<InputContainer style={{ width: '29%' }}>
													<LabelInput>Matricula</LabelInput>
													<Input value={colabActive.matricula} onChange={(e) => updateColabActive({ matricula: e.currentTarget.value })} />
												</InputContainer>
												<InputContainer style={{ width: '69%' }}>
													<LabelInput>Grupo de Trabalho</LabelInput>
													<Select isDisabled={!colabActive.update} onChange={(e) => updateColabActive({ grupos: e.map((grupo) => { return { id: grupo.value, grupo: grupo.label } }) })} defaultValue={colabActive.grupos.map((grupo) => { return { label: grupo.grupo, value: grupo.id } })} styles={SelectStylesInCharge} placeholder={'Selecionar'} closeMenuOnSelect={true} isMulti options={jobs.map((job) => { return { label: job.grupo, value: job.id } })} />
												</InputContainer>
											</RowForm>
											<ButtonContainer>
												<Button onClick={handleSubmit}>Atualizar</Button>
											</ButtonContainer>
										</>
									) : 
									(
										<>
											<RowForm>
												<InputContainer style={{ width: '69%' }}>
													<LabelInput>Nome completo</LabelInput>
													<Input disabled value={colabActive.nome} />
												</InputContainer>
												<InputContainer style={{ width: '29%' }}>
													<LabelInput>Data de nascimento</LabelInput>
													<Input type="date" disabled value={colabActive.data_nascimento} />
												</InputContainer>
											</RowForm>
											<RowForm>
												<InputContainer style={{ width: '32%' }}>
													<LabelInput>CPF</LabelInput>
													<Input disabled value={colabActive.cpf} />
												</InputContainer>
												<InputContainer style={{ width: '32%' }}>
													<LabelInput>Telefone</LabelInput>
													<Input disabled value={colabActive.telefone} />
												</InputContainer>
												<InputContainer style={{ width: '32%' }}>
													<LabelInput>E-mail</LabelInput>
													<Input disabled value={colabActive.email} />
												</InputContainer>
											</RowForm>
											<RowForm>
												<InputContainer style={{ width: '29%' }}>
													<LabelInput>Matricula</LabelInput>
													<Input disabled value={colabActive.matricula} />
												</InputContainer>
												<InputContainer style={{ width: '69%' }}>
													<LabelInput>Grupo de Trabalho</LabelInput>
													<Select isDisabled={!colabActive.update} defaultValue={colabActive.grupos.map((grupo) => { return { label: grupo.grupo, value: grupo.id } })} styles={SelectStylesInCharge} placeholder={'Selecionar'} closeMenuOnSelect={true} isMulti options={jobs.map((job) => { return { label: job.grupo, value: job.id } })} />
												</InputContainer>
											</RowForm>
										</>
									)
							}
						</Form>
					</FormContainer>
				</ColabDetailsContainer>
			</Container>
		</Layouts>
	);
}

export default ColabDetails;