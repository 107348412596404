import React from "react";
import {ResponsiveContainer} from "../Open-OS-Modal/styles";
import {OpenOS} from "../OpenOS";
import Close from "../../assets/icons/users-icon/Close.svg";

import {
    ColabsDeleteModal,
    ColabsDeleteModalCenter,
    ColabsDeleteModalCenterContent,
    ColabsDeleteModalCenterTitle,
    ColabsDeleteModalContainer,
    ColabsDeleteModalContentStrong,
    ColabsDeleteModalFooterButtonsContainer,
    ColabsDeleteModalTitleStrong,
    ColabsDeleteModalTopIcon,
    ColabsDeleteModalTopIconContainer
} from "./styles";

interface ISuccessModal {
    frameContent: string;
    blurOnClick?: any;
    blurState?: boolean;
    targetTitle?: string;
    target?: string;
    deleteAction?: any;
}

export const DeleteModal = (props: ISuccessModal) => {

    return (
        <ColabsDeleteModalContainer
            className={`${!props.blurState ? "hide" : ""}`}
            blurContainerDirection={"column"}
            position={"absolute"}
        >
            <ResponsiveContainer
                onClick={props.blurOnClick}
                className={"height"}
                blurHeight={"100%"}
                blurWidth={"100%"}
            />
            <ColabsDeleteModalContainer>
                <ResponsiveContainer
                    onClick={props.blurOnClick}
                    blurBackgroundColor={"rgba(0, 0, 0, 0.03)"}
                    blurWidth={"100%"}
                    blurHeight={"100%"}
                    className={`width left`}
                />
                <ColabsDeleteModal>
                    <ColabsDeleteModalTopIconContainer
                        onClick={props.blurOnClick}
                    >
                        <ColabsDeleteModalTopIcon src={Close} />
                    </ColabsDeleteModalTopIconContainer>
                    <ColabsDeleteModalCenter>
                        <ColabsDeleteModalCenterTitle>
                            <ColabsDeleteModalTitleStrong>
                                Atenção
                            </ColabsDeleteModalTitleStrong>{" "}
                            deseja realmente remover este {props.targetTitle}?
                        </ColabsDeleteModalCenterTitle>
                        <ColabsDeleteModalCenterContent>
                            Ao confirmar está ação você estará excluindo o
                            {' ' + props.targetTitle}{" "}
                            <ColabsDeleteModalContentStrong>
                                {props.target}
                            </ColabsDeleteModalContentStrong>{" "}
                            do seu quadro de {props.frameContent}
                        </ColabsDeleteModalCenterContent>
                    </ColabsDeleteModalCenter>
                    <ColabsDeleteModalFooterButtonsContainer>
                        <OpenOS
                            clickFunct={() => {props.blurOnClick()}}
                            buttonWidth={"133px"}
                            ableAnimation={false}
                            activeBgColor={"rgba(0, 0, 0, 0.1)"}
                            hoverBgColor={"rgba(0, 0, 0, 0.05)"}
                            Color={"#124A6B;"}
                            backgroundColor={"transparent"}
                            content={"Cancelar"}
                        />
                        <OpenOS
                            clickFunct={() => {props.deleteAction();props.blurOnClick()}}
                            buttonWidth={"133px"}
                            ableAnimation={false}
                            activeBgColor={"rgba(18, 74, 107, 0.85)"}
                            hoverBgColor={"rgba(18, 74, 107, 0.9)"}
                            Color={"#FFFFFF;"}
                            backgroundColor={"#124A6B"}
                            content={"Concluir"}
                        />
                    </ColabsDeleteModalFooterButtonsContainer>
                </ColabsDeleteModal>
                <ResponsiveContainer
                    onClick={props.blurOnClick}
                    blurBackgroundColor={"rgba(0, 0, 0, 0.03)"}
                    blurWidth={"100%"}
                    blurHeight={"100%"}
                    className={"width"}
                />
            </ColabsDeleteModalContainer>
            <ResponsiveContainer
                onClick={props.blurOnClick}
                blurHeight={"100%"}
                blurWidth={"100%"}
                className="height bottom"
            />
        </ColabsDeleteModalContainer>
    );
};
