import React, { ReactNode } from 'react';
import { AsideMenu } from '../../components/AsideMenu';
import { HeaderDashboard } from '../../components/HeaderDashboard';
import Toast, { IToast } from '../../components/Toast';
import { ComponentContainer, Container } from './styles';

interface ILayouts extends IToast {
    children: ReactNode;
    overflowY?: boolean;
}

const Layouts: React.FC<ILayouts> = ({...props}) => {
    
    return (
        <Container>
            <Container>
                <Toast title={props.title} content={props.content} state={props.state}/>
                <AsideMenu/>
                <HeaderDashboard Height={'2.5rem'} activeBgColor={'var(--brand-primary-shade-100)'} hoverBgColor={'var(--brand-primary-shade-200)'} Color={'white'} backgroundColor={'var(--brand-primary-default)'} content={'+ Abrir OS'}/>          
                <ComponentContainer>
                    {props.children}
                </ComponentContainer>
            </Container>
        </Container>
    );
}

export default Layouts;