import './Multi-Select-Job.css'

const SelectStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        outline: 'none',
        color: 'inherit'
    }),
    multiValue: (provided: any, state: any) => ({
        ...provided,
        outline: 'none'
    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        outline: 'none'
    })
}

export default SelectStyles;