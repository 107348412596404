import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ColabCards } from "../../components/ColabCards";
import { SearchInput } from "../../components/SearchInput";
import { ServiceOrderNav } from "../../components/ServiceOrderNav";
import { DeleteModal } from "../../components/DeleteModal";
import Layout from "../../layouts/Main";
import {
    ColabsMain,
    ColabsMainCenter,
    ColabsMainTop,
    ColabsSubTitle,
    ColabsTitle,
    ColabsTopLeft,
    ColabsTopLeftButton,
    Container,
} from "./styles";
import { colabsService } from "../../services/colabs.service";
import { useColabs } from "../../contexts/Users";
import { Find } from "../../interface/find.interface";
import { Skeleton } from "../../components/Skeleton";

const ColabsShimmer = [
    {},
    {},
    {},
    {},
    {}
]

export const Colabs = () => {

    const { setColabActive, colabs, getColabs } = useColabs();

    const [blurModal, setBlurModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [pageValue, setPageValue] = useState(0);
    const history = useHistory();

    const ColOptions = [
        {
            content: "Colaborador",
        },
        {
            content: "Grupo de trabalho",
        },
        {
            content: "Matrícula",
        },
    ];

    const [filter, setFilter] = React.useState<Find>({
        page: 0,
        search: "",
        grupos: [],
        status: "",
        prioridade: "",
    });

    let newFind = {
        page: pageValue,
        search: searchValue,
        grupos: [],
        status: "",
        prioridade: "",
    };

    React.useEffect(() => {
        getColabs(newFind);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const handleBlurModal = () => {
        setBlurModal((old) => !old);
    };

    const handleDeleteColab = useCallback(async () => {
        try {
            await colabsService.deleteColab(deleteId);
            setFilter(newFind)
            setDeleteId('')
        } catch (error) {
            alert(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteId]);

    return (
        <Layout>
            <Container>
                <ColabsTitle>Colaboradores</ColabsTitle>
                <ColabsSubTitle>
                    Detalhes e listagem dos colaboradores
                </ColabsSubTitle>
                <ColabsMain>
                    <ColabsMainTop>
                        <ColabsTopLeft>
                            <Link
                                className={"link"}
                                to={history.location.pathname + "/create"}
                            >
                                <ColabsTopLeftButton
                                    className={"create-button"}
                                >
                                    + Adicionar novo colaborador
                                </ColabsTopLeftButton>
                            </Link>
                        </ColabsTopLeft>
                        <SearchInput
                            required
                            placeholder={
                                "Digite o nome ou matrícula do colaborador"
                            }
                            value={searchValue}
                            onChange={(event) => {
                                setSearchValue(event.target.value);
                                setFilter(newFind);
                            }}
                        />
                    </ColabsMainTop>
                    <ServiceOrderNav
                        setState={setFilter}
                        state={newFind}
                        page={pageValue}
                        setPage={setPageValue}
                        total={colabs.total}
                        type={"colabs"}
                        values={ColOptions}
                    />
                    <ColabsMainCenter  className={`${colabs.total === -1? 'shimmer':''}`}>
                        {colabs.total !== -1?
                            colabs.rows.map((item, key) => {
                                return (
                                    <ColabCards
                                        deleteOnClick={() => {
                                            handleBlurModal();
                                            setDeleteId(item.id);
                                        }}
                                        key={key}
                                        id={item.id}
                                        buttonPath={"/show-more-colabs"}
                                        username={item.nome}
                                        job={item.grupos?.map(
                                            (grupo) => grupo.grupo
                                        )}
                                        registration={item.matricula}
                                        updateOnClick={() => {
                                            setColabActive({
                                                ...item,
                                                update: true,
                                            });
                                            history.push(
                                                `/colabs/show-more-colabs`
                                            );
                                        }}
                                        showMoreOnClick={() => {
                                            setColabActive({
                                                ...item,
                                                update: false,
                                            });
                                        }}
                                    />
                                );
                            }):ColabsShimmer.map(() => {return <Skeleton responsive={true} width={100} height={42}/>})}
                    </ColabsMainCenter>
                </ColabsMain>
                <DeleteModal
                    target={deleteId !== '' ? colabs?.rows.filter((item) => {return item.id === deleteId})[0].nome : ''}
                    blurOnClick={() => {
                        handleBlurModal();
                    }}
                    targetTitle={'colaborador'}
                    deleteAction={() => {
                        handleDeleteColab();
                    }}
                    blurState={blurModal}
                    frameContent={'colaboradores'}
                />
            </Container>
        </Layout>
    );
};
