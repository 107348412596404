import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    gap: .5rem;
`;

export const HistoryTimerLabel = styled.span`
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: rgba(68, 64, 83, 0.8);
`;

export const HistoryTimerContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 64px;
    padding: 12px 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`;

export const HistoryTimerIconContainer = styled.div`
    padding: 10px;
    border-radius: 50%;
    &.green {
        background-color: rgba(40, 161, 56, 0,1);
    }
    &.blue {
        background-color: rgba(66, 133, 244, 0,1);
    }
    &.gray {
        background-color: rgba(204, 204, 204, 0,1); 
    }
`;

export const HistoryTimerIcon = styled.img`

`;

export const HistoryTimerContent = styled.span`
    font-weight: 400;
    color: #333332;
`