import React from "react";
import Layouts from "../../layouts/Main";
import {
    ConfigListCardsContainer,
    ConfigListContainer,
    ConfigListCreateButton,
    ConfigListCreateButtonIcon,
    ConfigListJobModalCenterContainer,
    ConfigListJobModalFooterButtonsContainer,
    ConfigListJobModalLeftContainer,
    ConfigListLocalModalButtonContainer,
    ConfigListLocalModalCenterContainer,
    ConfigListLocalModalCloseIcon,
    ConfigListLocalModalCloseIconContainer,
    ConfigListLocalModalContainer,
    ConfigListLocalModalDescriptionContainer,
    ConfigListLocalModalDescriptionLabel,
    ConfigListLocalModalDescriptionTextArea,
    ConfigListLocalModalTitle,
    ConfigListLocalModalTopContainer,
    ConfigListTitle,
    ConfigMainContainer,
    ConfigSubtitle,
    ConfigTitle,
    ConfigTitleContainer,
    Container,
} from "./styles";
import Local from "../../assets/icons/users-icon/Local.svg";
import DeleteBlue from "../../assets/icons/Colabs/Delete.svg";
import DeleteGray from "../../assets/icons/users-icon/Close.svg";
import { ConfigCards } from "../../components/ConfigCards";
import { Modal } from "../../layouts/Modal";
import TrackOsInput from "../../components/TrackOsInput";
import { OpenOS } from "../../components/OpenOS";
import { DeleteModal } from "../../components/DeleteModal";
import { ILocal } from "../../interface/local.interface";
import { localService } from "../../services/locals.service";
import {
    createJob,
    deleteJob,
    getJobs,
    updateJob,
} from "../../services/jobs.service";
import { IJobs } from "../../interface/job.interface";
import { InputError } from "../../components/InputError";
import { SuccessModal } from "../../components/SuccessModal";
import { contentHelper } from "../../helpers/ModalResponse";

export const Config = () => {

    const [localInput, setLocalInput] = React.useState("");
    const [localTextarea, setLocalTextarea] = React.useState("");
    const [jobsInput, setJobsInput] = React.useState("");
    const [jobsTextarea, setJobsTextarea] = React.useState("");

    const [locals, setLocals] = React.useState<ILocal[]>([]);
    const [jobs, setJobs] = React.useState<IJobs[]>([]);
    const [blurLocalModal, setBlurLocalModal] = React.useState(false);
    const [blurJobModal, setBlurJobModal] = React.useState(false);
    const [blurDeleteModal, setBlurDeleteModal] = React.useState(false);
    const [deleteModalTarget, setDeleteModalTarget] = React.useState("0");
    const [edit, setEdit] = React.useState(false);
    const [colabsAble, setColabsAble] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState("0");
    const [ableError, setAbleError] = React.useState(false);
    const [responseModal, setResponseModal] = React.useState({state: -1, img: ''})

    const handleBlurLocal = (id?: string) => {
        if (id === "clean") {
            setTimeout(() => {
                setLocalInput("");
            }, 300);
            setTimeout(() => {
                setLocalTextarea("");
            }, 300);
            setBlurLocalModal((old) => !old);
            setAbleError(false);
            return;
        }
        setBlurLocalModal((old) => !old);
        setAbleError(false);
        id && setDeleteId(id);
    };
    const handleJobModal = (id?: string, type?: string) => {
        if(id === 'clean'){
            setTimeout(() => {setJobsInput('')}, 300);
            setTimeout(() => {setJobsTextarea('')}, 300);
            setBlurJobModal((old) => !old);
            setAbleError(false);
            setEdit(false);
            return;
        }
        id && setDeleteId(id);
        setBlurJobModal((old) => !old);
        setAbleError(false);
        setEdit(false);
        if (type === "update") {
            setColabsAble((old) => !old);
            return;
        }
        setColabsAble(false);
    };
    const handleEdit = (value: boolean) => {
        setEdit((old) => (old = value));
    };
    const handleBlurDelete = (id: string, target: string) => {
        setDeleteId(id);
        setBlurDeleteModal((old) => !old);
        setDeleteModalTarget((old) => (old = target));
    };
    const fetchLocals = React.useCallback(async () => {
        try {
            const data = await localService.getLocals();
            setLocals(data);
        } catch (error) {
            // alert(error);
        }
    }, []);
    const fetchJobs = React.useCallback(async () => {
        try {
            const data = await getJobs();
            setJobs(data);
        } catch (error) {
            // alert(error);
        }
    }, []);
    const handleAddLocal = React.useCallback(async (name: string, description: string) => {
        try {
            const data = await localService.createLocal({
                nome: name,
                descricao: description
            });
            setLocals((old) => [...old, data]);
            setAbleError(false);
            handleBlurLocal("clean");
            setTimeout(() => {setResponseModal({state: 0, img: 'Local'})},80) 
        } catch (error: any) {
            setTimeout(() => {setResponseModal({state: 0, img: 'LocalError'})},80)
            setAbleError(true);
        }
    }, []);
    const handleUpdateLocal = React.useCallback(async (name: string, description: string) => {
        try {
            await localService.updateLocal(deleteId, {
                nome: name,
                descricao: description
            });
            fetchLocals();
            setAbleError(false);
            handleBlurLocal('clean');
            setTimeout(() => {setResponseModal({state: 0, img: 'LocalEdit'})},80) 
        } catch (error) {
            setTimeout(() => {setResponseModal({state: 0, img: 'LocalError'})},80) 
            setAbleError(true);
        }
    }, [deleteId, fetchLocals]);
    const handleDeleteLocal = React.useCallback(async () => {
        try {
            await localService.deleteLocal(deleteId);
            setLocals((old) => old.filter((local) => local.id !== deleteId));
        } catch (error) {
            alert(error);
        }
    }, [deleteId]);
    const handleAddJob = React.useCallback(async (name: string, description: string) => {
        try {
            const data = await createJob({
                grupo: name,
                descricao: description,
            });
            setJobs((old) => [...old, data]);
            handleJobModal('clean');
            setAbleError(false);
            setTimeout(() => {setResponseModal({state: 0, img: 'Group'})},80) 
        } catch (error) {
            setTimeout(() => {setResponseModal({state: 0, img: 'GroupError'})},80) 
            setAbleError(true);
        }
    }, []);
    const handleUpdateJob = React.useCallback(async (name: string, description: string) => {
        try {
            await updateJob(deleteId, {
                grupo: name,
                descricao: description,
            });
            fetchJobs();
            setAbleError(false);
            handleJobModal('clean');
            setTimeout(() => {setResponseModal({state: 0, img: 'GroupEdit'})},80) 
            
        } catch (error) {
            setTimeout(() => {setResponseModal({state: 0, img: 'GroupError'})},80) 
            setAbleError(true);
        }
    }, [deleteId, fetchJobs]);
    const handleDeleteJob = React.useCallback(async () => {
        try {
            await deleteJob(deleteId);
            setJobs((old) => old.filter((local) => local.id !== deleteId));
        } catch (error) {
            alert(error);
        }
    }, [deleteId]);

    React.useEffect(() => {
        fetchLocals();
        fetchJobs();
    }, [fetchJobs, fetchLocals]);

    return (
        <Layouts overflowY={false}>
            <Container>
                <ConfigTitleContainer>
                    <ConfigTitle>Configurações do Sistema</ConfigTitle>
                    <ConfigSubtitle>Configurações gerais</ConfigSubtitle>
                </ConfigTitleContainer>
                <ConfigMainContainer>
                    <ConfigListContainer>
                        <ConfigListTitle>Gerenciar locais</ConfigListTitle>
                        <ConfigListCreateButton
                            onClick={() => {
                                handleEdit(false);
                                handleBlurLocal();
                            }}
                        >
                            <ConfigListCreateButtonIcon src={DeleteBlue} />
                        </ConfigListCreateButton>
                        <Modal
                            blurOnClick={() => {
                                handleBlurLocal();
                                handleEdit(false);
                            }}
                            blurState={blurLocalModal}
                        >
                            <ConfigListLocalModalContainer>
                                <ConfigListLocalModalTopContainer>
                                    <ConfigListLocalModalTitle>
                                        Cadastro de local de atendimento
                                    </ConfigListLocalModalTitle>
                                    <ConfigListLocalModalCloseIconContainer
                                        onClick={() => {
                                            handleBlurLocal();
                                        }}
                                    >
                                        <ConfigListLocalModalCloseIcon
                                            src={DeleteGray}
                                        />
                                    </ConfigListLocalModalCloseIconContainer>
                                </ConfigListLocalModalTopContainer>
                                <ConfigListLocalModalCenterContainer>
                                    <TrackOsInput
                                        inputClass={"config"}
                                        inputType={"text"}
                                        editable={true}
                                        hideIcon={" hidden"}
                                        placeHolder={"Ex: BioLabs"}
                                        labelContent={"Nome do Local"}
                                        value={localInput}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            setLocalInput(e.target.value);
                                        }}
                                    />
                                    <InputError
                                        stateAlert={ableError}
                                        value={{
                                            name: localInput,
                                        }}
                                        maxLength={50}
                                    />
                                    <ConfigListLocalModalDescriptionContainer>
                                        <ConfigListLocalModalDescriptionLabel
                                            htmlFor={"textarea"}
                                        >
                                            Descrição
                                        </ConfigListLocalModalDescriptionLabel>
                                        <ConfigListLocalModalDescriptionTextArea
                                            maxLength={170}
                                            id={"textarea"}
                                            value={localTextarea}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => {
                                                setLocalTextarea(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                        <InputError
                                            stateAlert={ableError}
                                            value={{
                                                name: localTextarea,
                                            }}
                                            maxLength={300}
                                        />
                                    </ConfigListLocalModalDescriptionContainer>
                                </ConfigListLocalModalCenterContainer>
                                <ConfigListLocalModalButtonContainer>
                                    <OpenOS
                                        clickFunct={handleBlurLocal}
                                        activeBgColor={"rgba(0, 0, 0, 0.1)"}
                                        hoverBgColor={"rgba(0, 0, 0, 0.05)"}
                                        Color={"rgba(0, 0, 0, 0.5);"}
                                        backgroundColor={"unset"}
                                        content={"Voltar"}
                                    />
                                    <OpenOS
                                        clickFunct={() => {
                                            edit &&
                                            localTextarea !== "" &&
                                            localInput !== ""
                                                ? handleUpdateLocal(localInput, localTextarea)
                                                : !edit &&
                                                  localTextarea !== "" &&
                                                  localInput !== ""
                                                ? handleAddLocal(localInput, localTextarea)
                                                : setAbleError(true);
                                        }}
                                        activeBgColor={
                                            "var(--brand-primary-shade-100)"
                                        }
                                        hoverBgColor={
                                            "var(--brand-primary-shade-200)"
                                        }
                                        Color={"white"}
                                        backgroundColor={
                                            "var(--brand-primary-default)"
                                        }
                                        content={`${
                                            edit ? "Concluir" : "Criar"
                                        }`}
                                    />
                                </ConfigListLocalModalButtonContainer>
                            </ConfigListLocalModalContainer>
                        </Modal>
                        <DeleteModal
                            deleteAction={
                                deleteModalTarget !== "grupo"
                                    ? handleDeleteLocal
                                    : handleDeleteJob
                            }
                            blurOnClick={handleBlurDelete}
                            blurState={blurDeleteModal}
                            targetTitle={deleteModalTarget}
                            target={
                                locals.find((id) => {
                                    return id.id === deleteId;
                                })?.nome
                            }
                            frameContent={deleteModalTarget !== "grupo"? 'locais':'grupos'}
                        />
                        <ConfigListCardsContainer>
                            {locals.map((item, key) => {
                                return (
                                    <ConfigCards
                                        onClickTrash={handleBlurDelete}
                                        onClickEdit={() => {
                                            handleBlurLocal(item.id);
                                            handleEdit(true);
                                            setLocalInput(item.nome);
                                            setLocalTextarea(item.descricao);
                                        }}
                                        target={"local"}
                                        content={item.descricao}
                                        icon={Local}
                                        title={item.nome}
                                        id={item.id}
                                        key={key}
                                    />
                                );
                            })}
                        </ConfigListCardsContainer>
                    </ConfigListContainer>
                    <ConfigListContainer>
                        <ConfigListTitle>Grupos de trabalho</ConfigListTitle>
                        <ConfigListCreateButton
                            onClick={() => {
                                handleJobModal();
                            }}
                        >
                            <ConfigListCreateButtonIcon
                                className={`${
                                    blurLocalModal || blurDeleteModal
                                        ? "hide"
                                        : ""
                                }`}
                                src={DeleteBlue}
                            />
                        </ConfigListCreateButton>
                        <Modal
                            blurState={blurJobModal}
                            blurOnClick={handleJobModal}
                        >
                            <ConfigListLocalModalContainer
                                className={`job${!colabsAble ? "update" : ""}`}
                            >
                                <ConfigListLocalModalTopContainer>
                                    <ConfigListLocalModalTitle>
                                        Grupo de trabalho
                                    </ConfigListLocalModalTitle>
                                    <ConfigListLocalModalCloseIconContainer
                                        onClick={() => {
                                            handleJobModal();
                                        }}
                                    >
                                        <ConfigListLocalModalCloseIcon
                                            src={DeleteGray}
                                        />
                                    </ConfigListLocalModalCloseIconContainer>
                                </ConfigListLocalModalTopContainer>
                                <ConfigListJobModalCenterContainer>
                                    <ConfigListJobModalLeftContainer>
                                        <ConfigListLocalModalCenterContainer>
                                            <TrackOsInput
                                                inputClass={"config"}
                                                inputType={"text"}
                                                editable={true}
                                                hideIcon={" hidden"}
                                                placeHolder={"Ex: Eletricista"}
                                                labelContent={"Nome:"}
                                                value={jobsInput}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setJobsInput(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <InputError
                                                stateAlert={ableError}
                                                value={{
                                                    name: jobsInput,
                                                }}
                                                maxLength={50}
                                            />
                                            <ConfigListLocalModalDescriptionContainer>
                                                <ConfigListLocalModalDescriptionLabel>
                                                    Descrição
                                                </ConfigListLocalModalDescriptionLabel>
                                                <ConfigListLocalModalDescriptionTextArea
                                                    value={jobsTextarea}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                                    ) => {
                                                        setJobsTextarea(
                                                            e.target.value
                                                        );
                                                    }}
                                                    maxLength={170}
                                                />
                                            </ConfigListLocalModalDescriptionContainer>
                                        </ConfigListLocalModalCenterContainer>
                                    </ConfigListJobModalLeftContainer>
                                </ConfigListJobModalCenterContainer>
                                <ConfigListJobModalFooterButtonsContainer>
                                    <ConfigListLocalModalButtonContainer>
                                        <OpenOS
                                            clickFunct={handleJobModal}
                                            activeBgColor={"rgba(0, 0, 0, 0.1)"}
                                            hoverBgColor={"rgba(0, 0, 0, 0.05)"}
                                            Color={"rgba(0, 0, 0, 0.5);"}
                                            backgroundColor={"unset"}
                                            content={"Cancelar"}
                                        />
                                        <OpenOS
                                            clickFunct={() => {
                                                edit
                                                    ? jobsInput !== "" &&
                                                      jobsTextarea !== ""
                                                        ? handleUpdateJob(jobsInput, jobsTextarea)
                                                        : setAbleError(true)
                                                    : handleAddJob(jobsInput, jobsTextarea);
                                            }}
                                            activeBgColor={
                                                "var(--brand-primary-shade-100)"
                                            }
                                            hoverBgColor={
                                                "var(--brand-primary-shade-200)"
                                            }
                                            Color={"white"}
                                            backgroundColor={
                                                "var(--brand-primary-default)"
                                            }
                                            content={`${
                                                edit ? "Concluir" : "Criar"
                                            }`}
                                        />
                                    </ConfigListLocalModalButtonContainer>
                                </ConfigListJobModalFooterButtonsContainer>
                            </ConfigListLocalModalContainer>
                        </Modal>
                        <ConfigListCardsContainer>
                            {jobs !== [] &&
                                jobs.map((item, key) => {
                                    return (
                                        <ConfigCards
                                            onClickTrash={handleBlurDelete}
                                            target={"grupo"}
                                            onClickEdit={() => {
                                                handleJobModal(
                                                    item.id,
                                                    "update"
                                                );
                                                handleEdit(true);
                                                setJobsInput(item.grupo);
                                                setJobsTextarea(item.descricao);
                                            }}
                                            content={item.descricao}
                                            icon={Local}
                                            title={item.grupo}
                                            id={item.id}
                                            key={key}
                                        />
                                    );
                                })}
                        </ConfigListCardsContainer>
                    </ConfigListContainer>
                    <SuccessModal img={responseModal.img} blurOnClick={() => {setResponseModal(old => old = {state: -1, img: old.img})}} blurState={responseModal.state === 0? true:false} content={contentHelper(responseModal.img)} target={localInput}/>
                </ConfigMainContainer>
            </Container>
        </Layouts>
    );
};
