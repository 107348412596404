import { LogCards } from "../../components/LogCards";
import { ShowMoreColabCard } from "../../components/ShowMoreColabCard";
import TrackOsInput from "../../components/TrackOsInput";
import { useServiceOrder } from "../../contexts/ServiceOrder";
import Layouts from "../../layouts/Main";
import { getPropriedades } from "../../utils/getPropriedades";
import {
    Container,
    ShowMoreHistoryColabsContainer,
    ShowMoreHistoryDataOsContainer,
    ShowMoreHistoryDataOsDescriptionContainer,
    ShowMoreHistoryDataOsDescriptionTextarea,
    ShowMoreHistoryDataOsDescriptionTextareaLabel,
    ShowMoreHistoryDataOsInputsContainer,
    ShowMoreHistoryDataOsMain,
    ShowMoreHistoryDataOsTitle,
    ShowMoreHistorySubTitle,
    ShowMoreHistoryTitle,
    ShowMoreHistoryTopContainer,
    ShowMoreHistoryTopTitle,
    ShowMoreOsCardsContainer,
    ShowMoreOsEndContainer,
    ShowMoreOsInteractionsHistoryContainer,
    ShowMoreOsInteractionsHistoryTitle,
    ShowMoreOsLogContainer,
    ShowMoreOsLogTopContainer,
    ShowMoreOsMainContainer,
    ShowMoreOsNotesContainer,
    ShowMoreOsNotesTitle,
    ShowMoreOsTopRight,
} from "./styles";
import React from "react";
import { LogContext } from "../../contexts/ChangeLog";
import { useAnotacao } from "../../contexts/Anotacao.contex";
import { ShowMoreOsNotesCards } from "../../components/ShowMoreOsNotesCards";
import HistoryTimerCard from "../../components/HistoryTimerCard";
import { osService } from "../../services/os.service";
import { ColabCardSkeleton, InputSkeleton, NotesSkeleton, Skeleton } from "../../components/Skeleton";


const ColabShimmer = [
    {},
    {},
    {},
    {}
];
const NotesShimmer = [
    {},
    {}
]
const LogShimmer = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
]

export const ShowMoreOsHistory = () => {
    const { osNumberActual, setOsNumberActual } = useServiceOrder();
    const { log, getLog } = React.useContext(LogContext);
    const { anotacoes, getAnotacoes } = useAnotacao();

    const osProps = getPropriedades(
        osNumberActual.status,
        osNumberActual.prioridade
    );
    const initialFilter = {
        page: Number(window.location.pathname.split('/')[3]),
        search: "",
        grupos: [],
        status: "",
        prioridade: "",
    }
    const Inputs = [
        {
            labelContent: "Local",
            inputContent: osNumberActual.local.nome,
            inputType: "text",
        },
        {
            labelContent: "Solicitante",
            inputContent: osNumberActual.nome_solicitante,
            inputType: "text",
        },
        {
            labelContent: "Prioridade",
            inputContent: osProps.prioridade,
            inputType: "text",
        },
        {
            labelContent: "Tipo",
            inputContent: osNumberActual.tipo,
            inputType: "text",
        },
        {
            labelContent: "Grupo de trabalho",
            inputContent: "Eletricista",
            inputType: "text",
        },
        {
            labelContent: "Status",
            inputContent: osProps.status,
            inputType: "text",
        },
        {
            labelContent: "Tempo estimado",
            inputContent: osNumberActual.tempo_estimado,
            inputType: "text",
        },
        {
            labelContent: "Data de abertura",
            inputContent: String(osNumberActual.created_at),
            inputType: "text",
        },
        {
            labelContent: "Data de encerramento",
            inputContent: String(osNumberActual.finished_at),
            inputType: "text",
        },
    ];
    React.useEffect(() => {
        getLog(window.location.pathname.split('/')[4])
        fecthActualOs();
        getAnotacoes(window.location.pathname.split('/')[4]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fecthActualOs = React.useCallback(async () => {
        await osService
            .getOsFinished(window.location.pathname.split('/')[3] === '0'? {}:initialFilter)
            .then((response) => {
                setOsNumberActual(
                    response.data.rows.filter((item) => {
                        return (
                            item.id === window.location.pathname.split("/")[4]
                        );
                    })[0]
                );
            })
            .catch((error) => {
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setOsNumberActual]);
    return (
        <Layouts>
            <Container>
                <ShowMoreHistoryTitle>
                    Ordem de Serviço: {osNumberActual?.id !== '-1'? osNumberActual?.numero:<Skeleton width={120} height={18}/>}
                </ShowMoreHistoryTitle>
                <ShowMoreHistorySubTitle>
                    Detalhes da ordem de serviço
                </ShowMoreHistorySubTitle>
                <ShowMoreHistoryTopContainer>
                    <ShowMoreHistoryTopTitle>
                        Encarregados
                    </ShowMoreHistoryTopTitle>
                    <ShowMoreHistoryColabsContainer>
                        {osNumberActual.id !== '-1'?osNumberActual?.encarregados?.map((item, key) => {
                            return (
                                <ShowMoreColabCard
                                    key={key}
                                    leader={item.responsavel}
                                    username={item.nome}
                                    job={item.grupos[0].grupo}
                                />
                            );
                        }):ColabShimmer.map(() => {return <ColabCardSkeleton responsive={true} height={0} width={0}/>})}
                    </ShowMoreHistoryColabsContainer>
                </ShowMoreHistoryTopContainer>
                <ShowMoreHistoryDataOsContainer>
                    <ShowMoreHistoryDataOsTitle>
                        Dados da OS
                    </ShowMoreHistoryDataOsTitle>
                    <ShowMoreHistoryDataOsMain>
                        <ShowMoreHistoryDataOsDescriptionContainer>
                            {osNumberActual.id !== '-1'?<ShowMoreHistoryDataOsDescriptionTextareaLabel
                                htmlFor={"description"}
                            >
                                Descrição
                            </ShowMoreHistoryDataOsDescriptionTextareaLabel>:<Skeleton width={148} height={22}/>}
                            {osNumberActual.id !== '-1'?<ShowMoreHistoryDataOsDescriptionTextarea
                                id={"description"}
                                maxLength={500}
                                disabled
                                value={osNumberActual.descricao}
                            />:<Skeleton width={250} height={400}/>}
                        </ShowMoreHistoryDataOsDescriptionContainer>
                        <ShowMoreHistoryDataOsInputsContainer>
                            {osNumberActual.id !== '-1'?Inputs?.map((item, key) => {
                                return (
                                    <TrackOsInput
                                        key={key}
                                        hideIcon={" hidden"}
                                        defaultValue={
                                            item.labelContent?.replaceAll(
                                                " ",
                                                ""
                                            ) === "Datadeabertura"
                                                ? `${String(item.inputContent)
                                                      ?.split("-")[2]
                                                      ?.slice(0, 2)}/${
                                                      String(
                                                          item.inputContent
                                                      )?.split("-")[1]
                                                  }/${
                                                      String(
                                                          item.inputContent
                                                      )?.split("-")[0]
                                                  }`
                                                : String(item.inputContent)
                                        }
                                        value={
                                            String(item.inputContent) === "null"
                                                ? "Indefinido"
                                                : String(item.inputContent).split('-').length > 2? 
                                                `${String(item.inputContent)
                                                      ?.split("-")[2]
                                                      ?.slice(0, 2)}/${
                                                      String(
                                                          item.inputContent
                                                      )?.split("-")[1]
                                                  }/${
                                                      String(
                                                          item.inputContent
                                                      )?.split("-")[0]
                                                  }`: String(item.inputContent)}
                                        editable={false}
                                        inputContent={
                                            String(item.inputContent) === "null"
                                                ? "Indefinido"
                                                : String(item.inputContent)
                                        }
                                        inputType={item.inputType}
                                        inputClass={"history"}
                                        labelContent={item.labelContent}
                                    />
                                );
                            }):Inputs?.map(() => {return <InputSkeleton responsive={true} height={90} width={30}/>})}
                            {osNumberActual.id !== '-1'?<HistoryTimerCard
                                color={"green"}
                                content={osNumberActual.em_aberto}
                                label={"Tempo em aberta"}
                            />:<InputSkeleton responsive={true} height={90} width={30}/>}
                            {osNumberActual.id !== '-1'?<HistoryTimerCard
                                color={"gray"}
                                content={osNumberActual.pausa}
                                label={"Tempo pausada"}
                            />:<InputSkeleton responsive={true} height={90} width={30}/>}
                            {osNumberActual.id !== '-1'?<HistoryTimerCard
                                color={"blue"}
                                content={osNumberActual.andamento}
                                label={"Tempo em andamento"}
                            />:<InputSkeleton responsive={true} height={90} width={30}/>}
                        </ShowMoreHistoryDataOsInputsContainer>
                    </ShowMoreHistoryDataOsMain>
                </ShowMoreHistoryDataOsContainer>
                <ShowMoreOsEndContainer>
                    <ShowMoreOsNotesContainer>
                        <ShowMoreOsNotesTitle>Anotações</ShowMoreOsNotesTitle>
                        <ShowMoreOsCardsContainer>
                            {osNumberActual.id !== '-1'? anotacoes.map((anotacao) => {
                                    return (
                                        <ShowMoreOsNotesCards
                                            anotacao={anotacao}
                                        />);
                            }): NotesShimmer.map(() => {return <NotesSkeleton height={0} width={0}/>})}
                        </ShowMoreOsCardsContainer>
                    </ShowMoreOsNotesContainer>
                    <ShowMoreOsInteractionsHistoryContainer>
                        <ShowMoreOsInteractionsHistoryTitle>
                            Histórico de Interações de OS
                        </ShowMoreOsInteractionsHistoryTitle>
                        <ShowMoreOsLogContainer>
                            <ShowMoreOsLogTopContainer className={`${osNumberActual.id === '-1'? 'shimmer':''}`}>
                                {osNumberActual.id !== '-1'?<ShowMoreOsTopRight>
                                    {log?.length} interações realizadas
                                </ShowMoreOsTopRight>:<Skeleton width={180} height={18}/>}
                            </ShowMoreOsLogTopContainer>
                            <ShowMoreOsMainContainer className={`${osNumberActual.id === '-1'? 'gap':''}`}>
                                {osNumberActual.id !== '-1'? log?.map((item, key) => {
                                    return (
                                        <LogCards
                                            key={key}
                                            action={item.message}
                                            createdAt={item.created_at}
                                            username={item.name}
                                            value={item.value}
                                        />
                                    );
                                }):LogShimmer.map(() => {return <Skeleton width={100} height={28} responsive={true}/>})}
                            </ShowMoreOsMainContainer>
                        </ShowMoreOsLogContainer>
                    </ShowMoreOsInteractionsHistoryContainer>
                </ShowMoreOsEndContainer>
            </Container>
        </Layouts>
    );
};
