import React from "react";
import Layouts from "../../layouts/Main";
import Select from "react-select";
import SelectStylesInCharge from "../../styles/Multi-Select-Colab";
import { useHistory } from "react-router-dom";

import {
    ColabDetailsContainer,
    Container,
    Form,
    FormContainer,
    LabelInput,
    RowForm,
    SubTitle,
    Title,
    Input,
    InputContainer,
    ButtonContainer,
    Button,
} from "./styles";
import { useLocal } from "../../contexts/LocalContext";
import { useColabs } from "../../contexts/Users";
import { InputError } from "../../components/InputError";
import { osValidate } from "../../validators/create-os.validator";

const initialColab = {
    nome: "",
    data_nascimento: "",
    matricula: "",
    email: "",
    telefone: "",
    cpf: "",
    grupos: [],
};

const ColabCreate: React.FC = () => {
    const [colab, setGlobalColab] = React.useState(initialColab);
    const { createColabs } = useColabs();
    const { jobs } = useLocal();
    const history = useHistory();
    const [ableError, setAbleError] = React.useState(false);

    const setColab = React.useCallback((obj: object, type?: string) => {
        setGlobalColab((old) => {
            return { ...old, ...obj };
        });
    }, []);

    const formatInput = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
		let value = e.target.value
        if(type === 'mail'){
            return e.target.value;
        }
		if(type === 'text'){
			return e.target.value.replace(/[0-9±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©&°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g,'')
		}
		if(type === 'phone'){
			if(value.length === 11){
				return e.target.value.replace(/(\d{2})?(\d{5})?(\d{4})/g, "($1)$2-$3")
			}
			return value.replace(/[A-Za-zá-źÁ-ŹǸẼỸ ẽỹṽṼẐ±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©ẀÀàḿṕḱǘǵḉÁḾṔḰÚǴḈ&ǹǛ°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g,'')
		}
		if(type === 'cpf'){
			if(value.length === 11){
				return e.target.value.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/g, "$1.$2.$3-$4")
			}
			return value.replace(/[A-Za-zá-źÁ-ŹǸẼỸ ẽỹṽṼẐ±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©ẀÀàḿṕḱǘǵḉÁḾṔḰÚǴḈ&ǹǛ°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g,'')
		}
		if(type === 'registration'){
			return value.replace(/[A-Za-zá-źÁ-ŹǸẼỸ ẽỹṽṼẐ±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©ẀÀàḿṕḱǘǵḉÁḾṔḰÚǴḈ&ǹǛ°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g,'')
		}
    };

    const handleSubmit = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        createColabs(
            {
                ...colab,
            },
            () => {
                history.push("/colabs");
            }
        );
    };
    const validation = () => {
        if(osValidate({
            osNumber: colab.cpf.replace(/[A-Za-zá-źÁ-ŹǸẼỸ ẽỹṽṼẐ±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©ẀÀàḿṕḱǘǵḉÁḾṔḰÚǴḈ&ǹǛ°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g,''),
            name:colab.nome
        }) !== undefined){
            setAbleError(true);
            return false;
        }
        if(osValidate({
            osNumber: colab.telefone,
            name: colab.data_nascimento
        }) !== undefined){
            setAbleError(true);
            return false;
        }
        if(osValidate({
            osNumber: colab.telefone,
            name: colab.email
        }) !== undefined){
            setAbleError(true);
            return false;
        }
        if(osValidate({
            osNumber: colab.matricula
        }) !== undefined){
            setAbleError(true);
            return false;
        }
        return true;
    }
    return (
        <Layouts>
            <Container>
                <Title>Colaborador</Title>
                <SubTitle>Cadastro de colaborador</SubTitle>
                <ColabDetailsContainer>
                    <FormContainer>
                        <Form>
                            <RowForm>
                                <InputContainer style={{ width: "69%" }}>
                                    <LabelInput>Nome completo</LabelInput>
                                    <Input
                                        value={colab.nome}
                                        onChange={(e) =>
                                            setColab({
                                                nome: formatInput(e, 'text'),
                                            })
                                        }
                                    />
                                    <InputError maxLength={70} stateAlert={ableError} value={{name:colab.nome}}/>
                                </InputContainer>
                                <InputContainer style={{ width: "29%" }}>
                                    <LabelInput>Data de nascimento</LabelInput>
                                    <Input
                                        type="date"
                                        value={colab.data_nascimento}
                                        onChange={(e) =>
                                            setColab({
                                                data_nascimento:
                                                    e.currentTarget.value,
                                            })
                                        }
                                    />
                                    <InputError maxLength={9980} stateAlert={ableError} value={{name: colab.data_nascimento}}/>
                                </InputContainer>
                            </RowForm>
                            <RowForm>
                                <InputContainer style={{ width: "32%" }}>
                                    <LabelInput>CPF</LabelInput>
                                    <Input
										maxLength={11}
                                        value={colab.cpf}
                                        onChange={(e) =>
                                            setColab({
                                                cpf: formatInput(e, 'cpf'),
                                            })
                                        }
                                    />
                                    <InputError maxLength={9980} stateAlert={ableError} value={{osNumber: colab.cpf}}/>
                                </InputContainer>
                                <InputContainer style={{ width: "32%" }}>
                                    <LabelInput>Telefone</LabelInput>
                                    <Input
										maxLength={11}
                                        value={colab.telefone}
                                        onChange={(e) =>
                                            setColab({
                                                telefone: formatInput(e, 'phone'),
                                            })
                                        }
                                    />
                                    <InputError maxLength={9980} stateAlert={ableError} value={{osNumber: colab.telefone}}/>
                                </InputContainer>
                                <InputContainer style={{ width: "32%" }}>
                                    <LabelInput>E-mail</LabelInput>
                                    <Input
										maxLength={60}
                                        value={colab.email}
                                        onChange={(e) =>
                                            {
                                            setColab({
                                                email: formatInput(e, 'mail'),
                                            })}
                                        }
                                    />
                                    <InputError maxLength={9980} stateAlert={ableError} value={{name: colab.email}}/>
                                </InputContainer>
                            </RowForm>
                            <RowForm>
                                <InputContainer style={{ width: "29%" }}>
                                    <LabelInput>Matricula</LabelInput>
                                    <Input
										maxLength={8}
                                        value={colab.matricula}
                                        onChange={(e) =>
                                            setColab({
                                                matricula:
                                                    formatInput(e, 'registration'),
                                            })
                                        }
                                    />
                                    <InputError maxLength={9980} stateAlert={ableError} value={{osNumber: colab.matricula}}/>
                                </InputContainer>
                                <InputContainer style={{ width: "69%" }}>
                                    <LabelInput>Grupo de Trabalho</LabelInput>
                                    <Select
                                        onChange={(e) =>
                                            setColab({
                                                grupos: e.map(
                                                    (grupo) => grupo.value
                                                ),
                                            })
                                        }
                                        styles={SelectStylesInCharge}
                                        placeholder={"Selecionar"}
                                        closeMenuOnSelect={true}
                                        isMulti
                                        options={jobs.map((job) => {
                                            return {
                                                label: job.grupo,
                                                value: job.id,
                                            };
                                        })}
                                    />
                                    <InputError maxLength={9980} stateAlert={ableError} value={{osNumber: String(colab.grupos)}}/>
                                </InputContainer>
                            </RowForm>
                            <ButtonContainer>
                                <Button onClick={(e) => {if(validation() === true){handleSubmit(e)}}}>
                                    Cadastrar Colaborador
                                </Button>
                            </ButtonContainer>
                        </Form>
                    </FormContainer>
                </ColabDetailsContainer>
            </Container>
        </Layouts>
    );
};

export default ColabCreate;
