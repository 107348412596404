import { Find } from "../interface/find.interface";
import { api } from "./api.service";

class DashboardService {
    async findTop(find: Find) {
        return await api().get(`/dashboard/top`, {params: find});
    }

    async findRight(find: Find) {
        return await api().get(`/dashboard/right`, {params: find});
    }

    async findGrafico(find: Find) {
        return await api().get(`/dashboard/grafico`, {params: find});
    }
}

export const dashboardService = new DashboardService();