import styled from 'styled-components';

interface IColabCard {
    leader: boolean;
}

export const Container = styled.div`
    display: flex;
    width: 22%;
    min-width: 18.75rem;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 0.125rem 0.5rem rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    padding: 0.625rem 0.75rem 0.875rem 1rem;
`;

export const ColabCardIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    background-color: #4285F4;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.0625rem;
`;

export const ColabCardInfoContainer = styled.div`
    display: flex;
    width: 100%;
    margin-left: 1rem;
`;

export const ColabCardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ColabCardUsername = styled.span`
    font-weight: 600;
    color: #444053;
    max-width: 10.625rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ColabCardJob = styled.span`
    font-weight: 400;
    max-width: 10.625rem;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ColabCardIconsContainer = styled.div<IColabCard>`
    display: flex;
    gap: 1rem;
    justify-content: ${({leader}) => `${leader? 'space-between':'flex-end'}`};
`;

export const ColabCardLeaderContainer = styled.div`
    border: 0.125rem solid #F9971E;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    right: 0.5rem;
    display: none;
    &.leader {
        display: flex;
    }
`;

export const ColabCardLeaderIcon = styled.img`
    width: 0.875rem;
    height: 0.875rem;
`;

export const ColabCardTrashIconContainer = styled.div`
    margin-top: 0.125rem;
`;

export const ColabCardTrashIcon = styled.img`
    cursor: pointer;
    width: 1.125rem;
    filter: invert(56%) sepia(14%) saturate(7%) hue-rotate(16deg) brightness(92%) contrast(85%);
`;