import React, { useContext, useState } from "react";
import { ShowMoreColabCard } from "../../components/ShowMoreColabCard";
import { ShowMoreOsNotesCards } from "../../components/ShowMoreOsNotesCards";
import TrackOsInput from "../../components/TrackOsInput";
import Layouts from "../../layouts/Main";
import {
    Container,
    ShowMoreOsDataOsContainer,
    ShowMoreOsColabsContainer,
    ShowMoreOsSubTitle,
    ShowMoreOsTitle,
    ShowMoreOsTopContainer,
    ShowMoreOsTopTitle,
    ShowMoreOsDataOsDescriptionContainer,
    ShowMoreOsDataOsTitle,
    ShowMoreOsDataOsDescriptionTextarea,
    ShowMoreOsDataOsInputsContainer,
    ShowMoreOsDataOsMain,
    ShowMoreOsDataOsDescriptionTextareaLabel,
    ShowMoreOsEndContainer,
    ShowMoreOsNotesContainer,
    ShowMoreOsNotesTitle,
    ShowMoreOsCardsContainer,
    ShowMoreOsInteractionsHistoryContainer,
    ShowMoreOsInteractionsHistoryTitle,
    ShowMoreOsLogContainer,
    ShowMoreOsLogTopContainer,
    ShowMoreOsTopRight,
    ShowMoreOsMainContainer,
    ShowMoreOsEditIcon,
    ShowMoreOsIconContainer,
    ShowMoreOsHoverEdit,
    ShowMoreOsHoverEditPart,
    ShowMoreOsInputsRowContainer,
    ShowMoreOsSelectContainer,
    ShowMoreOsSelectLabel,
    ShowMoreOsAddColab,
    ShowMoreOsModalTitle,
    ShowMoreOsModalContainer,
    ShowMoreOsModalSelectContainer,
    ShowMoreOsModalSelectLabel,
    ShowMoreOsModalEndContainer,
} from "./styles";
import { LogContext } from "../../contexts/ChangeLog";
import { LogCards } from "../../components/LogCards";
import Edit from "../../assets/icons/Colabs/Edit.svg";
import Clean from "../../assets/icons/Track-Service-Order/Clean.svg";
import CleanWhite from "../../assets/icons/Track-Service-Order/CleanWhite.svg";
import { useServiceOrder } from "../../contexts/ServiceOrder";
import { getPropriedades } from "../../utils/getPropriedades";
import { useAnotacao } from "../../contexts/Anotacao.contex";
import { ShowMoreOsSelect } from "../../components/ShowMoreOsSelect";
import { osService } from "../../services/os.service";
import { useLocal } from "../../contexts/LocalContext";
import { Modal } from "../../layouts/Modal";
import { useColabs } from "../../contexts/Users";
import { OpenOS } from "../../components/OpenOS";
import { DeleteModal } from "../../components/DeleteModal";
import {
    ColabCardSkeleton,
    InputSkeleton,
    NotesSkeleton,
    Skeleton,
} from "../../components/Skeleton";
import Toast from "../../components/Toast";

const PriorityValues = [
    {
        value: "1",
        label: "Baixa",
    },
    {
        value: "2",
        label: "Média",
    },
    {
        value: "3",
        label: "Alta",
    },
];
const ColabShimmer = [{}, {}, {}, {}];
const NotesShimmer = [{}, {}];
const LogShimmer = [{}, {}, {}, {}, {}, {}, {}];

export const ShowMoreTrackOs = () => {
    const {
        osNumberActual,
        setOsNumberActual,
        updateOs,
        addColab,
        deleteColab,
    } = useServiceOrder();
    const { log, getLog } = useContext(LogContext);
    const { local, types } = useLocal();
    const { anotacoes, getAnotacoes } = useAnotacao();
    const { colabs } = useColabs();

    const [showModal, setShowModal] = React.useState(false);
    const [deleteModal, setDeleteModal] = React.useState({
        state: false,
        target: "",
        value: "",
    });
    const [reload, setReload] = React.useState(false);
    const [colabState, setColabState] = React.useState("");
    const [osStatus, setOsStatus] = React.useState("");
    const [updateData, setUpdateData] = React.useState({
        descricao: "",
        id_local: "",
        id_tipo: "",
        nome_solicitante: "",
        prioridade: "",
        tempo_estimado: "",
    });

    const initialFilter = {
        page: Number(window.location.pathname.split("/")[3]),
        search: "",
        grupos: [],
        status: "",
        prioridade: "",
    };
    const [toast, setToast] = React.useState({
        toast: -1,
        input: false,
        content: "",
    });
    const [editable, setEditable] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [edit, setEdit] = useState(false);
    const [greenBackground, setGreenBackground] = useState("");

    const osProps = getPropriedades(osStatus, updateData.prioridade);

    React.useEffect(() => {
        getLog(window.location.pathname.split("/")[4]);
        getAnotacoes(window.location.pathname.split("/")[4]);
        fecthActualOs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Inputs = [
        {
            id: 1,
            labelContent: "Local",
            inputContent: osNumberActual?.local.nome,
            inputType: "text",
        },
        {
            id: 2,
            labelContent: "Solicitante",
            inputContent: osNumberActual?.nome_solicitante,
            inputType: "text",
            value: updateData.nome_solicitante,
        },
        {
            id: 3,
            labelContent: "Prioridade",
            inputContent: osProps?.prioridade,
            inputType: "text",
        },
        {
            id: 4,
            labelContent: "Tipo",
            inputContent: osNumberActual?.tipo ? osNumberActual?.tipo : "",
            inputType: "text",
        },
        {
            id: 6,
            labelContent: "Status",
            inputContent: osProps?.status,
            inputType: "text",
        },
        {
            id: 7,
            labelContent: "Tempo estimado",
            inputContent: osNumberActual?.tempo_estimado
                ? "osNumberActual?.tempo_estimado"
                : "",
            inputType: "text",
            value:
                updateData.tempo_estimado.length < 25
                    ? updateData.tempo_estimado
                    : "",
        },
        {
            id: 8,
            labelContent: "Data de abertura",
            inputContent: osNumberActual?.created_at.toString(),
            inputType: "string",
            value: "",
        },
    ];

    if (
        String(updateData.id_tipo) === "undefined" &&
        String(types[0]?.id).length > 15
    ) {
        setUpdateData(
            (old) =>
                (old = {
                    ...old,
                    id_tipo: types.filter((item) => {
                        return item.tipo === osNumberActual.tipo;
                    })[0]?.id,
                })
        );
    }
    const priorityFilter = (id: string) => {
        if (id === "1") {
            return "Baixa";
        }
        if (id === "2") {
            return "Média";
        }
        if (id === "3") {
            return "Alta";
        }
        return "";
    };

    const ActiveChanges = (key: number) => {
        if (greenBackground !== "") {
            return;
        }
        setEditable(key);
        setDisabled((old) => !old);
        setEdit((old) => !old);
    };

    const DelayChanges = (key: number) => {
        if (greenBackground !== "") {
            return;
        }
        setEditable((old) => (old = key));
        setDisabled((old) => !old);
        setTimeout(() => {
            setEdit((old) => !old);
        }, 1000);
    };

    const Animation = () => {
        if (greenBackground !== "") {
            return;
        }
        if (!greenBackground) {
            setGreenBackground((old) => (old = "green"));
            setTimeout(() => {
                setGreenBackground((old) => (old = ""));
            }, 1000);
            return;
        }
        setGreenBackground((old) => (old = ""));
    };

    const handleUpdateOs = React.useCallback(async () => {
        try {
            const data = {
                id: window.location.pathname.split("/")[4],
                numero: osNumberActual.numero,
                nome_solicitante: updateData.nome_solicitante,
                id_local: updateData.id_local,
                id_tipo: updateData.id_tipo,
                prioridade: Number(updateData.prioridade),
                tempo_estimado: updateData.tempo_estimado,
                descricao: updateData.descricao,
            };
            await updateOs(data);
             
        } catch (error) {
        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, updateOs, osNumberActual?.numero]);

    const handleAddColab = React.useCallback(
        async (os: string, colab: string) => {
            try {
                await addColab({ id_os: os, id_encarregado: colab });
                setReload((old) => !old);
                setToast((old) => (old = { ...old, toast: 0 }));
            } catch (error) {
                setToast((old) => (old = { ...old, toast: 0, content: "" }));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [addColab]
    );

    const handleDeleteColab = React.useCallback(
        async (os: string, colab: string) => {
            await deleteColab({ id_os: os, id_encarregado: colab });
            setReload((old) => !old);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [setReload, deleteColab]
    );

    const fecthActualOs = React.useCallback(async () => {
        await osService
            .getOsProgress(
                window.location.pathname.split("/")[3] === "0"
                    ? {}
                    : initialFilter
            )
            .then((response) => {
                setOsNumberActual(
                    response.data.rows.filter((item) => {
                        return (
                            item.id === window.location.pathname.split("/")[4]
                        );
                    })[0]
                );
                setOsStatus(
                    response.data.rows.filter((item) => {
                        return (
                            item.id === window.location.pathname.split("/")[4]
                        );
                    })[0].status
                );
                setUpdateData(
                    (old) =>
                        (old = {
                            ...old,
                            id_local: response.data.rows.filter((item) => {
                                return (
                                    item.id ===
                                    window.location.pathname.split("/")[4]
                                );
                            })[0].local.id,
                        })
                );
                setUpdateData(
                    (old) =>
                        (old = {
                            ...old,
                            nome_solicitante: response.data.rows.filter(
                                (item) => {
                                    return (
                                        item.id ===
                                        window.location.pathname.split("/")[4]
                                    );
                                }
                            )[0].nome_solicitante,
                        })
                );
                setUpdateData(
                    (old) =>
                        (old = {
                            ...old,
                            prioridade: response.data.rows.filter((item) => {
                                return (
                                    item.id ===
                                    window.location.pathname.split("/")[4]
                                );
                            })[0].prioridade,
                        })
                );
                setUpdateData(
                    (old) =>
                        (old = {
                            ...old,
                            tempo_estimado: response.data.rows.filter(
                                (item) => {
                                    return (
                                        item.id ===
                                        window.location.pathname.split("/")[4]
                                    );
                                }
                            )[0].tempo_estimado,
                        })
                );
                setUpdateData(
                    (old) =>
                        (old = {
                            ...old,
                            nome_solicitante: response.data.rows.filter(
                                (item) => {
                                    return (
                                        item.id ===
                                        window.location.pathname.split("/")[4]
                                    );
                                }
                            )[0].nome_solicitante,
                        })
                );
                setUpdateData(
                    (old) =>
                        (old = {
                            ...old,
                            descricao: response.data.rows.filter((item) => {
                                return (
                                    item.id ===
                                    window.location.pathname.split("/")[4]
                                );
                            })[0].descricao,
                        })
                );
                setUpdateData((old) => {
                    return {
                        ...old,
                        id_tipo: types?.filter?.((item) => {
                            return item.tipo === osNumberActual.tipo;
                        })[0]?.id,
                    };
                });
            })
            .catch((error) => {
                // alert(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setOsNumberActual]);

    React.useEffect(() => {
        fecthActualOs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fecthActualOs, reload]);

    return (
        <Layouts overflowY={true}>
            <Container>
                <ShowMoreOsTitle>
                    Ordem de Serviço: {osNumberActual && osNumberActual.numero}
                </ShowMoreOsTitle>
                <ShowMoreOsSubTitle>
                    Detalhes da ordem de serviço
                </ShowMoreOsSubTitle>
                <ShowMoreOsTopContainer>
                    <ShowMoreOsTopTitle>Encarregados</ShowMoreOsTopTitle>
                    <ShowMoreOsColabsContainer>
                        {osNumberActual?.id !== "-1"
                            ? osNumberActual?.encarregados?.map((item, key) => {
                                  return (
                                      <ShowMoreColabCard
                                          key={key}
                                          leader={item?.responsavel}
                                          username={item?.nome}
                                          job={
                                              item.grupos &&
                                              item?.grupos[0]?.grupo
                                          }
                                          onClickTrash={() => {
                                              setDeleteModal({
                                                  state: true,
                                                  target: item.nome,
                                                  value: item.id,
                                              });
                                          }}
                                      />
                                  );
                              })
                            : ColabShimmer.map(() => {
                                  return (
                                      <ColabCardSkeleton
                                          responsive={true}
                                          height={0}
                                          width={0}
                                      />
                                  );
                              })}
                        {osNumberActual?.id !== "-1" ? (
                            <ShowMoreOsAddColab
                                onClick={() => {
                                    setShowModal(true);
                                }}
                            >
                                + Adicionar colaborador
                            </ShowMoreOsAddColab>
                        ) : (
                            <></>
                        )}
                    </ShowMoreOsColabsContainer>
                </ShowMoreOsTopContainer>
                <ShowMoreOsDataOsContainer>
                    <ShowMoreOsDataOsTitle>Dados da OS</ShowMoreOsDataOsTitle>
                    <ShowMoreOsDataOsMain>
                        <ShowMoreOsDataOsDescriptionContainer>
                            {osNumberActual?.id !== "-1" ? (
                                <ShowMoreOsDataOsDescriptionTextareaLabel
                                    htmlFor={"description"}
                                >
                                    Descrição
                                </ShowMoreOsDataOsDescriptionTextareaLabel>
                            ) : (
                                <Skeleton width={148} height={22} />
                            )}
                            {osNumberActual?.id !== "-1" ? (
                                <ShowMoreOsDataOsDescriptionTextarea
                                    disabled={
                                        editable === -1 &&
                                        edit &&
                                        greenBackground === ""
                                            ? false
                                            : true
                                    }
                                    id={"description"}
                                    maxLength={500}
                                    onChange={(e) => {
                                        setUpdateData(
                                            (old) =>
                                                (old = {
                                                    ...old,
                                                    descricao: e.target.value,
                                                })
                                        );
                                    }}
                                    value={updateData.descricao}
                                />
                            ) : (
                                <Skeleton width={250} height={400} />
                            )}
                            <ShowMoreOsIconContainer
                                className={`edit${
                                    editable === -1 && edit ? " clean" : ""
                                } description${
                                    osNumberActual?.id === "-1" ? " hidden" : ""
                                }`}
                                onClick={() => {
                                    ActiveChanges(-1);
                                }}
                            >
                                <ShowMoreOsEditIcon src={Edit} />
                                <ShowMoreOsHoverEdit className={"edit-hover"}>
                                    Editar
                                    <ShowMoreOsHoverEditPart
                                        className={"edit-part"}
                                    />
                                </ShowMoreOsHoverEdit>
                            </ShowMoreOsIconContainer>
                            <ShowMoreOsIconContainer
                                className={`clean-initial${
                                    editable === -1 && edit ? " clean" : ""
                                } ${
                                    greenBackground !== ""
                                        ? greenBackground
                                        : ""
                                }
                                    ${greenBackground !== "" ? " disable" : ""}
                                    description`}
                                onClick={() => {
                                    DelayChanges(-1);
                                    setEditable(-1);
                                    Animation();
                                    handleUpdateOs();
                                }}
                            >
                                <ShowMoreOsEditIcon
                                    className={`${
                                        greenBackground !== ""
                                            ? greenBackground
                                            : ""
                                    }`}
                                    src={Clean}
                                />
                                <ShowMoreOsEditIcon
                                    className={`${
                                        editable === -1 && edit ? " clean" : ""
                                    } ${
                                        greenBackground !== ""
                                            ? greenBackground
                                            : ""
                                    }`}
                                    src={CleanWhite}
                                />
                                <ShowMoreOsHoverEdit
                                    className={`edit-hover${
                                        greenBackground !== "" ? " disable" : ""
                                    }`}
                                >
                                    Confirmar
                                    <ShowMoreOsHoverEditPart
                                        className={`edit-part${
                                            greenBackground !== ""
                                                ? " disable"
                                                : ""
                                        }`}
                                    />
                                </ShowMoreOsHoverEdit>
                            </ShowMoreOsIconContainer>
                        </ShowMoreOsDataOsDescriptionContainer>
                        <ShowMoreOsDataOsInputsContainer>
                            <ShowMoreOsInputsRowContainer>
                                <ShowMoreOsSelectContainer>
                                    {osNumberActual?.id !== "-1" ? (
                                        <ShowMoreOsSelectLabel>
                                            Local
                                        </ShowMoreOsSelectLabel>
                                    ) : (
                                        <Skeleton height={20} width={108} />
                                    )}
                                    {osNumberActual?.id !== "-1" ? (
                                        <ShowMoreOsSelect
                                            values={local?.map(
                                                ({
                                                    nome: label,
                                                    id: value,
                                                }) => ({
                                                    label,
                                                    value,
                                                })
                                            )}
                                            placeHolder={
                                                osNumberActual?.local.nome
                                            }
                                            setState={(value: string) => {
                                                setUpdateData(
                                                    (old) =>
                                                        (old = {
                                                            ...old,
                                                            id_local: value,
                                                        })
                                                );
                                                handleUpdateOs();
                                            }}
                                        />
                                    ) : (
                                        <Skeleton
                                            responsive={true}
                                            height={48}
                                            width={100}
                                        />
                                    )}
                                </ShowMoreOsSelectContainer>
                                {osNumberActual?.id !== "-1" ? (
                                    Inputs.slice(1, 2).map((item, key) => {
                                        return (
                                            <TrackOsInput
                                                key={key}
                                                iconClass={
                                                    editable === item.id - 1 &&
                                                    edit
                                                        ? " clean"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    ActiveChanges(key + 1);
                                                }}
                                                onChange={(e: any) => {
                                                    setUpdateData(
                                                        (old) =>
                                                            (old = {
                                                                ...old,
                                                                nome_solicitante:
                                                                    e.target
                                                                        .value,
                                                            })
                                                    );
                                                }}
                                                value={
                                                    updateData.nome_solicitante
                                                }
                                                animation={
                                                    greenBackground !== ""
                                                        ? greenBackground
                                                        : ""
                                                }
                                                hideIcon={
                                                    item.labelContent.slice(
                                                        0,
                                                        3
                                                    ) === "Dat"
                                                        ? " hidden"
                                                        : ""
                                                }
                                                onClickConfirm={() => {
                                                    DelayChanges(key + 1);
                                                    Animation();
                                                    handleUpdateOs();
                                                }}
                                                state={editable}
                                                editable={
                                                    editable === item.id - 1 &&
                                                    disabled
                                                        ? true
                                                        : false
                                                }
                                                inputClass={
                                                    item.labelContent.replaceAll(
                                                        " ",
                                                        ""
                                                    ) === "Solicitante"
                                                        ? ""
                                                        : item.labelContent.replaceAll(
                                                              " ",
                                                              ""
                                                          )
                                                }
                                                inputContent={item.inputContent}
                                                inputType={item.inputType}
                                                labelContent={item.labelContent}
                                            />
                                        );
                                    })
                                ) : (
                                    <InputSkeleton
                                        responsive={true}
                                        height={90}
                                        width={33}
                                    />
                                )}
                                <ShowMoreOsSelectContainer>
                                    {osNumberActual?.id !== "-1" ? (
                                        <ShowMoreOsSelectLabel>
                                            Prioridade
                                        </ShowMoreOsSelectLabel>
                                    ) : (
                                        <Skeleton height={20} width={108} />
                                    )}
                                    {osNumberActual?.id !== "-1" ? (
                                        <ShowMoreOsSelect
                                            values={PriorityValues}
                                            placeHolder={priorityFilter(
                                                osNumberActual?.prioridade
                                            )}
                                            height={"108"}
                                            setState={(value: string) => {
                                                setUpdateData(
                                                    (old) =>
                                                        (old = {
                                                            ...old,
                                                            prioridade: value,
                                                        })
                                                );
                                                handleUpdateOs();
                                            }}
                                        />
                                    ) : (
                                        <Skeleton
                                            responsive={true}
                                            height={48}
                                            width={100}
                                        />
                                    )}
                                </ShowMoreOsSelectContainer>
                            </ShowMoreOsInputsRowContainer>
                            <ShowMoreOsInputsRowContainer>
                                <ShowMoreOsSelectContainer>
                                    {osNumberActual?.id !== "-1" ? (
                                        <ShowMoreOsSelectLabel>
                                            Tipo
                                        </ShowMoreOsSelectLabel>
                                    ) : (
                                        <Skeleton height={20} width={108} />
                                    )}
                                    {osNumberActual?.id !== "-1" ? (
                                        <ShowMoreOsSelect
                                            values={types.map(
                                                ({
                                                    tipo: label,
                                                    id: value,
                                                }) => ({
                                                    value,
                                                    label,
                                                })
                                            )}
                                            placeHolder={
                                                osNumberActual?.tipo === null
                                                    ? "Indefinido"
                                                    : String(
                                                          osNumberActual?.tipo
                                                      )
                                            }
                                            height={"78"}
                                            setState={(value: string) => {
                                                setUpdateData(
                                                    (old) =>
                                                        (old = {
                                                            ...old,
                                                            id_tipo: value,
                                                        })
                                                );
                                                handleUpdateOs();
                                            }}
                                        />
                                    ) : (
                                        <InputSkeleton
                                            responsive={true}
                                            marginTop={-24}
                                            height={90}
                                            width={100}
                                        />
                                    )}
                                </ShowMoreOsSelectContainer>
                                {osNumberActual?.id !== "-1" ? (
                                    Inputs.slice(5, 7).map((item, key) => {
                                        return (
                                            <TrackOsInput
                                                key={key}
                                                iconClass={
                                                    editable === item.id - 1 &&
                                                    edit
                                                        ? " clean"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    ActiveChanges(key + 6);
                                                }}
                                                onChange={(e: any) => {
                                                    if (
                                                        item.labelContent ===
                                                        "Solicitante"
                                                    ) {
                                                        setUpdateData(
                                                            (old) =>
                                                                (old = {
                                                                    ...old,
                                                                    nome_solicitante:
                                                                        e.target
                                                                            .value,
                                                                })
                                                        );
                                                    }
                                                    if (
                                                        item.labelContent ===
                                                        "Tempo estimado"
                                                    ) {
                                                        setUpdateData(
                                                            (old) =>
                                                                (old = {
                                                                    ...old,
                                                                    tempo_estimado:
                                                                        e.target
                                                                            .value,
                                                                })
                                                        );
                                                    }
                                                }}
                                                value={
                                                    item.labelContent ===
                                                        "Data de abertura" &&
                                                    item.inputContent?.length <
                                                        30
                                                        ? `${item?.inputContent
                                                              ?.split("-")[2]
                                                              ?.slice(0, 2)}/${
                                                              item?.inputContent?.split(
                                                                  "-"
                                                              )[1]
                                                          }/${
                                                              item?.inputContent?.split(
                                                                  "-"
                                                              )[0]
                                                          }`
                                                        : item.value
                                                }
                                                animation={
                                                    greenBackground !== ""
                                                        ? greenBackground
                                                        : ""
                                                }
                                                hideIcon={
                                                    item.labelContent?.slice(
                                                        0,
                                                        3
                                                    ) === "Dat"
                                                        ? " hidden"
                                                        : ""
                                                }
                                                onClickConfirm={() => {
                                                    DelayChanges(key + 6);
                                                    Animation();
                                                    handleUpdateOs();
                                                }}
                                                state={editable}
                                                editable={
                                                    editable === item.id - 1 &&
                                                    disabled
                                                        ? true
                                                        : false
                                                }
                                                inputClass={
                                                    item.labelContent?.replaceAll(
                                                        " ",
                                                        ""
                                                    ) === "Tempoestimado"
                                                        ? ""
                                                        : item.labelContent?.replaceAll(
                                                              " ",
                                                              ""
                                                          )
                                                }
                                                inputContent={item.inputContent}
                                                inputType={item.inputType}
                                                labelContent={item.labelContent}
                                            />
                                        );
                                    })
                                ) : (
                                    <InputSkeleton
                                        responsive={true}
                                        height={90}
                                        width={33}
                                    />
                                )}
                                {osNumberActual?.id !== "-1" ? (
                                    Inputs?.map((item, key) => {
                                        return (
                                            <TrackOsInput
                                                key={key}
                                                iconClass={
                                                    editable === item.id - 1 &&
                                                    edit
                                                        ? " clean"
                                                        : ""
                                                }
                                                onClick={() => {
                                                    ActiveChanges(key);
                                                }}
                                                onChange={(e: any) => {
                                                    if (
                                                        item.labelContent ===
                                                        "Solicitante"
                                                    ) {
                                                        setUpdateData(
                                                            (old) =>
                                                                (old = {
                                                                    ...old,
                                                                    nome_solicitante:
                                                                        e.target
                                                                            .value,
                                                                })
                                                        );
                                                    }
                                                    if (
                                                        item.labelContent ===
                                                        "Tempo estimado"
                                                    ) {
                                                        setUpdateData(
                                                            (old) =>
                                                                (old = {
                                                                    ...old,
                                                                    tempo_estimado:
                                                                        e.target
                                                                            .value,
                                                                })
                                                        );
                                                    }
                                                }}
                                                value={
                                                    item.labelContent ===
                                                        "Data de abertura" &&
                                                    item.inputContent?.length <
                                                        30
                                                        ? `${item?.inputContent
                                                              ?.split("-")[2]
                                                              ?.slice(0, 2)}/${
                                                              item?.inputContent?.split(
                                                                  "-"
                                                              )[1]
                                                          }/${
                                                              item?.inputContent?.split(
                                                                  "-"
                                                              )[0]
                                                          }`
                                                        : item.value
                                                }
                                                animation={
                                                    greenBackground !== ""
                                                        ? greenBackground
                                                        : ""
                                                }
                                                hideIcon={
                                                    item.labelContent.slice(
                                                        0,
                                                        3
                                                    ) === "Dat"
                                                        ? " hidden"
                                                        : ""
                                                }
                                                onClickConfirm={() => {
                                                    DelayChanges(key);
                                                    Animation();
                                                }}
                                                state={editable}
                                                editable={
                                                    editable === item.id - 1 &&
                                                    disabled
                                                        ? true
                                                        : false
                                                }
                                                inputClass={
                                                    item.labelContent.replaceAll(
                                                        " ",
                                                        ""
                                                    ) === "Datadeabertura"
                                                        ? ""
                                                        : item.labelContent.replaceAll(
                                                              " ",
                                                              ""
                                                          )
                                                }
                                                inputContent={item.inputContent}
                                                inputType={item.inputType}
                                                labelContent={item.labelContent}
                                            />
                                        );
                                    })
                                ) : (
                                    <InputSkeleton
                                        responsive={true}
                                        height={90}
                                        width={33}
                                    />
                                )}
                            </ShowMoreOsInputsRowContainer>
                            <DeleteModal
                                blurState={deleteModal.state}
                                deleteAction={() => {
                                    handleDeleteColab(
                                        osNumberActual.id,
                                        deleteModal.value
                                    );
                                }}
                                blurOnClick={() => {
                                    setDeleteModal(
                                        (old) =>
                                            (old = {
                                                state: false,
                                                target: old.target,
                                                value: old.value,
                                            })
                                    );
                                }}
                                frameContent={"colaboradores"}
                                target={deleteModal.target}
                                targetTitle={"colaborador"}
                            />
                            <Modal
                                blurOnClick={() => {
                                    setShowModal(false);
                                    setTimeout(() => {setColabState('-1')},300);
                                }}
                                blurState={showModal}
                            >
                                <ShowMoreOsModalContainer>
                                    <ShowMoreOsModalTitle>
                                        Adicionar colaborador a OS
                                    </ShowMoreOsModalTitle>
                                    <ShowMoreOsModalSelectContainer>
                                        <ShowMoreOsModalSelectLabel>
                                            Colaborador
                                        </ShowMoreOsModalSelectLabel>
                                        <ShowMoreOsSelect
                                            colabs={true}
                                            placeHolder={
                                                "Ex: Thiago da Silva Nascimento - Eletricista"
                                            }
                                            state={colabState}
                                            values={colabs.rows.map((item) => {
                                                if (
                                                    osNumberActual.encarregados.filter(
                                                        (colab) => {
                                                            return (
                                                                colab.id ===
                                                                item.id
                                                            );
                                                        }
                                                    )[0]?.id === undefined
                                                ) {
                                                    return {
                                                        value: item.id,
                                                        label: `${item.nome} - ${item.grupos[0].grupo}`,
                                                    };
                                                }
                                                return { value: "", label: "" };
                                            })}
                                            setState={setColabState}
                                        />
                                    </ShowMoreOsModalSelectContainer>
                                    <ShowMoreOsModalEndContainer>
                                        <OpenOS
                                            clickFunct={() => {
                                                setShowModal(false);
                                            }}
                                            activeBgColor={"rgba(0, 0, 0, 0.1)"}
                                            hoverBgColor={"rgba(0, 0, 0, 0.05)"}
                                            Color={"rgba(0, 0, 0, 0.5);"}
                                            backgroundColor={"unset"}
                                            content={"Voltar"}
                                        />
                                        <OpenOS
                                            clickFunct={() => {
                                                setShowModal(false);
                                                handleAddColab(
                                                    osNumberActual?.id,
                                                    colabState    
                                                );
                                                setTimeout(() => {setColabState('-1')},300);
                                            }}
                                            activeBgColor={
                                                "var(--brand-primary-shade-100)"
                                            }
                                            hoverBgColor={
                                                "var(--brand-primary-shade-200)"
                                            }
                                            Color={"white"}
                                            backgroundColor={
                                                "var(--brand-primary-default)"
                                            }
                                            content={"Concluir"}
                                        />
                                    </ShowMoreOsModalEndContainer>
                                </ShowMoreOsModalContainer>
                            </Modal>
                        </ShowMoreOsDataOsInputsContainer>
                    </ShowMoreOsDataOsMain>
                </ShowMoreOsDataOsContainer>
                <ShowMoreOsEndContainer>
                    <ShowMoreOsNotesContainer>
                        <ShowMoreOsNotesTitle>Anotações</ShowMoreOsNotesTitle>
                        <ShowMoreOsCardsContainer>
                            {osNumberActual?.id !== "-1"
                                ? anotacoes?.map((anotacao) => {
                                      return (
                                          <ShowMoreOsNotesCards
                                              anotacao={anotacao}
                                          />
                                      );
                                  })
                                : NotesShimmer.map(() => {
                                      return (
                                          <NotesSkeleton height={0} width={0} />
                                      );
                                  })}
                        </ShowMoreOsCardsContainer>
                    </ShowMoreOsNotesContainer>
                    <ShowMoreOsInteractionsHistoryContainer>
                        <ShowMoreOsInteractionsHistoryTitle>
                            Histórico de Interações de OS
                        </ShowMoreOsInteractionsHistoryTitle>
                        <ShowMoreOsLogContainer>
                            <ShowMoreOsLogTopContainer
                                className={`${
                                    osNumberActual.id === "-1" ? "shimmer" : ""
                                }`}
                                style={{ justifyContent: "flex-end" }}
                            >
                                {osNumberActual.id !== "-1" ? (
                                    <ShowMoreOsTopRight>
                                        {log?.length} interações realizadas
                                    </ShowMoreOsTopRight>
                                ) : (
                                    <Skeleton width={180} height={18} />
                                )}
                            </ShowMoreOsLogTopContainer>
                            <ShowMoreOsMainContainer
                                className={`${
                                    osNumberActual.id === "-1" ? "gap" : ""
                                }`}
                            >
                                {osNumberActual.id !== "-1"
                                    ? log?.map((item, key) => {
                                          return (
                                              <LogCards
                                                  key={key}
                                                  action={item.message}
                                                  createdAt={item.created_at}
                                                  username={item.name}
                                                  value={item.value}
                                              />
                                          );
                                      })
                                    : LogShimmer.map(() => {
                                          return (
                                              <Skeleton
                                                  width={100}
                                                  height={28}
                                                  responsive={true}
                                              />
                                          );
                                      })}
                            </ShowMoreOsMainContainer>
                        </ShowMoreOsLogContainer>
                    </ShowMoreOsInteractionsHistoryContainer>
                </ShowMoreOsEndContainer>
                {toast.content !== "error" ? (
                    toast.toast === 0 || toast.toast === 1 ? (
                        <Toast
                            type={false}
                            title={'Colaborador adicionado com sucesso'}
                            setState={setToast}
                            state={toast.toast}
                            content={`O colaborador ${colabs.rows.filter((item) => {return item.id === colabState})[0]?.nome} a OS: ${osNumberActual?.numero}`}
                        />
                    ) : (
                        ""
                    )
                ) : (
                    <Toast
                        type={true}
                        title={"Erro"}
                        setState={setToast}
                        state={toast.toast}
                        content={"Falha ao adicionar colaborador"}
                    />
                )}
            </Container>
        </Layouts>
    );
};
