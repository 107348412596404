import React from "react";

import {
    ChatContent,
    ChatContentCard,
    ChatContentContainer,
    ChatTimerMessage,
    ChatUserIcon,
    ChatUsername,
    Container,
} from "./styles";

interface IChatCard {
    children: React.ReactNode;
    username: string;
    sended: boolean;
    timer?: string;
}

export const ChatCard: React.FC<IChatCard> = ({
    children,
    username,
    sended,
    timer,
}) => {
    return (
        <Container sended={sended}>
            {!sended && <ChatUserIcon>{username.slice(0, 1)}</ChatUserIcon>}
            <ChatContentContainer>
                {!sended && (
                    <ChatUsername>
                        {`${username.split(" ")[0].slice(0, 1)}${username
                            .split(" ")[0]
                            .slice(1, username.length)
                            .toLowerCase()} ${
                            String(username.split(" ")[1]) === "undefined"
                                ? ""
                                : username.split(" ")[1].slice(0, 1)
                        }${String(username
                            .split(" ")[1])
                            === 'undefined'?
                            ''
                            :username
                            .split(" ")[1]
                            .slice(1, username.length)
                            .toLowerCase()}`}
                    </ChatUsername>
                )}
                <ChatContentCard>
                    <ChatContent>{children}</ChatContent>
                </ChatContentCard>
                <ChatTimerMessage>
                    {`${timer?.split("-")[2].slice(0, 2).trim().toString()}/${
                        timer?.split("-")[1]
                    }/${timer?.split("-")[0]}`}
                </ChatTimerMessage>
            </ChatContentContainer>
        </Container>
    );
};
