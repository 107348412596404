import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 32rem;
    margin-top: 1.5rem;
    min-height: 142px;
`;

export const NotesCardIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #4285F4;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 1px;
    margin-right: 1rem;
`;

export const NotesCardContentContainer = styled.div`
    border: 0.5px solid #CCCCCC;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    min-width: 464px;
    padding: 0.5rem;
`;

export const NotesCardTopContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const NotesCardTopLeftContainer = styled.div`
    display: flex;

`;

export const NotesCardTopUsername = styled.span`
    font-weight: 500;
    font-size: 0.75rem;
    color: #4285F4;
    margin-right: 1rem;
`;

export const NotesCardTopInfo = styled.span`
    font-weight: 400;
    font-size: 0.75rem;
    color: #CCCCCC;
`;

export const NotesCardDateContent = styled.span`
    font-weight: 400;
    font-size: 0.75rem;
    color: #CCCCCC;
`;

export const NotesCardMainContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
`;

export const NotesCardMainContent = styled.span`
    font-weight: 400;
    color: #333332;
`;