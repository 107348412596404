import React, { useContext } from "react";
import { PrioritySelect } from "../../components/PriorityButton";
import { SearchInput } from "../../components/SearchInput";
import { ServiceOrderNav } from "../../components/ServiceOrderNav";
import { Skeleton } from "../../components/Skeleton";
import { TrackOsCard } from "../../components/TrackOsCard";
import { LogContext } from "../../contexts/ChangeLog";
import { useServiceOrder } from "../../contexts/ServiceOrder";
import { Find } from "../../interface/find.interface";
import Layout from "../../layouts/Main";
import {
    Container,
    TrackServiceOrderMain,
    TrackServiceOrderMainCenter,
    TrackServiceOrderMainTop,
    TrackServiceOrderSubTitle,
    TrackServiceOrderTitle,
    TrackServiceOrderTopLeft,
} from "./styles";

const TrackServiceOrder = () => {
    const { getOsProgress, osProgress, setOsNumberActual, osNumberActual } = useServiceOrder();
    const { getLog } = useContext(LogContext);

    const [priorityValue, setPriorityValue] = React.useState("");
    const [statusValue, setStatusValue] = React.useState("");
    const [searchValue, setSearchValue] = React.useState("");
    const [pageValue, setPageValue] = React.useState(0);

    const [filter, setFilter] = React.useState<Find>({
        page: 0,
        search: "",
        grupos: [],
        status: "",
        prioridade: "",
    });
    let newFind = {
        page: pageValue,
        search: searchValue,
        grupos: [],
        status: statusValue,
        prioridade: priorityValue,
    };

    React.useEffect(() => {},[osProgress])

    React.useEffect(() => {
        getOsProgress(newFind)
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [filter])
  
    const Priority = [
        {
            id: 0,
            icon: "red",
            label: "Alta",
            iconColor: "",
            value: "3",
        },
        {
            id: 1,
            icon: "orange",
            label: "Média",
            iconColor: "",
            value: "2",
        },
        {
            id: 2,
            icon: "blue",
            label: "Baixa",
            iconColor: "",
            value: "1",
        },
    ];
    const AllStatus = [
        {
            id: 0,
            icon: "red",
            label: "Em andamento",
            iconColor: "#4285F4",
            moveColor: "#444053",
            value: "1",
        },
        {
            id: 1,
            icon: "orange",
            label: "Pausada",
            iconColor: "#444053",
            value: "2",
        },
        {
            id: 2,
            icon: "blue",
            label: "Aberta",
            iconColor: "#28A138",
            value: "0",
        },
    ];
    const ColOptions = [
        {
            content: "Encarregados",
        },
        {
            content: "Número",
        },
        {
            content: "Prioridade",
        },
        {
            content: "Status",
        },
    ];
    const Shimmer = [
        {},
        {},
        {},
        {},
        {}
    ]
    return (
        <Layout>
            <Container>
                <TrackServiceOrderTitle>
                    Acompanhar ordens de serviço
                </TrackServiceOrderTitle>
                <TrackServiceOrderSubTitle>
                    Detalhes da ordem de serviço
                </TrackServiceOrderSubTitle>
                <TrackServiceOrderMain>
                    <TrackServiceOrderMainTop>
                        <TrackServiceOrderTopLeft>
                            <PrioritySelect
                                changeFunct={() => {
                                    setFilter((old) => (old = newFind));
                                }}
                                setValue={setPriorityValue}
                                top={"0"}
                                left={"0"}
                                values={Priority}
                                placeholder={"Todas as prioridades"}
                            />
                            <PrioritySelect
                                changeFunct={() => {
                                    setFilter((old) => (old = newFind));
                                }}
                                setValue={setStatusValue}
                                top={"0"}
                                left={"234px"}
                                values={AllStatus}
                                placeholder={"Todos os status"}
                            />
                        </TrackServiceOrderTopLeft>
                        <SearchInput
                            value={searchValue}
                            onChange={(event) => {
                                setSearchValue(event.target.value);
                                setPageValue(0)
                                setFilter((old) => (old = newFind));
                            }}
                            required
                            placeholder={
                                "Digite o código da ordem de serviço"
                            }
                        />
                    </TrackServiceOrderMainTop>
                    <ServiceOrderNav
                        state={newFind}
                        setState={setFilter}
                        setPage={setPageValue}
                        page={pageValue}
                        total={osProgress.total}
                        osClass={"os"}
                        values={ColOptions}
                    />
                    <TrackServiceOrderMainCenter className={`${osProgress.total === -1? 'shimmer':''}`}>
                        {osProgress.total !== -1 ? 
                            osProgress?.rows?.map((os) => {
                                return (
                                    <TrackOsCard
                                        onClick={() => {
                                            getLog(os.id);
                                            if(osNumberActual !== os){
                                                setOsNumberActual(os);
                                            }
                                        }}
                                        status={os.status}
                                        priority={os.prioridade}
                                        username={os.encarregados?.filter?.((item) => {return item.responsavel === true})[0]?.nome === undefined? os.encarregados[0]?.nome:os.encarregados?.filter?.((item) => {return item.responsavel === true})[0]?.nome}
                                        osNumber={os.numero}
                                        buttonPath={`/show-more-track${pageValue !== 0? '/' + pageValue:'/0'}/${os.id}`}
                                        key={os.id}
                                    />
                                );
                            })
                         : Shimmer.map(() => {return <Skeleton responsive={true} height={42} width={100}/>})
                        }
                    </TrackServiceOrderMainCenter>
                </TrackServiceOrderMain>
            </Container>
        </Layout>
    );
};

export default TrackServiceOrder;
