import React from "react";
import { PrioritySelect } from "../../components/PriorityButton";
import { SearchInput } from "../../components/SearchInput";
import { ServiceOrderNav } from "../../components/ServiceOrderNav";
import { Skeleton } from "../../components/Skeleton";
import { TrackOsCard } from "../../components/TrackOsCard";
import { useServiceOrder } from "../../contexts/ServiceOrder";
import { Find } from "../../interface/find.interface";
import Layout from "../../layouts/Main";
import {
    Container,
    OsHistoryMain,
    OsHistoryMainCenter,
    OsHistoryMainTop,
    OsHistorySubTitle,
    OsHistoryTitle,
    OsHistoryTopLeft,
} from "./styles";

const OsHistory = () => {
    const { osFinished, getOsFinished, setOsNumberActual } = useServiceOrder();

    const [priorityValue, setPriorityValue] = React.useState("");
    const [statusValue, setStatusValue] = React.useState("");
    const [searchValue, setSearchValue] = React.useState("");
    const [pageValue, setPageValue] = React.useState(0);

    const [filter, setFilter] = React.useState<Find>({
        page: 0,
        search: "",
        grupos: [],
        status: "",
        prioridade: "",
    });

    let newFind = {
        page: pageValue,
        search: searchValue,
        grupos: [],
        status: statusValue,
        prioridade: priorityValue,
    };

    React.useEffect(() => {
        getOsFinished(newFind)
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [filter])
     React.useEffect(() => {},[osFinished])

    const Priority = [
        {
            id: 0,
            icon: "red",
            label: "Alta",
            iconColor: "",
            moveColor: "#FF0000",
            value: "3",
        },
        {
            id: 1,
            icon: "orange",
            label: "Média",
            iconColor: "",
            moveColor: "#F9971E",
            value: "2",
        },
        {
            id: 2,
            icon: "blue",
            label: "Baixa",
            iconColor: "",
            moveColor: "#4285F4",
            value: "1",
        },
    ];
    const AllStatus = [
        {
            id: 0,
            icon: "red",
            label: "Concluida",
            iconColor: "#28A138",
            moveColor: "#623CEA",
            value: "3",
        },
        {
            id: 1,
            icon: "orange",
            label: "Arquivada",
            iconColor: "#623CEA",
            value: "4",
        },
    ];
    const ColOptions = [
        {
            content: "Encarregados",
        },
        {
            content: "Número",
        },
        {
            content: "Prioridade",
        },
        {
            content: "Status",
        },
    ];
    const Shimmer = [
        {},
        {},
        {},
        {},
        {}
    ]

    return (
        <Layout>
            <Container>
                <OsHistoryTitle>Histórico de ordens de serviço</OsHistoryTitle>
                <OsHistorySubTitle>
                    Detalhes do histórico de ordens de serviço
                </OsHistorySubTitle>
                <OsHistoryMain>
                    <OsHistoryMainTop>
                        <OsHistoryTopLeft>
                            <PrioritySelect
                                changeFunct={() => {
                                    setFilter((old) => (old = newFind));
                                }}
                                setValue={setPriorityValue}
                                top={"0"}
                                left={"0"}
                                values={Priority}
                                placeholder={"Todas as prioridades"}
                            />
                            <PrioritySelect
                                changeFunct={() => {
                                    setFilter((old) => (old = newFind));
                                }}
                                setValue={setStatusValue}
                                top={"0"}
                                left={"14.625rem"}
                                values={AllStatus}
                                placeholder={"Todos os status"}
                            />
                        </OsHistoryTopLeft>
                        <SearchInput
                            placeholder={
                                "Digite o código da ordem de serviço"
                            }
                            required
                            value={searchValue}
                            onChange={(event) => {
                                setSearchValue(event.target.value);
                                newFind.page = 0;
                                setFilter(newFind);
                            }}
                        />
                    </OsHistoryMainTop>
                    <ServiceOrderNav
                        state={newFind}
                        setState={setFilter}
                        setPage={setPageValue}
                        page={pageValue}
                        total={osFinished.total}
                        osClass={"os"}
                        values={ColOptions}
                    />
                    <OsHistoryMainCenter className={`${osFinished.total === -1? 'shimmer':''}`}>
                        {osFinished.total !== -1? (
                            osFinished.rows.map((os) => {
                                return (
                                    <TrackOsCard
                                        key={os.id}
                                        priority={os.prioridade}
                                        status={os.status}
                                        username={os.encarregados.filter((item) => {return item.responsavel === true})[0].nome}
                                        osNumber={os.numero}
                                        onClick={() => {
                                            setOsNumberActual(os);
                                        }}
                                        buttonPath={`/show-more-history/${pageValue}/${os.id}`}
                                    />
                                );
                            })
                        ) : (
                            Shimmer.map(() => {return <Skeleton height={42} width={1200}/>})
                        )}
                    </OsHistoryMainCenter>
                </OsHistoryMain>
            </Container>
        </Layout>
    );
};

export default OsHistory;
