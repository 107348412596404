import React from 'react';
import { Container, SearchIcon } from './styles';
import Search from '../../assets/icons/Track-Service-Order/Search.svg'

type InputProps = JSX.IntrinsicElements['input'];

export const SearchInput: React.FC<InputProps> = ({...rest}) => {
    return (
        <Container>
            <input className={'input'} {...rest}/>
            <SearchIcon src={Search} />
        </Container>
    );
}
