import React, { useState } from "react";
import { IconNavDivisorBar } from "../AsideMenu";
import {
    Header,
    HeaderUserOption,
    HeaderUserOptionContainer,
    HeaderUserOptionContent,
    HeaderUserOptionModal,
    HeaderUserOptionModalOptions,
    HeaderUserOptionModalOptionsContent,
    HeaderUserOptionNotificationContainer,
    NotificationModal,
    HeaderUserOptionUserIcon,
    HeaderUserOptionUsername,
    HeaderUserOptionUserOffice,
    NotificationModalTitle,
    NotificationModalMarkAsReadContainer,
    NotificationModalItemsContainer,
    NotificationModalItemStartContainer,
    NotificationModalItemIcon,
    NotificationModalItemContentContainer,
    NotificationModalItemTitle,
    NotificationModalItemContent,
    NotificationModalItemContentId,
    NotificationModalItems,
    NotificationModalItemEndContainer,
    NotificationModalItemTime,
    NotificationModalItemStatusIcon,
    NotificationModalTopContainer,
    HeaderUserOptionNotificationStateIcon,
    HeaderUserOptionNotificationIcon,
    NotificationModalItemStatusContainer,
    NotificationModalTrashIcon,
} from "./styles";
import Arrow from "../../assets/icons/users-icon/Arrow.svg";
import Bell from "../../assets/icons/dashboard-menu/Bell.svg";
import IconContainer from "../IconContainer";
import Trash from "../../assets/icons/dashboard-menu/NotificationTrash.svg";
import { OpenOSModal } from "../Open-OS-Modal";
import { OpenOS } from "../OpenOS";
import { useHistory } from "react-router";
import { useAuth } from "../../contexts/AuthContext";
import { INotificacaoes, useNotificacao } from "../../contexts/notificacao.contex";
import { IUser } from "../../interface/user.interface";
import { notificacaoService } from "../../services/notificacao.service";

interface IHeader {
    content?: string;
    backgroundColor?: string;
    Color?: string;
    hoverBgColor?: string;
    activeBgColor?: string;
    Height?: string;
}

const inicialState = {
    rows: [],
    total: 0,
}

export const HeaderDashboard = (props: IHeader) => {
    const auth = useAuth();
    const { notificacoes, confirma } = useNotificacao();

    const history = useHistory();

    const [notifications, setNotifications] = React.useState<INotificacaoes>(inicialState)
    const [osModal, setOsModal] = useState(false);
    const [userOptionModal, setUserOptionModal] = useState(false);
    const [notificationModal, setNotificationModal] = useState(false);
    const [notificationModalFocused, setNotificationModalFocused] =
        useState(false);
    const [deleteNotification, setDeleteNotification] = React.useState({
        state: false,
        id: "",
        key: -1,
    });

    const Icons = [
        {
            img: Arrow,
            description: "option-Icon",
        },
        {
            img: Bell,
            description: "notification-Icon",
        },
    ];
    const OptionsUserOption = [
        {
            id: 0,
            content: "Perfil",
        },
        {
            id: 1,
            content: "Sair",
        },
    ];
    const BlurNotificationIcon = () => {
        let state = notificationModalFocused;
        if (!state) {
            setNotificationModal(false);
        }
    };
    const IconLetter = (key: number) => {
        return notifications.rows[key].profissional.nome
            .slice(0, 1)
            .toLocaleUpperCase();
    };

    const fetchNotifications = React.useCallback(async() => {
        notificacaoService.find({}).then((res) => {setNotifications(res.data)}).catch((err) => {})
    },[])

    React.useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications, notificacoes]);

    return (
        <Header>
            <OpenOS
                clickFunct={() => {
                    setOsModal(true);
                }}
                Height={props.Height}
                activeBgColor={props.activeBgColor}
                hoverBgColor={props.hoverBgColor}
                Color={props.Color}
                className={'header'}
                content={props.content}
                backgroundColor={props.backgroundColor}
                id="open-os-button"
            />
            <OpenOSModal
                ClickFunct={() => {
                    setOsModal(false);
                }}
                blurState={osModal}
            />
            <HeaderUserOption>
                <HeaderUserOptionContainer
                    tabIndex={1}
                    onBlur={() => {
                        if (userOptionModal) {
                            setUserOptionModal(false);
                        }
                    }}
                    onClick={() => {
                        setUserOptionModal((old) => !old);
                    }}
                >
                    <HeaderUserOptionContent>
                        <HeaderUserOptionUsername>
                            {auth.user?.nome}
                        </HeaderUserOptionUsername>
                        <HeaderUserOptionUserOffice>
                            Administrador
                        </HeaderUserOptionUserOffice>
                    </HeaderUserOptionContent>
                    <HeaderUserOptionModal
                        className={`${userOptionModal ? "show" : ""}`}
                    >
                        {OptionsUserOption.map((item, key) => {
                            return (
                                <HeaderUserOptionModalOptions
                                    key={key}
                                    onClick={() => {
                                        if (item.content === "Perfil") {
                                            history.push("/profile");
                                            return;
                                        }else {
                                            auth.setUser({} as IUser);
                                            auth.setToken('');
                                        }
                                    }}
                                >
                                    <HeaderUserOptionModalOptionsContent>
                                        {item.content}
                                    </HeaderUserOptionModalOptionsContent>
                                </HeaderUserOptionModalOptions>
                            );
                        })}
                    </HeaderUserOptionModal>
                    <HeaderUserOptionUserIcon>{String(auth.user?.nome) !== 'undefined'? auth.user?.nome.slice(0, 1):''}</HeaderUserOptionUserIcon>
                    {Icons.slice(0, 1).map((item, key) => (
                        <IconContainer
                            img={item.img}
                            description={item.description}
                            key={key}
                        />
                    ))}
                </HeaderUserOptionContainer>
                <IconNavDivisorBar class="user-option" />
                <HeaderUserOptionNotificationContainer
                    tabIndex={1}
                    onBlur={() => {
                        BlurNotificationIcon();
                    }}
                    onClick={() => {
                        setNotificationModal((old) => !old);
                        setNotificationModalFocused(false);
                    }}
                >
                    <HeaderUserOptionNotificationIcon src={Bell} />
                    <HeaderUserOptionNotificationStateIcon className={`${notificacoes.total > 0? 'unread':''}`}/>
                </HeaderUserOptionNotificationContainer>
                <NotificationModal
                    tabIndex={1}
                    onMouseEnter={() => {
                        setNotificationModalFocused(true);
                    }}
                    onMouseLeave={() => {
                        setNotificationModalFocused(false);
                    }}
                    onClick={() => {
                        setNotificationModalFocused(true);
                    }}
                    onBlur={() => {
                        setNotificationModalFocused(false);
                        setNotificationModal(false);
                    }}
                    className={`${notificationModal ? "" : "hidden"}`}
                >
                    <NotificationModalTopContainer>
                        <NotificationModalTitle>
                            Notificações
                        </NotificationModalTitle>
                        <NotificationModalMarkAsReadContainer
                            onClick={() => {
                                confirma(notificacoes.rows.map((item) => {return item.id}))
                            }}
                        >
                                Marcar todas como lidas
                        </NotificationModalMarkAsReadContainer>
                    </NotificationModalTopContainer>
                    <NotificationModalItemsContainer
                        className={`${deleteNotification ? "" : ""}${
                            deleteNotification.key !== -1
                                ? " spacing"
                                : ""
                        }`}
                    >
                        {notifications.rows.map((item, key) => {
                            return (
                                <NotificationModalItems
                                    className={`${
                                        deleteNotification.state &&
                                        deleteNotification.id === item.id
                                            ? "right"
                                            : ""
                                    }${
                                        deleteNotification.key === key &&
                                        deleteNotification.id !== item.id
                                            ? " spacing"
                                            : ""
                                    }`}
                                    key={key}
                                >
                                    <NotificationModalItemStartContainer>
                                        <NotificationModalItemIcon>
                                            {String(IconLetter(key))}
                                        </NotificationModalItemIcon>
                                        <NotificationModalItemContentContainer>
                                            <NotificationModalItemTitle>
                                                {item.titulo}
                                            </NotificationModalItemTitle>
                                            <NotificationModalItemContent>
                                                {item.message}
                                                <NotificationModalItemContentId></NotificationModalItemContentId>
                                            </NotificationModalItemContent>
                                        </NotificationModalItemContentContainer>
                                    </NotificationModalItemStartContainer>
                                    <NotificationModalItemEndContainer>
                                        <NotificationModalItemTime>{`${item.created_at
                                            .toString()
                                            .split("-")[2]
                                            .slice(0, 2)}/${
                                            item.created_at
                                                .toString()
                                                .split("-")[1]
                                        }/${
                                            item.created_at
                                                .toString()
                                                .split("-")[0]
                                        }`}</NotificationModalItemTime>
                                        <NotificationModalItemStatusContainer>
                                            <NotificationModalItemStatusIcon
                                                className={`${item.active === true? 'unread':''}`}
                                            />
                                            <NotificationModalTrashIcon
                                                onClick={() => {
                                                    setDeleteNotification({
                                                        state: true,
                                                        id: item.id,
                                                        key: key + 1,
                                                    });
                                                    setTimeout(() => {
                                                        confirma([item.id]);
                                                    }, 100);
                                                    setTimeout(() => {
                                                        setDeleteNotification(
                                                            (old) =>
                                                                (old = {
                                                                    state: old.state,
                                                                    id: old.id,
                                                                    key: key,
                                                                })
                                                        );
                                                    }, 200);
                                                }}
                                                src={Trash}
                                            />
                                        </NotificationModalItemStatusContainer>
                                    </NotificationModalItemEndContainer>
                                </NotificationModalItems>
                            );
                        })}
                    </NotificationModalItemsContainer>
                </NotificationModal>
            </HeaderUserOption>
        </Header>
    );
};
