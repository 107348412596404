import React from "react";

import { Container, Input, Label } from "./styles";

interface IInput {
  autoFocus?: boolean;
  placeHolder?: string;
  onChange?: (value: string) => void;
  maxLength?: number;
  label?: string;
  value?: string;
  type: string;
  setValue?: any;
  id?: string;
}

const _InputText: React.ForwardRefRenderFunction<HTMLInputElement, IInput> = (
  props,
  ref
) => {
  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.type === 'number') {
      let change = e.target.value.replace(/[A-Za-zá-źÁ-ŹǸẼỸ ẽỹṽṼẐ±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©ẀÀàḿṕḱǘǵḉÁḾṔḰÚǴḈ&ǹǛ°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g, '')
      props.setValue(change)
      return;
    }
    if (props.type === 'timer') {
      let change = e.target.value.replace(/[A-Za-zá-źÁ-ŹǸẼỸ ẽỹṽṼẐ±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©ẀÀàḿṕḱǘǵḉÁḾṔḰÚǴḈ&ǹǛ°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g, '')
      if (change.length > 0 && change.length < 3) {
        props.setValue(change)
        return;
      }
      if(change.length === 3) {
				change = change.replace(/(\d{2})(\d{1})/g, "$1:$2")
        props.setValue(change)
        return;
      }
      if (props.value?.length === 11) {
        change = '';
        props.setValue('');
        return;
      }
      if (change.length >= 3 && change.length < 5) {
				change = change.replace(/(\d{2})(\d{2})/g, "$1:$2")
        props.setValue(change)
        return;
      }
      props.setValue(change)
      return;
    }
    if (props.type === 'text') {
      let change = e.target.value.replace(/[0-9±™⅜¼¾½¡Ỳ^»«¿̛‘‘⅞‘↑→←”“µ¥’®¨©^↑µ¥’®¨©&°°º̣·ª§¹²³£¢¬@/_\\;|´`#!\-=[.,+()$~%'":*?<>{}]/g, '')
      props.setValue(change)
      return;
    }
    props.setValue(e.target.value);
  }

  return (
    <Container>
      <Label label={props.label}>{props.label}</Label>
      <Input
        id={props.id}
        onChange={(e) => {
          setValue(e)
        }}
        value={props?.value}
        ref={ref}
        required
        autoFocus={props.autoFocus}
        placeholder={"Ex: " + props.placeHolder}
        maxLength={props?.maxLength}
      />
    </Container>
  );
};

export const InputText = React.forwardRef(_InputText);
