import { Doughnut } from 'react-chartjs-2';
import { ChartTitle, ChartContainer, ChartInfo } from './styles';

interface IDashboardChart {
  received?: number;
  finished?: number;
  children: React.ReactNode;
}


export const DashboardChart = (props:IDashboardChart) => {

  let received = props.received;
  let finished = props.finished;

  if(received === null && finished === null) {
    finished = 0
    received = 0
  }
  if(received === -1 && finished === -1) {
    finished = 0
    received = 0
  }

  const data = {
      datasets: [{
          backgroundColor: ['#124a6b','#47e6b1'],
          data: [finished, received],
          hoverBorderColor: '#ffffff',
          borderWidth: 1  ,
          cutout: '80%',
          borderRadius: 9999 ,
          fill: true
      }]
  };
  
  const options = {
      spacing: 1,
      rotation: 54,
      animation: {
          duration: 0
      },
      layout: {
        padding: 0
      },
      plugins: {
        title: {
          display: false
        }
      },
      interaction: {
        intersect: {
          display: false
        }
      }
  };
  
return (
    <ChartContainer>
      <ChartInfo className={`${props.received === -1? 'shimmer':'' }`}>{props.received === -1 || props.finished === -1? props.children : received === null && finished === null? '0':Number(received) > Number(finished)? String(received?.toString().slice(0, 4)) + '%':String(finished?.toString().slice(0, 4)) + '%'}</ChartInfo>
      <Doughnut id='myChart' className='Doughnut Chart' width={400} height={400} data={data}  options={options}/>
      <ChartTitle>{Number(received) > Number(finished)? 'Recebidos':'Finalizadas'}</ChartTitle>
    </ChartContainer>
  );
}
export default DashboardChart;