import React from 'react';
import Layouts from '../../layouts/Main';
import { Container, NotificationsMainCardsContainer, NotificationsMainContainer, NotificationsMainTopLeftContent, NotificationsMainTopLeftNavContainer, NotificationsMainTopLeftReload, NotificationsMainTopNavContainer, NotificationsMainTopRightButtons, NotificationsMainTopRightButtonsContainer, NotificationsMainTopRightClean, NotificationsMainTopRightContainer, NotificationsMainTopRightContent, NotificationsMainTopRightIcon, NotificationsSubTitle, NotificationsTitle } from './styles';
import Arrow from '../../assets/icons/users-icon/Arrow.svg';
import { NotificationsCards } from '../../components/NotificationsCards';
import { Find } from '../../interface/find.interface';
import { INotificacaoes, useNotificacao } from '../../contexts/notificacao.contex';
import { Skeleton } from '../../components/Skeleton';
import { replaceDate } from '../../utils/replaceDate';

const CardsShimmer = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
]

export const Notifications = () => {

    const { notificacoes, getNotificacoes, confirma } = useNotificacao();

    const[navNumber, setNavNumber] = React.useState(10);
    const[insideNavNumber, setInsideNavNumber] = React.useState(1)
    const [pageValue, setPageValue] = React.useState(0);

    const [filter, setFilter] = React.useState<Find>({
        page: 0,
        search: "",
        grupos: [],
        status: "",
        prioridade: "",
    });
    let newFind = {
        page: pageValue,
        search: 'searchValue',
        grupos: [],
        status: '',
        prioridade: '',
    };

    const Nav = (icon: string, ) => {
        let nav = navNumber;
        let insideNav = insideNavNumber;
        let initial = 10;
        if(navNumber >= Number(notificacoes.total) && icon === 'Next'){
            return;
        }
        if(navNumber >= 10 && icon === 'Next'){
            setInsideNavNumber(insideNav + initial)
            setNavNumber(nav + initial)
            setFilter(newFind)
            setPageValue((old: number) => old + 1)
        }
        if(navNumber > 10 && icon === 'Prev'){
            setInsideNavNumber(insideNav - initial)
            setNavNumber(nav - initial)
            setFilter(newFind)
            setPageValue((old: number) => old - 1)
        }
    }

    const cleanList = (itens: INotificacaoes) => {
        confirma(itens.rows.map((item) => {return item.id}));
    }

    React.useEffect(() => {
        getNotificacoes(newFind);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);
    
    return (
        <Layouts>
            <Container>
                <NotificationsTitle>Notificações</NotificationsTitle>
                <NotificationsSubTitle>Notificações de ações do sistema</NotificationsSubTitle>
                <NotificationsMainContainer>
                    <NotificationsMainTopNavContainer>
                        <NotificationsMainTopLeftNavContainer>
                            <NotificationsMainTopLeftContent>Última atualização: {notificacoes.total !== -1? <span>{replaceDate(String(notificacoes.rows?.[0]?.created_at), 'change')}</span>:<Skeleton width={100} height={18}/>}</NotificationsMainTopLeftContent>
                            <NotificationsMainTopLeftReload onClick={() => {setFilter(newFind)}}>Atualizar</NotificationsMainTopLeftReload>
                        </NotificationsMainTopLeftNavContainer>
                        <NotificationsMainTopRightContainer>
                            <NotificationsMainTopRightClean onClick={() => {cleanList(notificacoes);setFilter(newFind)}}>Limpar a lista</NotificationsMainTopRightClean>
                            <NotificationsMainTopRightContent>{insideNavNumber} - {navNumber > Number(notificacoes.total)? notificacoes.total:navNumber} de {notificacoes.total}</NotificationsMainTopRightContent>
                            <NotificationsMainTopRightButtonsContainer>
                                <NotificationsMainTopRightButtons onClick={() => {Nav('Prev')}} className={'previous'}>
                                    <NotificationsMainTopRightIcon src={Arrow} />
                                </NotificationsMainTopRightButtons>
                                <NotificationsMainTopRightButtons onClick={() => {Nav('Next')}} className={'next'}>
                                    <NotificationsMainTopRightIcon src={Arrow} />
                                </NotificationsMainTopRightButtons>
                            </NotificationsMainTopRightButtonsContainer>
                        </NotificationsMainTopRightContainer>
                    </NotificationsMainTopNavContainer>
                    <NotificationsMainCardsContainer className={`${notificacoes.total === -1? 'shimmer':''}`}>
                        {notificacoes.total !== -1?notificacoes.rows.map((item, key) => {
                            return (
                                <NotificationsCards key={key} notificacao={item}/>
                            )
                        }):CardsShimmer.map(() => {return <Skeleton responsive={true} width={100} height={48}/>})}
                    </NotificationsMainCardsContainer>
                </NotificationsMainContainer>
            </Container>
        </Layouts>
    );
}
