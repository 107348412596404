export const replaceDate = (value?: string, type?: string) => {
  if(value !== 'undefined'){
  let data = `${value?.split?.("-")[2].slice?.(0, 2)}/${value?.split?.("-")[1]}/${value?.split?.("-")[0]}`
  
  return data;
  }
  if(type === 'change') {
    return 'Sem atualizações recentes';
  }
  return;
}