import styled from "styled-components";

export const DashboardMain = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 6.875rem;
    overflow: hidden;
    background-color: #FBFBFB;
`;

export const DashboardTitle = styled.h1`
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 1.375rem;
    letter-spacing: -0.0156rem;
    color: #444053;
`;

export const DashboardSubTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

export const DashboardSubTitle = styled.h3`
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: -0.0156rem;
    color: rgba(68, 64, 83, 0.7);
    margin-top: 1rem;
`;

export const DashboardMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-right: 2.5rem;
    height: 100%;
    gap: 24px;
    width: 100%;
`;

export const DashboardStates = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
`;

export const DashboardEndContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    max-height: 100%;
    width: 100%;
    padding-bottom: 24px;
`;

export const DashboardDataContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 77%;
    max-height: 100%;
    padding-top: 1rem;
    padding-left: 1.5rem;
    border-radius: 8px;
    padding-right: 2.5rem;
    margin-right: 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
`;

export const DashboardGraphicContainer = styled.div`
    width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
`;

export const DashboardGraphicTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 500;
    color: #444053;
    margin-top: 2rem;
`;

export const DashboardOsStateContainer = styled.div`
    width: 100%;
    align-items: center;
    &.Received {
        width: unset;
        display: flex;
    }
    &.Finished {
        margin-top: 1.5rem;
        width: unset;
        display: flex;
    }
`;

export const DashboardOsStateEllipse = styled.div`
    width: 1rem;
    height: 1rem;
    background: #47E6B1;
    border-radius: 9999px;
    &.Finished {
        background: #124A6B;
    }
`;

export const DashboardOsState = styled.h3`
    font-size: 1.125rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 0.75rem;
`;

export const DashboardInfoContainer =  styled.div`
    height: 100%;
    width: 23%;
    display: flex;
    min-width: 260px;
    padding-top: 24px;
    padding: 8px 0;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: #FFFFFF;
`;