import styled from "styled-components";

interface IInputError {
    maxLength: number;
    minLength?: number;
    position?: string;
    top?: string;
}

export const Container = styled.div<IInputError>`
    top: ${({top}) => `${top}px`};
    position: ${({position}) => `${position}`};
    span {
        display: flex;
        color: red;
        display: none;
        &.empty {
            display: unset;
        }
        &.empty::after {
            display: unset;
            color: red;
            content: 'Campo obrigatório';
        }
        &.big {
            display: unset;
        }
        &.big::after {
            display: unset;
            color: red;
            content: '${({maxLength}) => `${maxLength? `Limite de ${maxLength} caracteres atingido.`:''}`}';
        }
        &.equal {
            display: unset;
        }
        &.equal::after {
            content: 'Os já existente';
            display: unset;
            color: red;
        }
        &.small {
            display: unset;
        }
        &.small::after {
            display: unset;
            color: red;
            content: '${({minLength}) => `${minLength? `Minimo de ${minLength} caracteres.`:''}`}';
        }
        &.equal {
            display: unset;
        }
        &.unset::after {
            display: unset;
            color: red;
            content: 'Os já existente';
        }
    }

`;