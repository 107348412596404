import React from "react";

interface IFilters {
        priority: string;
        setPriority: React.Dispatch<React.SetStateAction<string>>;
}

export const FiltersContext = React.createContext({} as IFilters)

export const FiltersProvider: React.FC<React.ReactNode> = ({children}) => {
    
    const [priority, setPriority] = React.useState('');

    return (
        <FiltersContext.Provider value={{priority, setPriority}}>
            {children}
        </FiltersContext.Provider>
    );
}

export default FiltersProvider;

export const useFilters = () => React.useContext(FiltersContext)