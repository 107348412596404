import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    min-width: 985px;
    padding-right: 1.5rem;
    height: 64px;
    border-radius: 6px;
    background-color: #FFFFFF;
    &:first-child {
        margin-top: -3px;
    }
`;

export const ColabsCardLeftContainer = styled.div`
    display: flex;
    width: 45.5%;
    min-width: 813px;
    justify-content: space-between ;
    max-width: 100%;
`; 

export const ColabsCardColabContainer = styled.div`
    display: flex;
    align-items: center;
    min-width: 60%;
    width: 60%;
`;

export const ColabsCardColabIcon = styled.div`
    display: flex;
    background: #124A6B;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 1.125rem;
    margin-left: 30px;
    line-height: 1.0625rem;
    color: #FFFFFF;
    width: 2rem;
    user-select: none;
    height: 2rem;
`;

export const ColabsCardColabUsername = styled.span`
    margin-left: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    color: rgba(68, 64, 83, 0.87);
    min-width: 266px;
    max-width: 16em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ColabsCardColabInfoConaiter = styled.div`
    display: flex;
    width: 40%;
    min-width: 320px;
    justify-content: space-between;
`;

export const ColabsCardColabJob = styled.span`
    display: flex;
    align-self: center;
    font-weight: 500;
    min-width: 140px;
    font-size: 1rem;
    width: 100%;
    color: rgba(68, 64, 83, 0.87);
    display: flex;
    justify-content: center;
`;

export const ColabsCardColabRegistration = styled.span`
    display: flex;
    align-self: center;
    width: 100%;
    font-weight: 500;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    color: rgba(68, 64, 83, 0.87);
`;

export const ColabsCardRightContainer = styled.div`
    display: flex;
    width: 53.5%;
    align-items: center;
    justify-content: flex-end;
    .link {
        text-decoration: none;
        width: 96px;
        border-radius: 9999px;
        margin-right: 16px;
    }
`;

export const ColabsCardShowMore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    min-width: 6rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #FFFFFF;
    border-radius: 9999px;
    background-color: #124A6B;
    margin-right: 16px;
    user-select: none;
    cursor: pointer;
    transition: ease background-color 0.16s;
    &:hover {
        background-color: rgba(18, 74, 107, 0.97);
    }
    &:active {
        background-color: rgba(18, 74, 107, 0.92);
    }
`;

export const ColabsCardRightIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    cursor: pointer;
    border-radius: 50%;
    transition: ease background-color 0.3s;
    user-select: none;
    position: relative;
    &:hover {
        background: rgba(229, 229, 229, 0.5);
        .edit-hover {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            bottom: -36px;
            width: 4rem;
            height: 1.5rem;
            background-color: #061D28;
            color: #FFFFFF;
            position: absolute;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 0.875rem;
            opacity: 1;
            z-index: 1;
            transition: ease opacity 0.3s;
        }
        .edit-part {
            background-color: #061D28;
            position: absolute;
            z-index: -1;
            top: -5px;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
        }
        .delete-hover {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            bottom: -36px;
            width: 4rem;
            height: 1.5rem;
            background-color: #FF0000;
            color: #FFFFFF;
            position: absolute;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 0.875rem;
            opacity: 1;
            z-index: 1;
            transition: ease opacity 0.3s;
        }
        .delete-part {
            background-color: #FF0000;
            position: absolute;
            z-index: -1;
            top: -5px;
            width: 16px;
            height: 16px;
            transform: rotate(45deg);
        }
    }
    &:active {
        background: rgba(229, 229, 229, 0.8);
    }
`;

export const ColabsCardRightEditIcon = styled.img`
    width: 0.75rem;
`;

export const ColabsCardHoverEdit = styled.div`
    opacity: 0;
    z-index: -1;
    position: absolute;
`;

export const ColabsCardHoverEditPart = styled.div`
    z-index: -1;
`;

export const ColabsCardRightDeleteIcon = styled.img`
    width: 0.75rem;
`;

export const ColabsCardHoverDelete = styled.div`
    opacity: 0;
    z-index: -1;
    position: absolute;
`;

export const ColabsCardHoverDeletePart = styled.div`
    z-index: -1;
`;
