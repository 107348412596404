export const contentHelper = (img: string) => {
      switch(img) {
          case 'Local':
              return 'Local cadastrado com sucesso'
          case 'LocalEdit':
              return 'Local editado com sucesso'
          case 'LocalError':
              return 'Erro ao cadastrar local'
          case 'Group':
              return 'Grupo de trabalho cadastrado com sucesso'
          case 'GroupEdit':
              return 'Grupo de trabalho editado com sucesso'
          case 'GroupError':
            return 'Erro ao cadastrar grupo de trabalho'
          default:
            return '';
      }
  }