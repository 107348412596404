/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import usePersistedState from "../hooks/use-persisted-state";
import { LoginDTO } from "../interface/login-dto.interface";
import { UpdatedUser } from "../interface/profile.interface";
import { IUser } from "../interface/user.interface";
import { doLogin, revalidate as refreshToken } from "../services/authentication.service";
import { updateProfile } from "../services/profile.service";

interface IAuthContext {
    signed: boolean;
    user: IUser | null;
    setUser: React.Dispatch<React.SetStateAction<IUser>>;
    signIn: (login: LoginDTO) => Promise<void>;
    signOut: () => void;
    token: string;
    setToken: React.Dispatch<React.SetStateAction<string>>;
    _updateProfile: (user: UpdatedUser) => void
}

export const AuthContext = React.createContext({} as IAuthContext);

const AuthContextProvider: React.FC = ({ children }) => {
    const [user, setUser] = usePersistedState<IUser>('user', {} as IUser);
    const [token, setToken] = usePersistedState("jwt_access_token", "");

    const signIn = React.useCallback(async (login: LoginDTO) => {
        try {
            const data = await doLogin(login);
            
            setUser(data.user);
            setToken(data.token);
        } catch (error) {
        }
    }, []);

    const revalidate = React.useCallback(async () => {
        try {
            const data = await refreshToken();
            setToken(data.token);
        } catch (error: any) {
            setToken('');
            setUser({} as IUser);
        }
    }, []);

    React.useEffect(() => {
        if (!token && !!user.id) {
            revalidate();
        }
    }, [token, revalidate]);

    const signOut = React.useCallback(() => {
        setUser({} as IUser);
        setToken("");
    }, [token, user]);

    const _updateProfile = React.useCallback(async (user: UpdatedUser) => {
        await updateProfile(user).then(async (res) => {
            setUser(res.data);
        }).catch((err) => {
            
        })
    }, [])

    return (
        <AuthContext.Provider value={{ user, setUser, signIn, token, setToken, signed: !!token, signOut, _updateProfile }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;

export const useAuth = () => React.useContext(AuthContext);