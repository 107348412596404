import React, { useState } from "react";
import {
    Container,
    PriorityArrow,
    PriorityContainer,
    PriorityIcon,
    PriorityLabel,
    PriorityOption,
    PriorityOptionContainer,
    PriorityPlaceHolder,
} from "./styles";
import Arrow from "../../assets/icons/users-icon/Arrow.svg";

interface IPrioritySelect {
    placeholder?: string;
    class?: string;
    showFunct?: () => void;
    hideFunct?: () => void;
    changeFunct?: () => void;
    state?: boolean;
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    iconColor?: string;
    setValue?: (value: string) => void;
    values?: Array<{
        id: number;
        icon: string;
        label: string;
        iconColor: string;
        moveColor?: string;
        value: string;
    }>;
}

export const PrioritySelect = (props: IPrioritySelect) => {
    
    const [placeHolder, setPlaceHolder] = useState(props.placeholder);
    const [showSelect, setShowSelect] = useState(false);
    const [mainIcon, setMainIcon] = useState("");
    const [labelKey, setLabelKey] = useState(-1);
    const [start, setStart] = useState(false);
    const [arrowFlip, setArrowFlip] = useState("");

    const ResetPlaceHolder = (initialPlaceHolder?: string) => {
        if (String(placeHolder) !== initialPlaceHolder) {
            setPlaceHolder(props.placeholder);
            props.setValue?.('');
            props.changeFunct?.();
        }
        if (labelKey !== -1) {
            setLabelKey(-1);
        }
        if (String(placeHolder) !== initialPlaceHolder) {
            setMainIcon("");
        }
    };

    const MovePlaceHolder = (key: number, item: number) => {
        setStart(true);
        setTimeout(() => {
            setLabelKey(key);
        }, 0.00001);
    };

    const TransformArrow = () => {
        setArrowFlip("flip");
        if (arrowFlip !== "") {
            setArrowFlip("");
        }
    };
    
    return (
        <Container
            top={props.top}
            right={props.right}
            bottom={props.bottom}
            left={props.left}
        >
            <PriorityContainer
                tabIndex={1}
                onBlur={() => {
                    if (showSelect) {
                        ResetPlaceHolder(props.placeholder);
                        TransformArrow();
                        setShowSelect((old) => !old);
                    }
                }}
                onClick={() => {
                    ResetPlaceHolder(props.placeholder);
                    TransformArrow();
                }}
                className={`${showSelect ? "active" : ""}`}
            >
                <PriorityOption
                    onClick={() => {
                        setShowSelect((old) => !old);
                    }}
                    className={`selected ${showSelect ? "active" : ""}`}
                >
                    <PriorityPlaceHolder
                        className={
                            String(placeHolder) !== props.placeholder
                                ? "main"
                                : ""
                        }
                    >
                        {placeHolder}
                    </PriorityPlaceHolder>
                    <PriorityArrow
                        className={`${
                            String(placeHolder) !== props.placeholder
                                ? "main"
                                : ""
                        }${arrowFlip}`}
                        src={Arrow}
                    />
                </PriorityOption>
                <PriorityOptionContainer
                    className={`${showSelect ? "active" : ""}`}
                >
                    {props.values?.map((item, key) => {
                        return (
                            <PriorityOption
                                onClick={() => {
                                    setPlaceHolder(item.label);
                                    setMainIcon(item.icon);
                                    MovePlaceHolder(key, item.id);
                                    setTimeout(() => {
                                        setShowSelect((old) => !old);
                                    }, 100);
                                    props.setValue?.(item.value)
                                    props.changeFunct?.();
                                }}
                            >
                                <PriorityIcon
                                    iconColor={item.iconColor}
                                    moveColor={item.moveColor}
                                    id="priorityicon"
                                    className={`${
                                        item.icon === "red"
                                            ? item.icon +
                                              "-" +
                                              mainIcon +
                                              "main"
                                            : ""
                                    } ${item.icon} ${
                                        showSelect ? "" : "hidden"
                                    } ${start ? "start" : ""} ${
                                        labelKey === key ? "Selected" : ""
                                    }`}
                                />
                                <PriorityLabel
                                    className={`${
                                        mainIcon !== ""
                                            ? item.icon + "label"
                                            : ""
                                    } ${key} ${item.icon} ${
                                        showSelect ? "" : "normal"
                                    } ${labelKey === key ? "Selected" : ""} ${
                                        labelKey === key ? "start" : ""
                                    }`}
                                >
                                    {item.label}
                                </PriorityLabel>
                            </PriorityOption>
                        );
                    })}
                </PriorityOptionContainer>
            </PriorityContainer>
        </Container>
    );
};
