import React, { useState } from "react";

import {
    Container,
    SelectContainer,
    SelectOption,
    SelectOptionArrow,
    SelectOptionContainer,
    SelectOptionContent,
} from "./styles";
import Arrow from "../../assets/icons/users-icon/Arrow.svg";

interface ILocal {
    id: string;
    nome: string;
}

interface IInputSelect {
    values?: ILocal[];
    direction?: string;
    placeHolder?: string;
    setState?: React.Dispatch<React.SetStateAction<string>>;
    state?: string;
    idOptions?: string;
    idSelect?: string;
}

export const InputSelect = (props: IInputSelect) => {
    const [showSelect, setShowSelect] = useState(false);
    const [selectedOption, setSelectedOption] = useState("0");
    const [selectedState, setSelectedState] = useState(false);

    const Selected = (key: string) => {
        setSelectedState((old) => !old);
        setSelectedOption(key);
        props.setState?.(old => (old = key));
        let Selected = key;
        if (!showSelect && selectedState) {
            setShowSelect(true);
            props.setState?.('');
            return;
        }
        if (Selected === selectedOption) {
            setSelectedState(true);
            return;
        }
    };
    React.useEffect(() => {
        if(props.state === '0'){
            setSelectedState(false);
            setSelectedOption('0');
        }
    },[props.state])
    return (
        <Container
            tabIndex={1}
            onBlur={() => {
                if (showSelect) {
                    setShowSelect(false);
                }
            }}
            className={`${props.direction === "column" ? "column" : ""}${
                showSelect ? " showed" : ""
            }`}
        >
            <SelectContainer
                className={`${showSelect && props.state ===  '0' ? "" : "hidden "}${
                    selectedState ? "selected " : ""
                }`}
            >
                <SelectOption
                    id={props.idSelect}
                    className={`${"main "}${showSelect ? "expanded " : ""}`}
                    onClick={() => {
                        setShowSelect((old) => !old);
                        setSelectedState(false);
                    }}
                >
                    <SelectOptionContent
                        className={`${"main-content"}${
                            selectedState ? " hidden" : ""
                        }`}
                    >
                        {props.placeHolder}
                    </SelectOptionContent>
                    <SelectOptionArrow
                        src={Arrow}
                        className={`${showSelect ? "flip" : ""}`}
                    />
                </SelectOption>
                <SelectOptionContainer
                    selected={selectedState}
                    direction={props.direction}
                    className={`${showSelect ? "" : "hidden "}${
                        selectedState ? "selected " : ""
                    }`}
                >
                    {props.values?.map((item, key) => {
                        return (
                            <SelectOption
                                id={`${props.idOptions}-${key}`}
                                key={key}
                                direction={props.direction}
                                className={`${
                                    showSelect && !selectedState
                                        ? "normal "
                                        : ""
                                }${
                                    selectedState && item.id === selectedOption
                                        ? "selected "
                                        : "none "
                                }`}
                                onClick={() => {
                                    setShowSelect(false);
                                    Selected(item.id);
                                }}
                            >
                                <SelectOptionContent
                                    className={`${
                                        selectedState &&
                                        item.id === selectedOption
                                            ? "selected "
                                            : ""
                                    }`}
                                >
                                    {item.nome}
                                </SelectOptionContent>
                            </SelectOption>
                        );
                    })}
                </SelectOptionContainer>
            </SelectContainer>
        </Container>
    );
};
