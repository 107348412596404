import React, {useState } from 'react';
import { Container, TrackServiceOrderColOptions, TrackServiceOrderLeftOptions, TrackServiceOrderNavIcon, TrackServiceOrderNavIconContainer, TrackServiceOrderNavInfo, TrackServiceOrderOptions, TrackServiceOrderRightInfoContainer, TrackServiceOrderRightOptions } from './styles';
import Arrow from '../../assets/icons/users-icon/Arrow.svg'
import { Find } from '../../interface/find.interface';

interface IServiceOrderNav {
    values?: Array<{
        content: string;
    }>
    class?: string;
    type?: string;
    page?: number;
    total?: number;
    setPage?: React.Dispatch<React.SetStateAction<number>>;
    state: Find;
    setState?: React.Dispatch<React.SetStateAction<Find>>;
    osClass?: string;
}

export const ServiceOrderNav = (props: IServiceOrderNav) => {

    const[navNumber, setNavNumber] = useState(5);
    const[insideNavNumber, setInsideNavNumber] = useState(1)
    const[initialState, setInitialState] = React.useState(false);

    if(props.page === 0 && initialState) {
        setInsideNavNumber(1);
        props.setState?.(props.state);
        props.setPage?.((old: number) => old);
        setInitialState(false);
    }
    const Nav = (icon: string, ) => {
        let nav = navNumber;
        let insideNav = insideNavNumber;
        let initial = 5;
        if(navNumber >= Number(props.total) && icon === 'Next') {
            return;
        }
        if(navNumber >= 5 && icon === 'Next') {
            setInsideNavNumber(insideNav + initial);
            setInitialState(true);
            setNavNumber(nav + initial);
            props.setState?.(props.state);
            props.setPage?.((old: number) => old + 1);
        }
        if(navNumber > 5 && icon === 'Prev') {
            setInsideNavNumber(insideNav - initial);
            setInitialState(true);
            setNavNumber(nav - initial);
            props.setState?.(props.state);
            props.setPage?.((old: number) => old - 1);
        }
    }

  return (
    <Container>
        <TrackServiceOrderColOptions>
            <TrackServiceOrderLeftOptions className={`${props.type} ${props.osClass}`}>
                {!props.osClass && props.values?.slice(0, 1).map((item, key) => {
                    return (
                        <TrackServiceOrderOptions key={key} className={`${item.content?.slice(0, 3).toString()} ${props.class}`}>{item.content}</TrackServiceOrderOptions>
                    )
                })}
                <TrackServiceOrderRightInfoContainer className={`${props.osClass}`}>
                {props.osClass? props.values?.slice(0, 4).map((item, key) => {
                    return (
                        <TrackServiceOrderOptions key={key} className={`${item.content?.slice(0, 3).toString()} ${props.class}`}>{item.content}</TrackServiceOrderOptions>
                    )
                }): props.values?.slice(1, 4).map((item, key) => {
                    return (
                        <TrackServiceOrderOptions key={key} className={`${item.content?.slice(0, 3).toString()} ${props.class}`}>{item.content}</TrackServiceOrderOptions>
                    )
                })}
                </TrackServiceOrderRightInfoContainer>
            </TrackServiceOrderLeftOptions>
            <TrackServiceOrderRightOptions className={`${props.type}`}>
                <TrackServiceOrderNavInfo>{insideNavNumber} - {navNumber > Number(props.total)? props.total:navNumber} de {props.total}</TrackServiceOrderNavInfo>
                <TrackServiceOrderNavIconContainer onClick={() => {Nav('Prev')}} className={`${'left'}`}>
                    <TrackServiceOrderNavIcon className={`${'left'}`} src={Arrow}/>
                </TrackServiceOrderNavIconContainer>
                <TrackServiceOrderNavIconContainer onClick={() => {Nav('Next')}} className={`${'right'}`} >    
                    <TrackServiceOrderNavIcon className={`${'right'}`} src={Arrow}/>
                </TrackServiceOrderNavIconContainer>
            </TrackServiceOrderRightOptions>
        </TrackServiceOrderColOptions>
    </Container>
  );
}
