import React from 'react';
import { Container, TrackOsCardAlignContainer, TrackOsCardLeftContainer, TrackOsCardOsNumber, TrackOsCardOsPriorityContainer, TrackOsCardPriorityContent, TrackOsCardPriorityIcon, TrackOsCardRightContainer, TrackOsCardShowMore, TrackOsCardStatus, TrackOsCardUserIcon } from './styles';
import RedArrow from '../../assets/icons/Track-Service-Order/RedArrow.svg';
import OrangeArrow from '../../assets/icons/Track-Service-Order/OrangeArrow.svg';
import BlueArrow from '../../assets/icons/Track-Service-Order/BlueArrow.svg';
import { Link, useLocation } from 'react-router-dom'
import { getPropriedades } from '../../utils/getPropriedades';

interface ITrackOsCard {
    username?: string;
    osNumber?: string;
    priority: string;
    status: string;
    buttonPath?: string;
    onClick?: () => void;
}

export const TrackOsCard = (props: ITrackOsCard) => {

    const location = useLocation();

    const osProps = getPropriedades(props.status, props.priority)

    const IconFunct = () => {
        return props.username?.slice(0, 1).toString().toUpperCase()
    }
    const PrioFunct = () => {
        return osProps.prioridade?.slice(0, 1).toString().toUpperCase()
    }
    const StatsFunct = () => {
        return osProps.status?.slice(0, 1).toString().toUpperCase()
    }
    
  return (
    <Container>
        <TrackOsCardLeftContainer>
            <TrackOsCardAlignContainer className={`${'icon'}`}>
                <TrackOsCardUserIcon>{`${IconFunct()}`}</TrackOsCardUserIcon>
            </TrackOsCardAlignContainer>
            <TrackOsCardAlignContainer>
                <TrackOsCardOsNumber>{props.osNumber}</TrackOsCardOsNumber>
            </TrackOsCardAlignContainer>
            <TrackOsCardAlignContainer >
            <TrackOsCardOsPriorityContainer className={`${PrioFunct()}`}>
                <TrackOsCardPriorityIcon className={`${PrioFunct()}`} src={`${PrioFunct() === 'A'? RedArrow:''}${PrioFunct() === 'M'? OrangeArrow:''}${PrioFunct() === 'B'? BlueArrow:''}`}/>
                <TrackOsCardPriorityContent className={`${PrioFunct()}`}>{osProps.prioridade}</TrackOsCardPriorityContent>
            </TrackOsCardOsPriorityContainer>
            </TrackOsCardAlignContainer>
            <TrackOsCardAlignContainer>            
                <TrackOsCardStatus className={`${StatsFunct()} ${osProps.status?.slice(0, 3).toString()}`}>{osProps.status}</TrackOsCardStatus>
            </TrackOsCardAlignContainer>
        </TrackOsCardLeftContainer>
        <TrackOsCardRightContainer>
        <Link onClick={props.onClick} style={{textDecoration:'none'}} to={`${location.pathname}${props.buttonPath}`}>
            <TrackOsCardShowMore>Ver Mais</TrackOsCardShowMore>
        </Link>
        </TrackOsCardRightContainer>
    </Container>
  );
}
