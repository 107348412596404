import React from "react";
import {
    Container,
    NotesCardContentContainer,
    NotesCardDateContent,
    NotesCardIcon,
    NotesCardMainContainer,
    NotesCardMainContent,
    NotesCardTopContentContainer,
    NotesCardTopInfo,
    NotesCardTopLeftContainer,
    NotesCardTopUsername,
} from "./styles";

export interface IPops {
    anotacao: {
        id: string;
        id_os: string;
        nome: string;
        anotacao: string;
        created_at: string;
    };
}

export const ShowMoreOsNotesCards = (props: IPops) => {
    return (
        <Container>
            <NotesCardIcon>{`${props.anotacao.nome.slice(0, 1)}${
                String(props.anotacao.nome.split(" ")[1]) === "undefined"
                    ? ""
                    : props.anotacao.nome.split(" ")[1].slice(0, 1)
            }`}</NotesCardIcon>
            <NotesCardContentContainer>
                <NotesCardTopContentContainer>
                    <NotesCardTopLeftContainer>
                        <NotesCardTopUsername>
                            {String(props.anotacao.nome.split(" ")[0]) ===
                            "undefined"
                                ? props.anotacao.nome
                                : props.anotacao.nome
                                      .split(" ")
                                      .slice(0, 2)
                                      .map((item) => {
                                          return `${item.slice(0, 1)}${item
                                              .slice(1, 99)
                                              .toLocaleLowerCase()} `;
                                      })}
                        </NotesCardTopUsername>
                        <NotesCardTopInfo>adicionou uma nota</NotesCardTopInfo>
                    </NotesCardTopLeftContainer>
                    <NotesCardDateContent>{`${props.anotacao.created_at
                        .split("-")[2]
                        .slice(0, 2)}/${
                        props.anotacao.created_at.split("-")[1]
                    }/${
                        props.anotacao.created_at.split("-")[0]
                    }`}</NotesCardDateContent>
                </NotesCardTopContentContainer>
                <NotesCardMainContainer>
                    <NotesCardMainContent>
                        {props.anotacao.anotacao}
                    </NotesCardMainContent>
                </NotesCardMainContainer>
            </NotesCardContentContainer>
        </Container>
    );
};
