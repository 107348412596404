import styled from "styled-components";

export const MenuIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    height: 52px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:not(:first-child) {
        margin-top: 4px;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.1);
        .Icon-Hover-Bar {
            display: unset;
        }
    }
    &.Active {
        background: rgba(255, 255, 255, 0.1);
        .Icon-Hover-Bar {
            display: unset;
        }
    }
    // Main Nav Icon
    &.Main {
        height: 73px;
        min-height: 73px;
        background: linear-gradient(180deg, #7BBC9C 0%, #124A6B 100.22%);
        transition: ease all 1s;
        .Icon-Hover-Bar {
            display: none;
        }
    }
    &.Main:hover {
        .Icon-Hover-Bar {
            height: 64px;
        }
    }
    // Other Nav Icons
    .Icon-Container:first-child {
        background-color: unset;
        margin-top: 10px;
    }
    .Icon-Container:first-child:hover {
        background: rgba(255, 255, 255, 0.1);
    }
`;

export const IconHoverBar = styled.div`
    height: 100%;
    width: 3px;
    position: absolute;
    background: white;
    left: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: none;
`;

export const IconLogo = styled.img`
    &.Main {
        height: 0;
        width: 0;
        transition: ease all .2s .08s;
    }
    &.Expanded.Icon-Container.Main.Logo {
        height: 65px;
        width: 93px;
        padding: 50px 70px;
        margin-left: 50px;
        padding: 0;
        transition: ease all .2s .08s;
    }
`;

export const IconImg = styled.img`
    margin-left: 26px;
    transition: ease-in-out all .3s ;
    &.undefined {
        display: none;
    }
    &.Hamburger {
        margin-left: 22px;
        left: -4px;
        top: 26px;
        &.Expanded {
            opacity: 0;
        }
    }
    &.Close {
        position: absolute;
        opacity: 0;
        left: 0px;
        top: 26px;
    }
    &.Show {
        opacity: 1;
        left: 0px;
        top: 26px;
    }
`;

export const IconText = styled.span`
    opacity: 0;
    font-size: 0;
    z-index: -1;
    transition: ease all .3s;
    &.Expanded {
        opacity: 1;
        font-size: unset;
        z-index: unset;
        margin-top: -1px;
        display: unset;
        justify-content: left;
        color: white;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.375rem;
        letter-spacing: -0.0156rem;
        margin-left: 1.5rem;
    }
`;