import styled from 'styled-components';

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    min-width: 222px;
    width: 33%;
    position: relative;
    margin-top: 1rem;
    &.profile {
        margin-left: 0;
        width: 100%;
    }
    &.config {
        height: 86px;
        width: 100%;
    }
    &.password {
        width: 22%;
    }
    &.search {
        width: 100%;
        margin-left: 0;
    }
    &.history {
        width: 30%;
    }
    &.Local {
        display: none;
    }
    &.Prioridade {
        display: none;
    }
    &.Tipo {
        display: none;
    }
    &.Status {
        display: none;
    }
    &.Datadeabertura {
        display: none;
    }
    &.Solicitante {
        display: none;
    }
    &.Tempoestimado {
        display: none;
    }
`;

export const TrackOsInputLabel = styled.label`
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    letter-spacing: 0.0313rem;
    color: rgba(68, 64, 83, 0.8);
    margin-bottom: 0.5rem;
    &.config {
        font-weight: 400;
        font-size: 1.125rem;
        color: #444053;
    }
`;

export const TrackOsInputUnset = styled.input`
    width: 100%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    letter-spacing: -0.0156rem;
    color: #333332;
    outline: none;
    border: none;
    width: 100%;
    height: 4rem;
    opacity: 1;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 3rem;
    &:disabled {
        color: #33333299;
        background-color: #FFFFFF;
        opacity: 1;
        cursor: default;
    }
    &.config {
        margin-top: 0.75rem;
        height: 4rem;
        box-shadow: none;
        background-color: rgba(229, 229, 229, 0.1);
        border: 1px solid #E5E5E5;
        border-radius: 4px;
    }
    &.search {
        border: 1px solid #E5E5E5;
        margin-top: 0.75rem;
        height: 4rem;
        padding-left: 3.125rem;
    }
`;

export const TrackOsEditIconContainer = styled.div`
    background: rgba(229, 229, 229, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    position: absolute;
    right: 1rem;
    bottom: 2.5rem;
    cursor: pointer;
    transition: ease background-color 0.15s;
    &.editable {
        display: flex;
    }
    &:hover {
        background: rgba(229, 229, 229, 0.7);
    }
    &:active {
        background: rgba(229, 229, 229, 0.85);
    }
`;

export const TrackOsEditIcon = styled.img`
    z-index: -1;
`;

export const ShowMoreOsIconContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    border-radius: 50%;
    transition: ease background-color 0.5s, ease opacity 0.15s;
    user-select: none;
    right: 1rem;
    bottom: 1.125rem;
    position: absolute;
    opacity: 1;
    z-index: 2;
    outline: none;
    border: none;
    &.config {
        bottom: 1.125rem;
    }
    &:hover {
        transition: ease opacity 0.3s;
        background: rgba(229, 229, 229, 0.5);
        .edit-hover {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            bottom: -2.25rem;
            width: 4rem;
            height: 1.5rem;
            background-color: #061D28;
            color: #FFFFFF;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 0.875rem;
            opacity: 1;
            z-index: -1;
            left: unset;
            transition: ease opacity 0.3s;
            transition: 2s z-index;
            &.disable {
                opacity: 0;
                z-index: -1;
            }
        }
        .edit-part {
            display: flex;
            background-color: #061D28;
            position: absolute;
            z-index: -1;
            top: -0.3125rem;
            width: 1rem;
            left: unset;
            height: 1rem;
            transform: rotate(45deg);
            &.disable {
                opacity: 0;
                z-index: -1;
            }
        }
        .delete-hover {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            bottom: -2.25rem;
            width: 4rem;
            height: 1.5rem;
            background-color: #FF0000;
            color: #FFFFFF;
            position: absolute;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 0.875rem;
            opacity: 1;
            z-index: -1;
            transition: ease opacity 0.3s;
        }
        .delete-part {
            background-color: #FF0000;
            position: absolute;
            z-index: -1;
            top: -0.3125rem;
            width: 1rem;
            height: 1rem;
            transform: rotate(45deg);
        }
    }
    &:active {
        background: rgba(229, 229, 229, 0.8);
    }
    &.clean.active {
        opacity: 0;
        z-index: -1;
        width: 0;
        position: unset;
        height: 0;
        cursor: pointer;
    }
    &.edit.clean {
        opacity: 0;
        z-index: -1;
    }
    &.hidden {
        z-index: -1;
        opacity: 0;
    }
    &.green {
        transition: ease background-color 0.3s;
        background-color: rgba(71, 226, 142, 1);
        cursor: not-allowed;
    }
`;

export const ShowMoreOsEditIcon = styled.img`
    position: absolute;
    z-index: -1;
    &.clean {
        opacity: 0;
        z-index: -1;
    }
    &.green {
        opacity: 1;
        z-index: unset;
    }
    &.unset.green {
        opacity: 0;
        z-index: -1;
    }
`;

export const ShowMoreOsHoverEdit = styled.div`
    opacity: 0;
    z-index: -1;
    height: 0;
    width: 0;
    position: absolute;
    display: none;
`;

export const ShowMoreOsHoverEditPart = styled.div`
    height: 0;
    z-index: -1;
    display: none;
    width: 0;
    position: absolute;
`;