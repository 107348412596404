import { GlobalStyle } from "./styles/global";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import ServiceOrderProvider from "./contexts/ServiceOrder";
import ColabsProvider from "./contexts/Users";
import { LogProvider } from "./contexts/ChangeLog";
import AuthContextProvider from "./contexts/AuthContext";
import LocalProvider from "./contexts/LocalContext";
import ChatProvider from './contexts/ChatContext';
import AnotacaoProvider from "./contexts/Anotacao.contex";
import NotificacaoProvider from "./contexts/notificacao.contex";
import DashboardProvider from "./contexts/Dashboard.context";
import FiltersProvider from './contexts/FiltersContext'

function App() {
    return (
        <AuthContextProvider>
            <ServiceOrderProvider>
                <LocalProvider>
                    <LogProvider>
                        <ColabsProvider>
                            <ChatProvider>
                                <AnotacaoProvider>
                                    <NotificacaoProvider>
                                        <DashboardProvider>
                                            <FiltersProvider>
                                                <BrowserRouter>
                                                    <Routes />
                                                    <GlobalStyle />
                                                </BrowserRouter>
                                            </FiltersProvider>
                                        </DashboardProvider>
                                    </NotificacaoProvider>
                                </AnotacaoProvider>
                            </ChatProvider>
                        </ColabsProvider>
                    </LogProvider>
                </LocalProvider>
            </ServiceOrderProvider>
        </AuthContextProvider>
    );
}

export default App;
