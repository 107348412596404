import React, { useState } from "react";

import {
    CalendarContainer,
    DashboardCalendarContainer,
    DashboardGraphicDate,
    DashboardGraphicDateContainer,
} from "./styles";
import Calendar from "react-calendar";
import moment from "moment";

interface IDashboardCalendar {
    blurFromStateFunct?: any;
    blurUntilStateFunct?: any;
    blurFromState?: boolean;
    blurUntilState?: boolean;
    fromValue: Date;
    setFromValue: React.Dispatch<React.SetStateAction<Date>>;
    untilValue: Date;
    setUntilValue: React.Dispatch<React.SetStateAction<Date>>;
    reload: any;
}

export let from = -1;
export let until = -1;

export const DashboardCalendar = (props: IDashboardCalendar) => {

    const [fromDateSelect, setFromDateSelect] = useState(false);
    const [untilDateSelect, setUntilDateSelect] = useState(false);
    const [fromDateShow, setFromDateShow] = useState(-1);
    const [untilDateShow, setUntilDateShow] = useState(-1);
    
    from = fromDateShow;
    until = untilDateShow;

    return (
        <DashboardGraphicDateContainer>
            <DashboardCalendarContainer>
                <DashboardGraphicDate
                    onClick={() => {
                        props.blurFromStateFunct(false);
                        setFromDateShow(1);
                        setFromDateSelect(false);
                        if(fromDateShow === 1){
                            setFromDateShow(0);
                        }
                    }}
                    onBlur={() => {
                        if(fromDateShow === 1){
                            setFromDateShow(0);
                        }
                    }}
                    tabIndex={1}
                    className={`from`}
                >
                    <strong>De</strong>
                    {moment(props.fromValue).format("DD/MM/YYYY")}
                </DashboardGraphicDate>
                <CalendarContainer
                    onBlur={() => {
                        if(fromDateShow !== 2){
                            setFromDateShow(0)
                        }
                    }}
                    onMouseEnter={() => {
                        if (fromDateShow !== 0) {
                            setFromDateShow(2);
                        }
                    }}
                    onMouseLeave={() => {
                        if (fromDateShow === 2) {
                            setFromDateShow(1);
                        }
                    }}
                    tabIndex={1}
                    className={`from ${fromDateShow !== 0 ? "" : "hide "}${
                        fromDateSelect ? "selected " : ""
                    }${
                        props.blurFromState && fromDateShow !== 0 ? "hide" : ""
                    }`}
                >
                    <Calendar
                        onClickDay={(value: Date) => {
                            setFromDateSelect(true);
                            setFromDateShow(0);
                            props.setFromValue(value);
                            props.reload()
                        }}
                        className={`calendar 1`}
                    />
                </CalendarContainer>
            </DashboardCalendarContainer>
            <DashboardCalendarContainer>
                <DashboardGraphicDate
                    onClick={() => {
                        props.blurUntilStateFunct(false);
                        setUntilDateShow(1);
                        setUntilDateSelect(false);
                        if(untilDateShow === 1){
                            setUntilDateShow(0);
                        }
                    }}
                    onBlur={() => {
                        if(untilDateShow === 1){
                            setUntilDateShow(0);
                        }
                    }}
                    tabIndex={1}
                    className={`until`}
                >
                    <strong>Até</strong>
                    {moment(props.untilValue).format("DD/MM/YYYY")}
                </DashboardGraphicDate>
                <CalendarContainer
                    onBlur={() => {
                        if(untilDateShow !== 2){
                            setUntilDateShow(0)
                        }
                    }}
                    onMouseEnter={() => {
                        if (untilDateShow !== 0) {
                            setUntilDateShow(2);
                        }
                    }}
                    onMouseLeave={() => {
                        if (untilDateShow === 2) {
                            setUntilDateShow(1);
                        }
                    }}
                    tabIndex={1}
                    className={`until ${untilDateShow !== 0 ? "" : "hide "}${
                        untilDateSelect ? "selected" : ""
                    }${
                        props.blurUntilState && untilDateShow !== 0
                            ? "hide"
                            : ""
                    }`}
                >
                    <Calendar
                        onClickDay={(value: Date) => {
                            setUntilDateSelect(true);
                            setUntilDateShow(0);
                            props.setUntilValue(value);
                            props.reload();
                        }}
                        className={`calendar 2`}
                    />
                </CalendarContainer>
            </DashboardCalendarContainer>
        </DashboardGraphicDateContainer>
    );
};
