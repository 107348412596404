import React from "react";
import {Route, Redirect, BrowserRouter as Router, useHistory} from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import OsHistory from "../pages/OsHistory";
import {SignIn} from "../pages/SignIn";
import TrackServiceOrder from "../pages/TrackServiceOrder";
import {Colabs} from "../pages/Colabs";
import {ShowMoreTrackOs} from "../pages/ShowMoreTrackOs";
import {ShowMoreOsHistory} from "../pages/ShowMoreOsHistory";
import {Chat} from "../pages/Chat";
import {Notifications} from "../pages/Notifications";
import {Profile} from "../pages/Profile";
import {Config} from "../pages/Config";
import {useAuth} from "../contexts/AuthContext";
import ColabDetails from "../pages/ColabDetails";
import ColabCreate from "../pages/ColabCreate";
import NewColabCreate from "../pages/NewColabCreate";

const Routes: React.FC = () => {

    const auth = useAuth();
    const history = useHistory()
    
    return (
        <Router>
            {!auth.signed && <Route path="/" exact component={SignIn} />}
            {auth.signed? (
                <>
                    {history.location.pathname === '/' || !history.location.pathname? window.location.replace('/dashboard'):<></>}
                    <Route path="/Dashboard" exact component={Dashboard} />
                    <Route path="/Ordens" exact component={TrackServiceOrder} />
                    <Route
                        path="/ordens/show-more-track/:id/:id"
                        exact
                        component={ShowMoreTrackOs}
                    />
                    <Route path="/history" exact component={OsHistory} />
                    <Route
                        path="/history/show-more-history/:id/:id"
                        exact
                        component={ShowMoreOsHistory}
                    />
                    <Route path="/colabs" exact component={Colabs} />
                    <Route
                        path="/colabs/show-more-colabs"
                        exact
                        component={ColabDetails}
                    />
                    <Route
                        path="/colabs/create"
                        exact
                        component={ColabCreate}
                    />
                    <Route path="/chat" exact component={Chat} />
                    <Route
                        path="/notifications"
                        exact
                        component={Notifications}
                    />
                    <Route path="/newcolab/create" component={NewColabCreate}/>
                    <Route path="/profile" exact component={Profile} />
                    <Route path="/config" exact component={Config} />
                </>
            ):
                <Redirect path="/" to={auth.signed? history.location.pathname : "/"} />
            }
        </Router>
    );
};

export default Routes;
