import React from "react";
import { OpenOS } from "../OpenOS";
import Local from '../../assets/imgs/Config/Local.png'
import LocalError from '../../assets/imgs/Config/LocalError.png'
import Error from '../../assets/imgs/Config/Error.png'
import Group from '../../assets/imgs/Config/Group.png'
import Close from "../../assets/icons/users-icon/Close.svg";
import {
    CreatedColabModalMainContent,
    CreatedColabModalMainImg,
    CreatedColabModalMainSection,
    CreatedColabModalTopCloseIcon,
    CreatedColabModalTopCloseIconContainer,
    ShowMoreColabCreatedColabModalContainer,
} from "./styles";
import { Modal } from "../../layouts/Modal";

interface IModalComponent {
    img: string;
    content: string;
    target: string;
    blurOnClick: () => void;
    blurState?: boolean;
    children?: React.ReactNode;
}

export const SuccessModal = (props: IModalComponent) => {

        const imgHelper = (img: string) => {
            if(img === 'Close'){
                return Close;
            }
            if(img === 'Local' || img === 'LocalEdit'){
                return Local;
            }
            if(img === 'LocalError'){
                return LocalError;
            }
            if(img === 'Group' || img === 'GroupEdit'){
                return Group;
            }
            if(img === 'GroupError'){
                return Error;
            }
        }

    return (
        <Modal
            blurState={props.blurState}
            blurOnClick={props.blurOnClick}
            target={props.target}
        >
            <ShowMoreColabCreatedColabModalContainer>
                <CreatedColabModalTopCloseIconContainer
                    onClick={props.blurOnClick}
                >
                    <CreatedColabModalTopCloseIcon src={Close} />
                </CreatedColabModalTopCloseIconContainer>
                <CreatedColabModalMainSection>
                    <CreatedColabModalMainImg src={imgHelper(props.img)} />
                    <CreatedColabModalMainContent>
                        {props.content}
                    </CreatedColabModalMainContent>
                    <OpenOS
                        clickFunct={props.blurOnClick}
                        buttonWidth={"133px"}
                        ableAnimation={false}
                        activeBgColor={"rgba(18, 74, 107, 0.85)"}
                        hoverBgColor={"rgba(18, 74, 107, 0.9)"}
                        Color={"#FFFFFF;"}
                        backgroundColor={"#124A6B"}
                        content={"Concluir"}
                    />
                </CreatedColabModalMainSection>
            </ShowMoreColabCreatedColabModalContainer>
        </Modal>
    );
};
