/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Find } from "../interface/find.interface";
import { IMessage } from "../interface/message.interface";
import { IOs } from "../interface/os.interface";
import { IUser } from "../interface/user.interface";
import { chatService } from "../services/chat.service";
import { useAuth } from "./AuthContext";

interface IChatContext {
    osProgress: IOs[];
    osFinished: IOs[];
    messages: IMessage[];
    getProgress: (Find: Find) => void;
    getFinished: () => void;
    getMessages: (id_os: string) => void;
    sendMessage: (message: string, id_os: string) => void
}

export const ChatContext = React.createContext({} as IChatContext);

const ChatProvider: React.FC = ({ children }) => {
    const [osProgress, setOsProgress] = React.useState<IOs[]>([]);
    const [osFinished, setOsFinished] = React.useState<IOs[]>([]);
    const [messages, setMessages] = React.useState<IMessage[]>([]);

    const { setUser, setToken } = useAuth();

    const getProgress = React.useCallback((Find: Find) => {
        chatService.getOsProgress(Find)
            .then((res) => {
                setOsProgress(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    const getFinished = React.useCallback(() => {
        chatService.getOsFinished()
            .then((res) => {
                setOsFinished(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    const getMessages = React.useCallback((id_os: string) => {
        chatService.getMessage(id_os)
            .then((res) => {
                setMessages(res.data);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, []);

    const sendMessage = React.useCallback((message: string, id_os: string) => {
        chatService.sendMessage(message, id_os)
            .then((res) => {
                getMessages(id_os);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setUser({} as IUser);
                    setToken("");
                }
            })
    }, [])

    return (
        <ChatContext.Provider value={{ osProgress, osFinished, messages, getProgress, getFinished, getMessages, sendMessage }}>
            {children}
        </ChatContext.Provider>
    );
};

export default ChatProvider;

export const useChat = () => React.useContext(ChatContext);