import styled from 'styled-components';


export const DashboardStateCard = styled.div`
    display: flex;
    width: 22%;
    height: 100px;
    align-items: center;
    z-index: 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); 
    border-radius: 8px;
    transition: ease box-shadow 0.3s;
    min-width: 260.5px;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    }
    &:active {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    }
    &.Info {
        display: flex;
        width: 100%;
        gap: 12px;
        height: 64px;
        min-width: unset;
        box-shadow: none;
        cursor: unset;
        z-index: unset;
        &:first-child {
        }
        .card-content {
            border-bottom: 1px solid rgba(229, 229, 229, 0.5);;
            margin-right: 1rem;
        }
        &:last-child {
        }
    }
`;

export const DashboardStateCardIcon = styled.img`
    user-select: none;
    margin: 0 24px;
    &.Card-Icon {
        background-color: red;
        padding: 16px;
        border-radius: 8px;
    }
    &.Box {
        background: rgba(66, 133, 244, 0.05);
        border-radius: 9999px;
    }
    &.Stacks {
        background: rgba(98, 60, 234, 0.05);
        border-radius: 9999px;
    }
    &.Paused {
        background: rgba(151, 151, 151, 0.05);
        border-radius: 9999px;
    }
    &.Alert {
        background: rgba(249, 151, 30, 0.05);
        border-radius: 16px;
    }
    // Info
    &.Received {
        background: linear-gradient(45.36deg, #276A91 0%, var(--blue-secondary) 215.63%);
        padding: 12px;
        margin: 16px;
    }
    &.Finished {
        background: linear-gradient(45.36deg, #3AD29F 0%, var(--blue-secondary) 215.63%);
        padding: 12px;
        margin: 16px;
    }
    &.Timer {
        background: linear-gradient(45.36deg, #A38AFD 0%, var(--blue-secondary) 215.63%);
        padding: 12px;
        margin: 16px;
    }
    &.Archived {
        background: linear-gradient(45.36deg, #F9971E 0%, var(--blue-secondary) 215.63%);
        padding: 12px;
        margin: 16px;
    }
    &.Conclusion {
        background: linear-gradient(45.36deg, #E576C6 0%, var(--blue-secondary) 215.63%);
        padding: 12px 13px;
        margin: 16px;
    }
`;

export const DashboardStateCardContainer = styled.div`
    width: 100%;
`;

export const DashboardStateCardValue = styled.div`
    display: flex;
    min-width: 4.125rem;
    font-weight: 600;
    font-size: 1.5rem;
`;

export const DashboardStateCardContent = styled.h4`
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(68, 64, 83, 0.7);
`;